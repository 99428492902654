import type { FunctionComponent } from 'react';
import PurchaseTable from './PurchaseTable';

const Purchases: FunctionComponent = () => (
    <div className="section">
        <div className="container">
            <h3 className="title is-4">Закупленные диапазоны</h3>
            <PurchaseTable />
        </div>
    </div>
);

export default Purchases;