import { BaseService } from './base.service';
import type { DiapasonRange, PagedPurchases, PurchaseItem } from '../store/purchaseSlice';
import type { DtoPage } from '../enities/comonEntities';

class PurchaseService extends BaseService {
    private static _purchaseService: PurchaseService;
    private static _controller: string = 'purchases';

    private constructor(name: string) {
        super(name);
    }

    public static get Instance(): PurchaseService {
        return this._purchaseService || (this._purchaseService = new this(this._controller));
    }


    public async addPurchaseAsync(item: PurchaseItem): Promise<PurchaseItem> {
        const { data } = await this.$http.post<PurchaseItem>('/', item);
        return data;
    }

    public async editPurchaseAsync(item: PurchaseItem): Promise<PurchaseItem> {
        const { data } = await this.$http.put<PurchaseItem>(`/${item.id}`, item);
        return data;
    }

    public async validateDiapasonAsync(item: DiapasonRange): Promise<boolean> {
        const { data } = await this.$http.post<boolean>(`/validate`, item);
        return data;
    }   

    public async deletePurchaseAsync(id: number): Promise<PurchaseItem> {
        const { data } = await this.$http.delete<PurchaseItem>(`/${id}`);
        return data;
    }

    public async getPurchaseAsync(id: number): Promise<PurchaseItem> {
        const { data } = await this.$http.get<PurchaseItem>(`/${id}`);
        return data;
    }

    public async getPurchasesAsync(startIndex: number): Promise<PurchaseItem[]> {
        const { data } = await this.$http.get<PurchaseItem[]>('/');
        return data;
    }

    public async getPurchasesPageAsync(dto: DtoPage): Promise<PagedPurchases> {
        const { data } = await this.$http.post<PagedPurchases>('/page',
        {
            page: dto.page,
            sortOrder: dto.sortOrder,
            direction: dto.direction,
            searchText: dto.searchText
        });
        return data;
    }
}

export const PurchaseApi = PurchaseService.Instance;
