import authReducer from './authSlice';
import userReducer from './userSlice';
import purchaseReducer from './purchaseSlice';
import performReducer from './performSlice';
import auditReducer from './auditSlice';
import balanceReportReducer from './balanceReportSlice';
import uploadReportReducer from './uploadReportSlice';
import remissionReportReducer from './remissionReportSlice';




import { configureStore } from '@reduxjs/toolkit'

export const store = configureStore({
  reducer: {
        auth: authReducer,
        user: userReducer,
        purchase: purchaseReducer,
        perform: performReducer,
        audit: auditReducer,
        balanceReport: balanceReportReducer,
        uploadReport: uploadReportReducer,
        remissionReport: remissionReportReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;