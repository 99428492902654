import type { FunctionComponent } from 'react';
import PerformGroupTable from './PerformGroupTable';
//import PerformTable from './PerformTable';
//PerformTable

const Performs: FunctionComponent = () => {
    return (
        <div className="section">
            <div className="container is-fluid">
                <h3 className="title is-4">Реализованные диапазоны</h3>
                < PerformGroupTable/>
            </div>
        </div>
    );
};

export default Performs;