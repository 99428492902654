import { Status } from '../config';

const getStatusName = (status: number | undefined): string => {
    var result = '';
    switch (status) {
        case Status.AwaitingSend:
            result = 'Ожидается отправка';
            break;
        case Status.Processing:
            result = 'Обработка';
            break;
        case Status.Sent:
            result = 'Доставлено';
            break;
        case Status.Partially:
            result = 'Отправлено частично';
            break;
        case Status.Fail:
            result = 'Не доставлено';
            break;
        default:
            result = ''
            break;
    }
    return result;
};

export default getStatusName;