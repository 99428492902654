import { Spinner } from '../../components';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, type FunctionComponent } from 'react';
import { useAppSelector, useAppDispatch } from '../../store';
import { getRemissionAsync, getRemissionExportAsync, type RemissionItem } from '../../store/remissionReportSlice';
import { Box, Button, Grid, Select, Tooltip, type SelectChangeEvent, MenuItem } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, } from '@fortawesome/free-solid-svg-icons';
import { padLeft, textLabels } from '../../config';
import MUIDataTable from 'mui-datatables';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import getStatusName from "./../../hooks/getStatusName";
import { FilterAlt } from '@mui/icons-material';
import { getUsersAsync, type UserItem } from 'src/store/userSlice';

const RemissionTable: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const startDate = useAppSelector<string>((state) => state.remissionReport.startDate);
    const endDate = useAppSelector<string>((state) => state.remissionReport.endDate);
    const isLoading = useAppSelector<boolean>((state) => state.remissionReport.isLoading);
    const items = useAppSelector<RemissionItem[]>((state) => state.remissionReport.items);
    const users = useAppSelector<UserItem[]>((state) => state.user.users);

    const [startDateString, setStartDateString] = useState<string>("");
    const [endDateString, setEndDateString] = useState<string>("");
    const [selectUsers, setSelectUsers] = useState<string[]>([] as string[]);


    const columns = [
        {
            name: "id",
            label: "#",
            options: {
                visible: false
            }
        },
        {
            name: "used",
            label: "Количество номеров",
            options: {
                sort: true
            }
        },
        {
            name: "seria",
            label: "Серия бланков",
            options: {
                sort: true
            }
        },
        {
            name: "startCode",
            label: "Стартовый номер",
            options: {
                sort: false,
                customBodyRender: (value: any, tableMeta: { rowData: any; }, updateValue: any) => padLeft(value, 9)
            }
        },
        {
            name: "endCode",
            label: "Конечный номер",
            options: {
                sort: false,
                customBodyRender: (value: any, tableMeta: { rowData: any; }, updateValue: any) => padLeft(value, 9)
            }
        },
        {
            name: "changedBy",
            label: "Изменено",
            options: {
                sort: true
            }
        },
    ];

    const HeaderElements = () => (
        <>
            <Tooltip title="Назад">
                <Button
                    variant="outlined"
                    startIcon={<FontAwesomeIcon icon={faArrowCircleLeft} />}
                    className="float-start"
                    onClick={() => { navigate(-1); }}>
                    Назад
                </Button>
            </Tooltip>
        </>
    );
    
    useEffect(() => {
        setStartDateString(startDate)
        setEndDateString(endDate)
        dispatch(getUsersAsync(0))
        dispatch(getRemissionAsync({ startDate, endDate, users: selectUsers }))
    }, [dispatch, startDate, endDate]);    

    return (
        <>
            <Box component="span" sx={{ p: 2 }}>
                <Grid container spacing={2}>
                    <Grid display="flex" justifyContent="center" alignItems="center" item xs={2} >
                        <h3 className="title is-4">Списание УКЗ</h3>
                    </Grid>
                    <Grid justifyContent="center" item xs={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker label="Начало периода" 
                                // value={dayjs(new Date(startDate))} 
                                value={dayjs(new Date(startDateString))} 
                                format='DD.MM.YYYY' 
                                onChange={(value: any) => {
                                    setStartDateString(dayjs(value).toISOString());
                                    // dispatch(getRemissionAsync({ startDate: dayjs(value).toISOString(), endDate }));
                                }}/>
                        </LocalizationProvider>
                    </Grid>
                    <Grid justifyContent="center" item xs={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker label="Окончание периода" 
                                // value={dayjs(new Date(endDate))} 
                                value={dayjs(new Date(endDateString))} 
                                format='DD.MM.YYYY'
                                onChange={(value:any) => {
                                    setEndDateString(value.toDate());
                                    // dispatch(getRemissionExportAsync({ startDate, endDate: value.toDate() }));
                                }} />
                        </LocalizationProvider>
                    </Grid>
                    <Grid justifyContent="center" item xs={4}>
                        <Select style={{ width: "400px" }}
                            multiple
                            value={selectUsers}
                            onChange={(event: SelectChangeEvent<typeof selectUsers>) => {                               
                                const values = event.target.value as string[];
                                setSelectUsers(values);
                                // dispatch(getUploadAsync({ startDate, endDate, users: values }));
                            }}>
                            {users.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.firstName + ' ' + item.lastName + ' (' + item.email+')'}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid display="flex" justifyContent="center" alignItems="center" item xs={2} >
                        <Button
                            variant="outlined"
                            startIcon={<FilterAlt  />}
                            className="float-start"
                            onClick={() => {
                                dispatch(getRemissionAsync({ startDate:startDateString, endDate:endDateString, users: selectUsers }));
                            }}>
                            Применить фильтры
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Spinner isLoading={isLoading} />
            <MUIDataTable
                title=""
                data={items}
                columns={columns}
                options={{
                    textLabels: textLabels,
                    filter: false,
                    pagination: false,
                    selectableRows: 'none',
                    onDownload: (buildHead, buildBody, columns, data) => {
                        dispatch(getRemissionExportAsync({ startDate, endDate, users:selectUsers }));
                        return false;
                    }, 
                    customToolbar: () => (<HeaderElements />),
                }}
            />
        </>
    );
};

export default RemissionTable;
