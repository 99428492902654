import { Box, CircularProgress, InputLabel, Tooltip } from '@mui/material';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import Button from "@mui/material/Button";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import { Departments, Routes } from '../../config';
import { useEffect, type FunctionComponent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { editPurchaseAsync, getPurchaseAsync, validateDiapasonAsync, type DiapasonRange, type PurchaseItem } from '../../store/purchaseSlice';
import { useAppSelector, useAppDispatch } from '../../store';

import { toast, type Id } from 'react-toastify';


const PurchaseEdit: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const isLoading = useAppSelector<boolean>((state) => state.purchase.isLoading);
    const purchase = useAppSelector<PurchaseItem>((state) => state.purchase.singlePurchase);

    const { id:idParam = '0'} = useParams();
    const idParamNumber = parseInt(idParam, 0);

    const [showSavingProgress, setShowSavingProgress] = useState<boolean>(false)


    useEffect(() => {
        dispatch(getPurchaseAsync(idParamNumber));
    }, [dispatch, idParamNumber]);

    const validateCode = async (value: any, ctx: any) => {
        const v = ctx.parent;
        // console.log("ctx", ctx);
        // console.log("window.location.pathname", window.location.pathname);
        let pathnameArr = window.location.pathname.split('/');
        // console.log("pathnameArr", pathnameArr);
        // console.log("pathnameArr[pathnameArr.length]", pathnameArr[pathnameArr.length - 1]);
        let id: number = isNaN(parseInt(pathnameArr[pathnameArr.length - 1])) ? 0 : parseInt(pathnameArr[pathnameArr.length - 1]);
        if (v.startCode == 0 || v.endCode == 0) {
            return true;
        }
        if (parseInt(v.startCode) > parseInt(v.endCode)) {
            return true;
        }
        const range = { seria: v?.seria ?? '', startCode: v.startCode, endCode: v.endCode, currentDiapasonId: id } as DiapasonRange;
        console.log("validate range", range);
        const result = await (await dispatch(validateDiapasonAsync(range))).payload;
        return result === true;
    };
    const compareCode = async (value: any, ctx: any) => {
        const v = ctx.parent;
        if (v.startCode == 0 || v.endCode == 0) {
            return true;
        }
        return (parseInt(v.startCode) <= parseInt(v.endCode));
    };
    const requiredCode = async (value: any) => {
        return (parseInt(value) > 0);
    };
    const differenceBetweenCodesMoreThan3Mil = async (value: any, ctx: any) => {
        // console.log("value", value);
        // console.log("ctx", ctx);
        let parent = ctx.parent;
        let res = false
        if(parent.endCode && parent.startCode){
            let startCode = parseInt(parent.startCode);
            let endCode = parseInt(parent.endCode);
            if(!isNaN(startCode) && !isNaN(endCode)){
                let difference = endCode - startCode;
                // console.log("difference", difference)
                if(difference <= 3000000)
                    res = true;
            }
        }
        return res;
    };
    const validationSchema = Yup.object().shape({
        dateSup: Yup.date().max(new Date(),"Максимальное значение не больше текущей датыы")
            .default(() => new Date()).required('Обязательное поле'),
        seria: Yup.string().required('Обязательное поле')
            .min(3, "Минимальное количество символов 3").max(3, "Максимальное количество символов 3")
            .matches(/^[aA-zZ\s]+$/, "Разрешены только буквенные символы латинского алфавита"),
        startCode: Yup.string().max(9, "Максимальное длинна не больше 9 знаков").required('Обязательное поле')
            .test('startCode', 'Обязательное поле', requiredCode)
            .test('startCode', 'Диапазон уже существует в закупленных диапазонах', validateCode)
            .test('startCode', 'Стартовый код должен быть меньше конечного кода', compareCode),
            // .test('startCode', 'Введен слишком большой диапазон', differenceBetweenCodesMoreThan3Mil),
        endCode: Yup.string().max(9, "Максимальное длинна не больше 9 знаков").required('Обязательное поле')
            .test('endCode', 'Обязательное поле', requiredCode)
            .test('endCode', 'Диапазон уже существует в закупленных диапазонах', validateCode)
            .test('endCode', 'Конечный код должен быть больше стартового кода', compareCode),
            // .test('endCode', 'Введен слишком большой диапазон', differenceBetweenCodesMoreThan3Mil),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            dateSup: purchase.dateSup,
            seria: purchase.seria,
            startCode: purchase.startCode,
            endCode: purchase.endCode,
            departmentId: purchase.departmentId,
            unusedCodesCount: purchase.unusedCodesCount,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const purchaseItem: PurchaseItem = {
                id: purchase.id,
                dateSup: values.dateSup,
                seria: values.seria,
                startCode: values.startCode,
                endCode: values.endCode,
                departmentId: values.departmentId,
                unusedCodesCount: values.unusedCodesCount 
            };
            setShowSavingProgress(true);
            toast.info("Идёт сохранение...");
            dispatch(editPurchaseAsync(purchaseItem)).then(() => {
                setShowSavingProgress(false);
                toast.dismiss();
                toast.success("Сохранено успешно");
                //navigate(Routes.find((x) => x.name === "Purchases")?.path ?? "");
            });
        }
    });

    useEffect(() => {
        if(formik.values.seria != null && formik.values.seria.length > 0)
            formik.setFieldValue("seria", formik.values.seria.toUpperCase());
    }, [formik.values.seria]);

    return (
        <div className="section">
            <div className="container">
                <h3 className="title is-4">Изменение закупленных диапазонов УКЗ</h3>
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink>Дата поставки:</InputLabel> 
                                    {/*<DateTimePicker*/}
                                    {/*    required autoFocus fullWidth*/}
                                    {/*    id="dateSup" name="dateSup" type="date"*/}
                                    {/*    value={dayjs(formik.values.dateSup).format("YYYY-MM-DD")}*/}
                                    {/*    onChange={formik.handleChange}*/}
                                    {/*    onBlur={formik.handleBlur}*/}
                                    {/*    error={formik.touched.dateSup && Boolean(formik.errors.dateSup)}*/}
                                    {/*    //helperText={formik.touched.dateSup && formik.errors.dateSup}*/}
                                    {/*/>*/}
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            //required 
                                            //autoFocus 
                                            //fullWidth
                                            //value={dayjs(formik.values.dateSup).format("YYYY-MM-DD")}
                                            //id="dateSup" 
                                            // name="dateSup" 
                                            // type="date"
                                            // sx={{width: "100%"}}
                                            value={dayjs(formik.values.dateSup)}
                                            onChange={(value, context) => {
                                                console.log("DatePicker onChange value", value);
                                                console.log("DatePicker onChange value?.toDate()", value?.toDate());
                                                formik.setFieldValue("dateSup", value?.toDate());
                                                // formik.handleChange(value)
                                            }}
                                            // onChange={formik.handleChange}
                                            //onBlur={formik.handleBlur}
                                            //error={formik.touched.dateSup && Boolean(formik.errors.dateSup)}
                                            format={"DD.MM.YYYY"}
                                            slotProps={{
                                                textField: {
                                                    required:true,
                                                    fullWidth:true,
                                                    name:"dateSup",
                                                    // onChange:(e) => {
                                                    //     console.log("slotProps onChange", e);
                                                    //     // formik.setFieldValue("dateSup", e);
                                                    //     // formik.handleChange(e)
                                                    // },
                                                    onBlur:(e) => formik.handleBlur(e),
                                                    error: formik.touched.dateSup && Boolean(formik.errors.dateSup),
                                                    // id="dateSup" ,
                                                    // value={dayjs(formik.values.dateSup).format("YYYY-MM-DD")}
                                                    // onChange:{formik.handleChange},
                                                    // onBlur={formik.handleBlur},
                                                    // error={formik.touched.dateSup && Boolean(formik.errors.dateSup)}
                                                },
                                              }}
                                        />
                                    </LocalizationProvider>
                                    {/* <TextField
                                        required autoFocus fullWidth
                                        id="dateSup" name="dateSup" type="date"
                                        value={dayjs(formik.values.dateSup).format("YYYY-MM-DD")}
                                        onChange={(e) => {
                                            console.log("TextField onChange", e);
                                            // formik.setFieldValue("dateSup", e);
                                            //formik.handleChange(e)
                                        }}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.dateSup && Boolean(formik.errors.dateSup)}
                                        //helperText={formik.touched.dateSup && formik.errors.dateSup}
                                    /> */}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink>Серия:</InputLabel>
                                    <TextField
                                        required fullWidth
                                        id="seria" name="seria"
                                        value={formik.values.seria}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.seria && Boolean(formik.errors.seria)}
                                        helperText={formik.touched.seria && formik.errors.seria}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink>Стартовый код:</InputLabel> 
                                    <TextField
                                        required fullWidth
                                        id="startCode" name="startCode" type="number"
                                        value={formik.values.startCode}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.startCode && Boolean(formik.errors.startCode)}
                                        helperText={formik.touched.startCode && formik.errors.startCode}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink>Конечный код:</InputLabel> 
                                    <TextField
                                        required fullWidth
                                        id="endCode" name="endCode" type="number"
                                        value={formik.values.endCode}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.endCode && Boolean(formik.errors.endCode)}
                                        helperText={formik.touched.endCode && formik.errors.endCode}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink>Всего:</InputLabel>
                                    <TextField
                                        fullWidth
                                        id="amount" name="amount" type="number"
                                        value={formik.values.endCode - formik.values.startCode + 1}
                                        InputProps={{
                                            readOnly: true,
                                          }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} sx={{ display: { xs: 'none', md: 'none' } }} >
                                    <InputLabel shrink>Неиспользованных кодов:</InputLabel>
                                    <TextField 
                                        fullWidth
                                        id="unusedCodesCount" name="unusedCodesCount" type="number"
                                        value={formik.values.unusedCodesCount}
                                        InputProps={{
                                            readOnly: true,
                                          }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink>Отдел:</InputLabel>
                                    <TextField
                                        fullWidth
                                        id="department" name="department" type="string"
                                        value={Departments.has(Number(formik.values.departmentId)) ? Departments.get(Number(formik.values.departmentId)) : ""}
                                        InputProps={{
                                            readOnly: true,
                                          }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box margin={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={5}>
                                    {/*<pre>{JSON.stringify(formik.values, null, 2)}</pre>*/}
                                </Grid>
                                <Grid item xs={12} sm={1} style={{display: "flex", justifyContent:"center", alignItems: "center"}}>
                                    { showSavingProgress && 
                                        <CircularProgress size={25} />
                                    }
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Button
                                        disabled={isLoading}
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Сохранить
                                    </Button>
                                    {/* <Tooltip title="Сохранить">
                                        <Button
                                            startIcon={isLoading && <CircularProgress />}
                                            disabled={isLoading}
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                        >
                                            Сохранить
                                        </Button>
                                    </Tooltip> */}
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        sx={{ mt: 3, mb: 2 }}
                                        onClick={() => {
                                            navigate(-1);
                                        }}
                                    >
                                        Назад
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                </Box>
            </div>
        </div>
    );
}

export default PurchaseEdit;