
import { type FunctionComponent } from 'react';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Box, InputLabel, MenuItem } from '@mui/material';
import { useFormik } from 'formik';
import { ru } from 'yup-locales';
import { setLocale } from 'yup';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { addUserAsync, type UserItem, type UserDepartment } from '../../store/userSlice';
import { useAppSelector, useAppDispatch } from '../../store';
import { Routes, RolesDefs, DepartmentId, DepartmentLabel, DepartmentsDefs } from '../../config';

const UserAdd: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector<boolean>((state) => state.user.isLoading);
    const navigate = useNavigate();
    setLocale(ru);
    const nameRegex = /^(BY|by)\d{6}$/g;
    const validationSchema = Yup.object().shape({
        firstName: Yup.string().min(2, 'Минимум 2 буквы').max(50, 'Максимум 50 букв').required('Обязательное поле'),
        lastName: Yup.string().min(2, 'Минимум 2 буквы').max(50, 'Максимум 50 букв').required('Обязательное поле'),
        login: Yup.string()
        .required('Обязательное поле')
        .matches(nameRegex, 'Допустимый формат имени пользователя - BYxxxxxx или byxxxxxx')
        ,
        password: Yup.string().min(8, 'Пароль должен содержать минимум 8 символов').required('Обязательное поле'),
        email: Yup.string().email().required('Обязательное поле'),
        userroles: Yup.array().min(1, 'Выберете минимум 1 роль'),
        departmentId: Yup.number().min(1, 'Выберите отдел для пользователя'),
    });
    const formik = useFormik({
        initialValues: {
            login: '',
            password: '',
            firstName: '',
            lastName: '',
            email: '',
            userRoles:[],
            departmentId: -1,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const userItem: UserItem = {
                id:0,
                login: values.login,
                password: values.password,
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                userRoles: values.userRoles,
                departmentId: values.departmentId
            };
            console.log("onSubmit values", values)
            console.log("onSubmit userItem", userItem)
            dispatch(addUserAsync(userItem)).then(() => {
                navigate(Routes.find((x) => x.name === "Users")?.path ?? "");
            });
        }
    });

    return (
        <div className="section">
            <div className="container">
                <h3 className="title is-4">Добавить аккаунт пользователя</h3>
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink>Логин:</InputLabel>
                                    <TextField
                                        required
                                        fullWidth
                                        id="login"
                                        label=""
                                        name="login"
                                        autoComplete="login"
                                        autoFocus                                      
                                        value={formik.values.login}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.login && Boolean(formik.errors.login)}
                                        helperText={formik.touched.login && formik.errors.login}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink>Пароль:</InputLabel>
                                    <TextField
                                        required
                                        fullWidth
                                        name="password"                                      
                                        type="password"
                                        id="password"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.password && Boolean(formik.errors.password)}
                                        helperText={formik.touched.password && formik.errors.password}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink>Имя:</InputLabel>
                                    <TextField                                 
                                        required
                                        fullWidth
                                        id="firstName"                                        
                                        name="firstName"
                                        value={formik.values.firstName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                        helperText={formik.touched.firstName && formik.errors.firstName}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink>Фамилия:</InputLabel>                                   
                                    <TextField
                                        required
                                        fullWidth
                                        id="lastName"
                                        label=""
                                        name="lastName"
                                        value={formik.values.lastName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                        helperText={formik.touched.lastName && formik.errors.lastName}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink>Email:</InputLabel>
                                    <TextField
                                        required
                                        fullWidth
                                        name="email"
                                        label=""
                                        type="email"
                                        id="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink>Роли:</InputLabel>
                                    <TextField
                                        select multiline
                                        required
                                        fullWidth
                                        name="userRoles"                                        
                                        type="select"
                                        id="userRoles"
                                        SelectProps={{multiple: true}}
                                        value={formik.values.userRoles}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.userRoles && Boolean(formik.errors.userRoles)}
                                        helperText={formik.touched.userRoles && formik.errors.userRoles}
                                    >
                                        {RolesDefs.map(x => (
                                            <MenuItem key={x.value} value={x.value}>
                                                {x.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink>Отдел:</InputLabel>
                                    <TextField
                                        select
                                        required
                                        fullWidth
                                        name="departmentId"
                                        type="select"
                                        id="departmentId"
                                        SelectProps={{ multiple: false }}
                                        value={formik.values.departmentId}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.departmentId && Boolean(formik.errors.departmentId)}                                       
                                    >
                                        {DepartmentsDefs.map(x => (
                                            <MenuItem key={x.value} value={x.value}>
                                                {x.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box margin={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    {/*<pre>{JSON.stringify(formik.values, null, 2)}</pre>*/}
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Button
                                        disabled={isLoading}
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Сохранить
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        sx={{ mt: 3, mb: 2 }}
                                        onClick={() => {
                                            navigate(-1);
                                        }}
                                    >
                                        Отменить
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                </Box>
            </div>
        </div>
    );
}
export default UserAdd;
