import axios, { type AxiosInstance } from 'axios';

export abstract class BaseService {
    protected readonly $http: AxiosInstance;
    protected constructor(controller: string, timeout: number = 50000) {        
        this.$http = axios.create({
            timeout,
            //baseURL: `http://localhost/api/${controller}/`
            // baseURL: `https://localhost:5001/api/${controller}/`
            baseURL: `/api/${controller}/`
        });

        this.$http.interceptors.request.use(function (config) {
            const token = localStorage.getItem('token');
            config.headers.Authorization = token;
            return config;
        });
  }
}
