import type { FunctionComponent } from 'react';
import UserTable from './UserTable';

const Users: FunctionComponent = () => (
    <div className="section">
        <div className="container">
            <h3 className="title is-4">Пользователи</h3>
            <UserTable />
        </div>
    </div>
);

export default Users;