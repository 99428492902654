import { Spinner } from '../../components';
import { useNavigate } from 'react-router-dom';
import { useEffect, type FunctionComponent, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../store';
import { getBalanceAsync, getBalanceExportAsync, type BalanceItem } from '../../store/balanceReportSlice';
import { Box, Button, Grid, MenuItem, Paper, TextField, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, } from '@fortawesome/free-solid-svg-icons';
import { Departments, DepartmentsDefs, textLabels } from '../../config';
import MUIDataTable from 'mui-datatables';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FilterAlt } from '@mui/icons-material';

const BalanceReport: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const startDate = useAppSelector<string>((state) => state.balanceReport.startDate);
    const endDate = useAppSelector<string>((state) => state.balanceReport.endDate);
    const isLoading = useAppSelector<boolean>((state) => state.balanceReport.isLoading);
    const items = useAppSelector<BalanceItem[]>((state) => state.balanceReport.items);

    const [startDateString, setStartDateString] = useState<string>("");
    const [endDateString, setEndDateString] = useState<string>("");
    const [departmentId, setDepartmentId] = useState<number>(Number(Departments.keys().next().value));

    const columns = [
        {
            name: "pos",
            label: "#",
            options: {
                visible: false
            }
        },
        {
            name: "date_pos",
            label: "Дата",
            options: {
                customBodyRender: (value: any, tableMeta: { rowData: any; }, updateValue: any) => dayjs(value).format("DD.MM.YYYY")  
            }
        },
        {
            name: "start_value",
            label: "Остаток на начало, шт",
            options: {
                sort: false
            }
        },
        {
            name: "in_value",
            label: "Получено, шт",
            options: {
                sort: false
            }
        },
        {
            name: "out_value",
            label: "Готовая продукция на склад, шт",
            options: {
                sort: false
            }
        },

        {
            name: "defect_value",
            label: "Забракованная продукция, шт",
            options: {
                sort: false
            }
        },
        {
            name: "end_value",
            label: "Остаток на конец, шт",
            options: {
                sort: false
            }
        }
    ];

    const HeaderElements = () => (
        <>
            <Tooltip title="Назад">
                <Button
                    variant="outlined"
                    startIcon={<FontAwesomeIcon icon={faArrowCircleLeft} />}
                    className="float-start"
                    onClick={() => { navigate(-1); }}>
                    Назад
                </Button>
            </Tooltip>
        </>
    );

    useEffect(() => {
        setStartDateString(startDate)
        setEndDateString(endDate)
        dispatch(getBalanceAsync({ startDate, endDate, departmentId}));
    }, [dispatch, startDate, endDate]);

    return (
        <>
            <Box component="span" sx={{ p: 2 }}>
                <Grid container spacing={2}>
                    <Grid display="flex" justifyContent="center" alignItems="center" item xs={2} >
                        <h3 className="title is-4">Оборотная ведомость</h3>
                    </Grid>
                    <Grid justifyContent="center" item xs={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker label="Начало периода" 
                                // value={dayjs(new Date(startDate))} 
                                value={dayjs(new Date(startDateString))} 
                                format='DD.MM.YYYY' 
                                maxDate={dayjs(new Date(endDateString))} 
                                onChange={(value: any) => {
                                    setStartDateString(dayjs(value).toISOString());
                                    // dispatch(getBalanceAsync({ startDate: dayjs(value).toISOString(), endDate }));
                                }}/>
                        </LocalizationProvider>
                    </Grid>
                    <Grid justifyContent="center" item xs={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker label="Окончание периода" 
                                // value={dayjs(new Date(endDate))} 
                                value={dayjs(new Date(endDateString))} 
                                format='DD.MM.YYYY'
                                maxDate={dayjs(new Date())} 
                                onChange={(value:any) => {
                                    setEndDateString(value.toDate());
                                    // dispatch(getBalanceAsync({ startDate, endDate: value.toDate() }));
                                }} />
                        </LocalizationProvider>
                    </Grid>
                    <Grid justifyContent="center" item xs={2}>
                        {/* <InputLabel shrink>Отдел:</InputLabel> */}
                        <TextField
                            label="Отдел"
                            fullWidth
                            select
                            name="department"
                            type="select"
                            id="department"
                            SelectProps={{ multiple: false}}
                            value={departmentId}
                            onChange={e => {
                                console.log("onChange e ", e)
                                setDepartmentId(Number(e.target.value));
                            }}
                            // onBlur={formik.handleBlur}
                            // error={formik.touched.departmentId && Boolean(formik.errors.departmentId)}                                       
                            >
                            {DepartmentsDefs.map(x => (
                                <MenuItem key={x.value} value={x.value}>
                                    {x.label}
                                </MenuItem>
                            ))}
                            </TextField>
                    </Grid>
                    <Grid display="flex" justifyContent="center" alignItems="center" item xs={2} >
                        <Button
                            variant="outlined"
                            startIcon={<FilterAlt  />}
                            className="float-start"
                            onClick={() => {
                                dispatch(getBalanceAsync({ startDate:startDateString, endDate:endDateString, departmentId:departmentId }));
                            }}>
                            Применить фильтры
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Spinner isLoading={isLoading} />
            <MUIDataTable
                title=""
                data={items}
                columns={columns}
                options={{
                    textLabels: textLabels,
                    filter: false,
                    pagination: false,
                    selectableRows: 'none',
                    onDownload: (buildHead, buildBody, columns, data) => {
                        dispatch(getBalanceExportAsync({ startDate, endDate, departmentId }));
                        return false;
                    }, 
                    customToolbar: () => (<HeaderElements />),
                }}
            />
        </>
    );
};

export default BalanceReport;
