import { Spinner } from '../../components';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, type FunctionComponent } from 'react';
import { useAppSelector, useAppDispatch } from '../../store';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { textLabels } from '../../config';
import type { AuditItem, AuditRequest } from '../../enities/auditEntities';
import { getAuditAsync } from '../../store/auditSlice';
import MUIDataTable from 'mui-datatables';
import dayjs from 'dayjs';

const AuditTable: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { id, type } = useParams();

    const isLoading = useAppSelector<boolean>((state) => state.audit.isLoading);
    const audits = useAppSelector<AuditItem[]>((state) => state.audit.items);

    const columns = [
        {
            name: "createdDate",
            label: "Дата изменения",
            options: {             
                customBodyRender: (value: any, tableMeta: { rowData: any; }, updateValue: any) => (
                    <>{dayjs(value).format("DD.MM.YYYY hh:mm:ss")}</>
                )
            }
        },
        {
            name: "createdBy",
            label: "Пользователь"
        },
        {
            label: "Тип",
            name: "changeState"
        }];

    const HeaderElements = () => (
        <>
            <Tooltip title="Назад">
                <Button
                    variant="outlined"
                    startIcon={<FontAwesomeIcon icon={faArrowCircleLeft} />}
                    className="float-start"
                    onClick={() => { navigate(-1); }}>
                    Назад
                </Button>
            </Tooltip>
        </>
    );

    useEffect(() => {
        dispatch(getAuditAsync(
            { id: id, type: type } as AuditRequest));
    }, [dispatch, id, type]);

    return (
        <>
            <Spinner isLoading={isLoading} />
            <MUIDataTable
                title=""
                data={audits}
                columns={columns}
                options={{
                    textLabels: textLabels,
                    filter: false,
                    sort: false,
                    selectableRows: 'none',
                    pagination: false,
                    expandableRows: true,
                    expandableRowsHeader: false,
                    renderExpandableRow: (rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }) => {
                        return (
                            <tr>
                                <td colSpan={4}>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableCell align="right">Свойство</TableCell>
                                                <TableCell align="right">Старое значение</TableCell>
                                                <TableCell align="right">Новое значение</TableCell>
                                            </TableHead>
                                            <TableBody>
                                                {audits[rowMeta.dataIndex].properties.map((row) => {
                                                    return (
                                                        <TableRow key={row.auditEntryPropertyID}>
                                                            <TableCell>{row.propertyName}
                                                            </TableCell>
                                                            <TableCell align="right">{row.oldValue}
                                                            </TableCell>
                                                            <TableCell align="right">{row.newValue}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </td>
                            </tr>
                        );
                    },
                    customToolbar: () => (<HeaderElements />),
                }}
            />
        </>
    );
};

export default AuditTable;
