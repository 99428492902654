import { faAccusoft } from '@fortawesome/free-brands-svg-icons';
import { faAdjust, faLeftRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tooltip } from '@mui/material';
import type { FunctionComponent } from 'react';
import { useNavigate } from 'react-router';
import { Routes } from '../../config';

const Dashboard: FunctionComponent = () => {
const navigate = useNavigate();
 return (
    <div className="dashboard-wrapper">
        <section className="container">
            <div className="card">
                <div className="card-content">
                    <div className="column">
                        <p className="title has-text-centered">Отчеты</p>
                        <hr />
                        <div className="columns is-multiline">
                            <div className="column dashboard-info">
                                <div className="content">
                                     <ul>
                                         <li style={{ listStyleType: "none" }}>
                                            <Tooltip title="Отчет о реализации УКЗ">
                                                <Button
                                                     variant="outlined"
                                                     startIcon={<FontAwesomeIcon icon={faAccusoft} />}
                                                    className="float-start"
                                                    onClick={() => {
                                                        navigate(Routes.find((x) => x.name === "ReportUpload")?.path ?? "");
                                                    }}>
                                                    Реализация УКЗ
                                                </Button>
                                            </Tooltip>
                                             <span>  Отчет отображает данные по вводу и отправкам данных в ГИС УКЗ в разрезе пользователей.</span>
                                        </li>
                                         <li style={{ listStyleType: "none" }}>
                                             <Tooltip title="Списание УКЗ">
                                                 <Button
                                                     variant="outlined"
                                                     startIcon={<FontAwesomeIcon icon={faAdjust} />}
                                                     className="float-start"
                                                     onClick={() => {
                                                         navigate(Routes.find((x) => x.name === "ReportRemission")?.path ?? "");
                                                     }}>
                                                     Списание УКЗ
                                                 </Button>
                                             </Tooltip>
                                             <span>  Отчет представляет собой выгрузку информации для формирования Акта о списании УКЗ в виде таблицы.</span>О
                                        </li>
                                         <li style={{ listStyleType: "none" }}>
                                             <Tooltip title="Оборотная ведомость">
                                                 <Button
                                                     variant="outlined"
                                                     startIcon={<FontAwesomeIcon icon={faLeftRight} />}
                                                     className="float-start"
                                                     onClick={() => {
                                                         navigate(Routes.find((x) => x.name === "ReportBalance")?.path ?? "");
                                                     }}>
                                                     Оборотная ведомость
                                                 </Button>
                                             </Tooltip>
                                             <span>  Cводный отчет об использовании контрольных унифицированных знаков за заданный календарный период.</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    );
};

export default Dashboard;