import { UserApi } from 'src/api';
import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { DepartmentLabel, DepartmentId } from 'src/config/constants';

export type UserItem = Readonly<{
    id: number;
    login: string;
    firstName: string;
    lastName: string;
    password: string;
    email: string;
    userRoles: any[];
    departmentId: number;
}>;

export interface UserDepartment {
    departmentLabel: DepartmentLabel;
    departmentId: DepartmentId
};

export type UsersState = Readonly<{
    isLoading: boolean;
    startIndex: number;
    users: UserItem[];
    singleUser: UserItem;
    id: number;
}>;

export type UpdateUserPassword = Readonly<{
    login:string;
    oldPassword: string;
    newPassword: string;
}>;

export type UpdateUserPasswordResponse = Readonly<{
    status:number;
    statusText:string;
}>;

export type ReceiveUsersPayload = Pick<UsersState, "users" | "startIndex" | "id" | "singleUser">;

const initialState: UsersState = {
    users: [],
    isLoading: false,
    startIndex: 10,
    singleUser: {} as UserItem,
    id:0
};


export const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        addUser: (state, action: PayloadAction<UserItem>) => {   
            state.singleUser = action.payload;
        },
        requestUser: (state, action: PayloadAction<number>) => {
            state.isLoading = true;
            state.id = action.payload;
        },
        receiveUser: (state, action: PayloadAction<ReceiveUsersPayload>) => {
            const { id, singleUser  } = action.payload;
            state.isLoading = false;
            state.id = id;
            state.singleUser = singleUser;
        },
        requestUsers: (state, action: PayloadAction<number>) => {
            state.isLoading = true;
            state.startIndex = action.payload;
        },
        receiveUsers: (state, action: PayloadAction<ReceiveUsersPayload>) => {
            const { users, startIndex } = action.payload;
            if (startIndex === state.startIndex) {
                state.isLoading = false;
                state.users = users;
                state.startIndex = startIndex;             
            }
        }
    }
});

export const deleteUserAsync = createAsyncThunk(
    'users/getUserAsync',
    async (id: number, { dispatch }) => {
        dispatch(requestUser(id));
        try {
            const singleUser = await UserApi.deleteUserAsync(id);
            const payload = { singleUser, id, users: [], startIndex: 0 };
            dispatch(receiveUser(payload));
        } catch (e) {
            console.error(e);
        }
    }
);

export const getUserAsync = createAsyncThunk(
    'users/getUserAsync',
    async (id: number, { dispatch }) => {
        dispatch(requestUser(id));
        try {
            const singleUser = await UserApi.getUserAsync(id);
            const payload = { singleUser, id, users: [], startIndex: 0};
            dispatch(receiveUser(payload));
        } catch (e) {
            console.error(e);
        }
    }
);

export const editUserAsync = createAsyncThunk(
    'users/editUserAsync',
    async (user: UserItem, { dispatch }) => {
        try {
            await UserApi.editUserAsync(user);
            dispatch(addUser(user));
        } catch (e) {
            console.error(e);
        }
    }
);

export const addUserAsync = createAsyncThunk(
    'users/addUserAsync',
    async (user: UserItem, { dispatch }) => {
        try {
            await UserApi.addUserAsync(user);
            dispatch(addUser(user));
        } catch (e) {
            console.error(e);
        }
    }
);

export const getUsersAsync = createAsyncThunk(
    'users/getUsersAsync',
    async (startIndex: number, { dispatch, getState }) => {
        const { startIndex: stateIdx } = (getState as () => UsersState)();
        /*if (startIndex === stateIdx) {
            return;
        }*/
        dispatch(requestUsers(startIndex));
        try {
            const users = await UserApi.getUsersAsync();
            const payload = {
                users, startIndex, id: 0, singleUser: {} as UserItem };
            dispatch(receiveUsers(payload));
        } catch (e) {
            console.error(e);
        }
    }
);

export const updatePasswordAsync = createAsyncThunk(
    'users/UpdatePasswordAsync',
    async (updatepassword: UpdateUserPassword, { dispatch, getState }) => {
        
        try {
            const recievedData = await UserApi.updatePasswordAsync(updatepassword);
            console.log("recievedData", recievedData)
            return recievedData;
        } catch (e) {
            console.error(e);
        }
    }
);

export const { requestUsers, receiveUsers, requestUser, receiveUser, addUser } = userSlice.actions;

export default userSlice.reducer;