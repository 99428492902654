
import { useEffect, type FunctionComponent } from 'react';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Button from "@mui/material/Button";
import { Box, InputLabel } from '@mui/material';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { addPurchaseAsync, validateDiapasonAsync, type DiapasonRange, type PurchaseItem } from '../../store/purchaseSlice';
import { useAppSelector, useAppDispatch } from '../../store';
import { Routes } from '../../config';


const PurchaseAdd: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector<boolean>((state) => state.purchase.isLoading);
    const navigate = useNavigate();
    const validateCode = async (value: any, ctx: any) => {
        const v = ctx.parent;
        console.log("ctx", ctx);
        // console.log("window.location.pathname", window.location.pathname);
        // let pathnameArr = window.location.pathname.split('/');
        // console.log("pathnameArr", pathnameArr);
        if (v.startCode == 0 || v.endCode == 0) {
            return true;
        }
        if (parseInt(v.startCode) > parseInt(v.endCode)) {
            return true;
        }
        const range = { seria: v?.seria??'', startCode: v.startCode, endCode: v.endCode, currentDiapasonId: 0 } as DiapasonRange;
        console.log("validate range", range);
        const result = await (await dispatch(validateDiapasonAsync(range))).payload;
        return result === true;
    };
    const compareCode = async (value: any, ctx: any) => {
        const v = ctx.parent;
        if (v.startCode == 0 || v.endCode == 0) {
            return true;
        }
        return (parseInt(v.startCode) <= parseInt(v.endCode));
    };
    const requiredCode = async (value: any) => {
        return (parseInt(value) > 0);
    };
    // const differenceBetweenCodesMoreThan3Mil = async (value: any, ctx: any) => {
    //     // console.log("value", value);
    //     // console.log("ctx", ctx);
    //     let parent = ctx.parent;
    //     let res = false
    //     if(parent.endCode && parent.startCode){
    //         let startCode = parseInt(parent.startCode);
    //         let endCode = parseInt(parent.endCode);
    //         if(!isNaN(startCode) && !isNaN(endCode)){
    //             let difference = endCode - startCode;
    //             // console.log("difference", difference)
    //             if(difference <= 3000000) // 3 000 000
    //                 res = true;
    //         }
    //     }
    //     return res;
    // };
    const validationSchema = Yup.object().shape({
        dateSup: Yup.date().max(new Date(), "Максимальное значение не больше текущей датыы")
            .default(() => new Date()).required('Обязательное поле'),
        seria: Yup.string().required('Обязательное поле')
            .min(3, "Минимальное количество символов 3").max(3, "Максимальное количество символов 3")
            .matches(/^[aA-zZ\s]+$/, "Разрешены только буквенные символы латинского алфавита"),
        startCode: Yup.string().max(9, "Максимальное длинна не больше 9 знаков").required('Обязательное поле')
            .test('startCode', 'Обязательное поле', requiredCode)
            .test('startCode', 'Диапазон уже существует в закупленных диапазонах', validateCode)
            .test('startCode', 'Стартовый код должен быть меньше конечного кода', compareCode),
            // .test('startCode', 'Введен слишком большой диапазон (больше 3 000 000)', differenceBetweenCodesMoreThan3Mil),
        endCode: Yup.string().max(9, "Максимальное длинна не больше 9 знаков").required('Обязательное поле')
            .test('endCode', 'Обязательное поле', requiredCode)
            .test('endCode', 'Диапазон уже существует в закупленных диапазонах', validateCode)
            .test('endCode', 'Конечный код должен быть больше стартового кода', compareCode),
            // .test('startCode', 'Введен слишком большой диапазон (больше 3 000 000)', differenceBetweenCodesMoreThan3Mil),
    });
    const formik = useFormik({
        initialValues: {
            dateSup: new Date(),
            seria: '',
            startCode: 0,
            endCode: 0,
            departmentId: 0,
            unusedCodesCount: 0
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const purchaseItem: PurchaseItem = {
                id:0,
                dateSup: values.dateSup,
                seria: values.seria,
                startCode: values.startCode,
                endCode: values.endCode,
                departmentId: values.departmentId,
                unusedCodesCount: values.unusedCodesCount 
            };
            dispatch(addPurchaseAsync(purchaseItem)).then(() => {
                navigate(Routes.find((x) => x.name === "Purchases")?.path ?? "");
            });
        }
    });

    useEffect(() => {
        if(formik.values.seria != null && formik.values.seria.length > 0)
            formik.setFieldValue("seria", formik.values.seria.toUpperCase());
    }, [formik.values.seria]);

    return (
        <div className="section">
            <div className="container">
                <h3 className="title is-4">Ввод закупленных диапазонов УКЗ</h3>
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink>Дата поставки:</InputLabel> 
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            //required 
                                            //autoFocus 
                                            //fullWidth
                                            //value={dayjs(formik.values.dateSup).format("YYYY-MM-DD")}
                                            //id="dateSup" 
                                            // name="dateSup" 
                                            // type="date"
                                            // sx={{width: "100%"}}
                                            value={dayjs(formik.values.dateSup)}
                                            onChange={(value, context) => {
                                                console.log("DatePicker onChange value", value);
                                                console.log("DatePicker onChange value?.toDate()", value?.toDate());
                                                formik.setFieldValue("dateSup", value?.toDate());
                                                // formik.handleChange(value)
                                            }}
                                            // onChange={formik.handleChange}
                                            //onBlur={formik.handleBlur}
                                            //error={formik.touched.dateSup && Boolean(formik.errors.dateSup)}
                                            format={"DD.MM.YYYY"}
                                            slotProps={{
                                                textField: {
                                                    required:true,
                                                    fullWidth:true,
                                                    name:"dateSup",
                                                    // onChange:(e) => {
                                                    //     console.log("slotProps onChange", e);
                                                    //     // formik.setFieldValue("dateSup", e);
                                                    //     // formik.handleChange(e)
                                                    // },
                                                    onBlur:(e) => formik.handleBlur(e),
                                                    error: formik.touched.dateSup && Boolean(formik.errors.dateSup),
                                                    // id="dateSup" ,
                                                    // value={dayjs(formik.values.dateSup).format("YYYY-MM-DD")}
                                                    // onChange:{formik.handleChange},
                                                    // onBlur={formik.handleBlur},
                                                    // error={formik.touched.dateSup && Boolean(formik.errors.dateSup)}
                                                },
                                            }}
                                        />
                                    </LocalizationProvider>
                                    {/*<TextField
                                        required autoFocus fullWidth
                                        id="dateSup" name="dateSup" type="date"
                                        value={dayjs(formik.values.dateSup).format("YYYY-MM-DD")}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.dateSup && Boolean(formik.errors.dateSup)}
                                    //helperText={formik.touched.dateSup && formik.errors.dateSup}
                                    />*/}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink>Серия:</InputLabel> 
                                    <TextField
                                        required fullWidth
                                        id="seria" name="seria"
                                        value={formik.values.seria}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.seria && Boolean(formik.errors.seria)}
                                        helperText={formik.touched.seria && formik.errors.seria}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink>Стартовый код:</InputLabel> 
                                    <TextField                                 
                                        required fullWidth
                                        id="startCode" name="startCode" type="number"
                                        value={formik.values.startCode}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.startCode && Boolean(formik.errors.startCode)}
                                        helperText={formik.touched.startCode && formik.errors.startCode}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink>Конечный код:</InputLabel> 
                                    <TextField
                                        required fullWidth
                                        id="endCode" name="endCode" type="number"
                                        value={formik.values.endCode}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.endCode && Boolean(formik.errors.endCode)}
                                        helperText={formik.touched.endCode && formik.errors.endCode}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink>Всего:</InputLabel>
                                    <TextField
                                        fullWidth
                                        id="amount" name="amount" type="number"
                                        value={formik.values.endCode - formik.values.startCode + 1}
                                        InputProps={{
                                            readOnly: true,
                                          }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box margin={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    {/*<pre>{JSON.stringify(formik.values, null, 2)}</pre>*/}
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Button
                                        disabled={isLoading}
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Сохранить
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        sx={{ mt: 3, mb: 2 }}
                                        onClick={() => {navigate(-1);}}
                                    >
                                        Отменить
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                </Box>
            </div>
        </div>
    );
}
export default PurchaseAdd;
