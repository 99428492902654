import { Spinner } from '../../components';
import { useParams, useNavigate, generatePath } from 'react-router-dom';
import { useEffect, type FunctionComponent } from 'react';
import { useAppSelector, useAppDispatch } from '../../store';
import { deleteUserAsync, getUsersAsync, type UserItem } from '../../store/userSlice';
import { Button, ButtonGroup, IconButton, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, } from '@fortawesome/free-solid-svg-icons';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Departments, Routes, textLabels } from '../../config';
import Swal from 'sweetalert2';
import MUIDataTable from 'mui-datatables';

const UserTable: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { startIndex: startIndexDefault = '0' } = useParams();
    const intNextStartIndex = parseInt(startIndexDefault, 10);

    const isLoading = useAppSelector<boolean>((state) => state.user.isLoading);
    const users = useAppSelector<UserItem[]>((state) => state.user.users);
    const startIndex = useAppSelector<number>((state) => state.user.startIndex);

    const columns = [
        {
            name: "id",
            label: "Id",
            options: {
                display: false, filter: false
            }
        },
        {
            label: "Логин",
            name: "login",
            options: {
                filter: true
            }
        },
        {
            label: "Имя",
            name: "firstName",
            options: {
                filter: true
            }
        },
        {
            label: "Фамилия",
            name: "lastName",
            options: {
                filter: true
            }
        },
        {
            label: "Email",
            name: "email",
            options: {
                filter: true
            }
        },
        {
            label: "Отдел",
            name: "departmentId",
            options: {
                filter: true,
                customBodyRender: (value: any, tableMeta: { rowData: any; }, updateValue: any) => Departments.has(Number(value)) ? Departments.get(Number(value)) : "" 
            }
        },
        {
            name: "Операции",
            options: {
                filter: false, sort: false,
                customBodyRender: (value: any, tableMeta: { rowData: any; }, updateValue: any) => {
                    return <>
                        <ButtonGroup variant="text">
                            <Tooltip title="Редактировать">
                                <IconButton color="primary" onClick={() => {
                                    const path = Routes.find((x) => x.name === "UserEdit")?.path ?? "";
                                    const id = tableMeta.rowData[0];
                                    navigate(generatePath(path, { id: id }), { state: { id: id } });
                                }}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Удалить">
                                <IconButton color="primary" onClick={() => {
                                    Swal.fire({
                                        title: "Удалить аккаунт пользователя?",
                                        showClass: { popup: 'animate__animated animate__fadeInDown' },
                                        hideClass: { popup: 'animate__animated animate__fadeOutUp' },
                                        showCancelButton: true,
                                        confirmButtonColor: '#d33',
                                        cancelButtonColor: '#3085d6',
                                        confirmButtonText: 'Удалить',
                                        cancelButtonText: 'Отменить'
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            const id = tableMeta.rowData[0];
                                            dispatch(deleteUserAsync(id)).then(() => {
                                                dispatch(getUsersAsync(intNextStartIndex));
                                            });
                                        }
                                    })
                                }}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </ButtonGroup>
                    </>;
                },
            },
        }];

    const HeaderElements = () => (
        <>
        <Tooltip title="Добавить пользователя">
            <Button
                variant="outlined"
                startIcon={<FontAwesomeIcon icon={faAdd} />}
                className="float-start"
                onClick={() => {
                    navigate(Routes.find((x) => x.name === "UserAdd")?.path ?? "");
                }}
            >
                Добавить
            </Button>
        </Tooltip>
        </>
    );

    useEffect(() => {
        dispatch(getUsersAsync(intNextStartIndex));
    }, [dispatch, startIndex, intNextStartIndex]);

    return (
        <>
            <Spinner isLoading={isLoading} />
            <MUIDataTable
                title=""
                data={users}
                columns={columns}
                options={{
                    textLabels: textLabels,
                    filter: true,
                    selectableRows: 'none',
                    pagination: false,
                    download: false,
                    customToolbar: () => (<HeaderElements />),
                }}
            />
        </>
    );
};

export default UserTable;
