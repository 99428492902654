import { BaseService } from './base.service';
import type { AuditItem, AuditRequest } from '../enities/auditEntities';

class AuditService extends BaseService {
    private static _auditService: AuditService;
    private static _controller: string = 'audit';

    private constructor(name: string) {
        super(name);
    }

    public static get Instance(): AuditService {
        return this._auditService || (this._auditService = new this(this._controller));
    }

    public async getAuditAsync(request:AuditRequest): Promise<AuditItem[]> {
        const { data } = await this.$http.get<AuditItem[]>(`/${request.id}/${request.type}`);
        return data;
    }
}

export const AuditApi = AuditService.Instance;
