import { type FunctionComponent } from 'react';
import { useIsRememberMe } from '../../hooks';
import { Routes } from '../../config';
import Signin from './Signin';
import { Navigate } from 'react-router-dom';

const Auth: FunctionComponent = () => {
    const isRememberMe = useIsRememberMe();

    if (isRememberMe) {
        return (
            <Navigate to={Routes.find((x) => x.name === 'Purchases')?.path ?? '/'} />
        );
    }
    return (
        <div className="section">
            <div className="container">
                <Signin />
            </div>
        </div>
    );
}
export default Auth;