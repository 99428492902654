import { PurchaseApi } from 'src/api';
import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { DtoPage } from '../enities/comonEntities';

export type DiapasonRange = Readonly<{
    seria: string;
    startCode: number;
    endCode: number;
    currentDiapasonId: number;
}>;

export type PagedPurchases = Readonly<{
    currentPage: number;
    rowCount: number;
    pageSize: number;
    results: PurchaseItem[];
}>;

export type PurchaseItem = Readonly<{
    id: number;
    dateSup: Date;
    seria: string;
    startCode: number;
    endCode: number;
    departmentId: number;
    unusedCodesCount: number;
}>;

export type PurchasesState = Readonly<{
    isLoading: boolean;
    page: number;
    rowCount: number;
    pageSize: number;
    purchases: PurchaseItem[];
    singlePurchase: PurchaseItem;
    id: number;
    searchString:string;
}>;

export type ReceivePurchasesPayload = Pick<PurchasesState,
    "purchases" | "pageSize" | "rowCount" | "page" | "id" | "singlePurchase">;

const initialState: PurchasesState = {
    purchases: [],
    isLoading: false,
    page: 0,
    rowCount: 0,
    pageSize: 0,
    singlePurchase: {} as PurchaseItem,
    id: 0,
    searchString:"",
};

export const purchaseSlice = createSlice({
    name: 'purchases',
    initialState,
    reducers: {
        addPurchase: (state, action: PayloadAction<PurchaseItem>) => {
            state.singlePurchase = action.payload;
        },
        requestPurchase: (state, action: PayloadAction<number>) => {
            state.isLoading = true;
            state.id = action.payload;
        },
        receivePurchase: (state, action: PayloadAction<ReceivePurchasesPayload>) => {
            const { id, singlePurchase } = action.payload;
            state.isLoading = false;
            state.id = id;
            state.singlePurchase = singlePurchase;
        },
        requestPurchases: (state, action: PayloadAction<number>) => {
            state.isLoading = true;
            state.page = action.payload;
        },
        receivePurchases: (state, action: PayloadAction<ReceivePurchasesPayload>) => {
            const { purchases, page, rowCount, pageSize } = action.payload;
            state.isLoading = false;
            state.purchases = purchases;
            state.page = page;
            state.rowCount = rowCount;
            state.pageSize = pageSize;  
        },
        setSearchString: (state, action: PayloadAction<string>) => {
            state.searchString = action.payload;
        },
    }
});


export const validateDiapasonAsync = createAsyncThunk(
    'purchases/validateDiapasonAsync',
     async (range: DiapasonRange, {fulfillWithValue }) => {
        try {
            const result = await PurchaseApi.validateDiapasonAsync(range);
            return fulfillWithValue(result);
        } catch (err: any) {
            return fulfillWithValue(false);
        }
    }
);

export const deletePurchaseAsync = createAsyncThunk(
    'purchases/deletePurchaseAsync',
    async (id: number, { dispatch }) => {
        dispatch(requestPurchase(id));
        try {
            const singlePurchase = await PurchaseApi.deletePurchaseAsync(id);
            const payload = {
                singlePurchase, id, purchases: [],
                pageSize: 0, page: 0, rowCount: 0
            };
            dispatch(receivePurchase(payload));
        } catch (e) {
            console.error(e);
        }
    }
);

export const getPurchaseAsync = createAsyncThunk(
    'purchases/getPurchaseAsync',
    async (id: number, { dispatch }) => {
        dispatch(requestPurchase(id));
        try {
            const singlePurchase = await PurchaseApi.getPurchaseAsync(id);
            const payload = {
                singlePurchase, id, purchases: [],
                pageSize: 0, page: 0, rowCount: 0
            };
            dispatch(receivePurchase(payload));
        } catch (e) {
            console.error(e);
        }
    }
);

export const editPurchaseAsync = createAsyncThunk(
    'purchases/editPurchaseAsync',
    async (purchase: PurchaseItem, { dispatch }) => {
        try {
            await PurchaseApi.editPurchaseAsync(purchase);
            dispatch(addPurchase(purchase));
        } catch (e) {
            console.error(e);
        }
    }
);

export const addPurchaseAsync = createAsyncThunk(
    'purchases/addPurchaseAsync',
    async (purchase: PurchaseItem, { dispatch }) => {
        try {
            await PurchaseApi.addPurchaseAsync(purchase);
            dispatch(addPurchase(purchase));
        } catch (e) {
            console.error(e);
        }
    }
);

export const getPurchasesPageAsync = createAsyncThunk(
    'purchases/getPurchasesPageAsync',
    async (dto: DtoPage, { dispatch, getState }) => {
        dispatch(requestPurchases(dto.page));
        try {
            const purchases = await PurchaseApi.getPurchasesPageAsync(dto);
            const payload = {
                purchases: purchases.results, pageSize: purchases.pageSize,
                page: purchases.currentPage, rowCount: purchases.rowCount,
                singlePurchase: {} as PurchaseItem, id: 0
            };
            dispatch(receivePurchases(payload));
        } catch (e) {
            console.error(e);
        }
    }
);

export const { requestPurchases, receivePurchases, requestPurchase, receivePurchase, addPurchase, setSearchString } = purchaseSlice.actions;

export default purchaseSlice.reducer;