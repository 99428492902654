import { Spinner } from '../../components';
import { useNavigate } from 'react-router-dom';
import { useEffect, type FunctionComponent } from 'react';
import { useAppSelector, useAppDispatch } from '../../store';
import { getUploadAsync, getUploadExportAsync, type UploadItem } from '../../store/uploadReportSlice';
import { getUsersAsync, type UserItem } from '../../store/userSlice';
import { Box, Button, Grid, MenuItem, Select, Tooltip, type SelectChangeEvent } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, } from '@fortawesome/free-solid-svg-icons';
import { padLeft, textLabels } from '../../config';
import MUIDataTable from 'mui-datatables';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import getStatusName from "./../../hooks/getStatusName";
import React from 'react';
import { FilterAlt } from '@mui/icons-material';

const UploadReport: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const startDate = useAppSelector<string>((state) => state.uploadReport.startDate);
    const endDate = useAppSelector<string>((state) => state.uploadReport.endDate);
    const isLoading = useAppSelector<boolean>((state) => state.uploadReport.isLoading);
    const items = useAppSelector<UploadItem[]>((state) => state.uploadReport.items);
    const users = useAppSelector<UserItem[]>((state) => state.user.users);

    const [selectUsers, setSelectUsers] = React.useState<string[]>([] as string[]);
    const [startDateString, setStartDateString] = React.useState<string>("");
    const [endDateString, setEndDateString] = React.useState<string>("");

    const columns = [
        {
            name: "id",
            label: "#",
            options: {
                visible: false
            }
        },
        {
            name: "seria",
            label: "Серия",
            options: {
                sort: true
            }
        },
        {
            name: "startCode",
            label: "Стартовый номер",
            options: {
                sort: false,
                customBodyRender: (value: any, tableMeta: { rowData: any; }, updateValue: any) => padLeft(value, 9),
            }            
        },
        {
            name: "endCode",
            label: "Конечный номер",
            options: {
                sort: false,
                customBodyRender: (value: any, tableMeta: { rowData: any; }, updateValue: any) => padLeft(value, 9),
            }
        },
        {
            name: "used",
            label: "Количество использованных из диапазона",
            options: {
                sort: false
            }
        },
        {
            name: "defect",
            label: "Количество забракованных из диапазона",
            options: {
                sort: false
            }
        },
        {
            name: "changedOn",
            label: "Дата изменения",
            options: {
                customBodyRender: (value: any, tableMeta: { rowData: any; }, updateValue: any) => value ? dayjs(value).format("DD.MM.YYYY HH:mm:ss") : ""
            }
        },
        {
            name: "changedBy",
            label: "Изменено",
            options: {
                sort: true
            }
        },
        {
            name: "sendBy",
            label: "Отправлено",
            options: {
                sort: true
            }
        },
        {
            name: "dateSend",
            label: "Дата отправки",
            options: {
                customBodyRender: (value: any, tableMeta: { rowData: any; }, updateValue: any) => value ? dayjs(value).format("DD.MM.YYYY"):""  
            }
        },
        {
            name: "status",
            label: "Статус отправки",
            options: {
                sort: true,
                customBodyRender: (value: any, tableMeta: { rowData: any; }, updateValue: any) => getStatusName(value)
            }
        },
    ];

    const HeaderElements = () => (
        <>
            <Tooltip title="Назад">
                <Button
                    variant="outlined"
                    startIcon={<FontAwesomeIcon icon={faArrowCircleLeft} />}
                    className="float-start"
                    onClick={() => { navigate(-1); }}>
                    Назад
                </Button>
            </Tooltip>
        </>
    );
    useEffect(() => {
        dispatch(getUsersAsync(0)); }, [dispatch]);
    
    useEffect(() => {
        setStartDateString(startDate)
        setEndDateString(endDate)
        dispatch(getUploadAsync({ startDate, endDate, users: selectUsers })) 
    }, [dispatch, startDate, endDate, 
        // selectUsers
    ]);

    return (
        <>
            <Box component="span" sx={{ p: 2 }}>
                <Grid container spacing={2}>
                    <Grid display="flex" justifyContent="center" alignItems="center" item xs={2} >
                        <h3 className="title is-4">Реализация УКЗ</h3>
                    </Grid>
                    <Grid justifyContent="center" item xs={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker label="Начало периода" 
                                // value={dayjs(new Date(startDate))} 
                                value={dayjs(new Date(startDateString))} 
                                format='DD.MM.YYYY' 
                                onChange={(value: any) => {
                                    setStartDateString(dayjs(value).toISOString());
                                    // dispatch(getUploadAsync({ startDate: dayjs(value).toISOString(), endDate, users: selectUsers }));
                                }}/>
                        </LocalizationProvider>
                    </Grid>
                    <Grid justifyContent="center" item xs={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker label="Окончание периода" 
                                // value={dayjs(new Date(endDate))} 
                                value={dayjs(new Date(endDateString))} 
                                format='DD.MM.YYYY'
                                onChange={(value:any) => {
                                    setEndDateString(value.toDate());
                                    // dispatch(getUploadAsync({ startDate, endDate: value.toDate(), users: selectUsers }));
                                }} />
                        </LocalizationProvider>
                    </Grid>
                    <Grid justifyContent="center" item xs={4}>
                        <Select style={{ width: "400px" }}
                            multiple
                            value={selectUsers}
                            onChange={(event: SelectChangeEvent<typeof selectUsers>) => {                               
                                const values = event.target.value as string[];
                                setSelectUsers(values);
                                // dispatch(getUploadAsync({ startDate, endDate, users: values }));
                            }}>
                            {users.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.firstName + ' ' + item.lastName + ' (' + item.email+')'}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid display="flex" justifyContent="center" alignItems="center" item xs={2} >
                        <Button
                            variant="outlined"
                            startIcon={<FilterAlt  />}
                            className="float-start"
                            onClick={() => {
                                // console.log("values", startDate, endDate, selectUsers )
                                // console.log("values", startDateString, endDateString, selectUsers )
                                dispatch(getUploadAsync({ startDate:startDateString, endDate:endDateString, users: selectUsers }));
                            }}>
                            Применить фильтры
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Spinner isLoading={isLoading} />
            <MUIDataTable
                title=""
                data={items}
                columns={columns}
                options={{
                    textLabels: textLabels,
                    filter: false,
                    pagination: false,
                    selectableRows: 'none',
                    onDownload: (buildHead, buildBody, columns, data) => {
                        dispatch(getUploadExportAsync({ startDate, endDate, users:selectUsers }));
                        return false;
                    }, 
                    customToolbar: () => (<HeaderElements />),
                }}
            />
        </>
    );
};

export default UploadReport;
