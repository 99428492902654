import Layout from './Layout';
import { Routes as routes } from './config';
import { useEffect, type FunctionComponent } from 'react';
import { useIsLoggedIn, userCheckRole } from './hooks';
import { useCSSTransitionProps } from './hooks';
import { useLocation, Route, Routes, Navigate, useNavigate  } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useBeforeunload } from 'react-beforeunload';

const App: FunctionComponent = () => {
    const location = useLocation();
    const cssProps = useCSSTransitionProps();
    const isLoggedIn = useIsLoggedIn();
    const navigate = useNavigate();

    useBeforeunload(() => {
        if (localStorage.getItem("remember") == null) {
            localStorage.removeItem("token");
            localStorage.removeItem('auth_user');
        }
    });

    useEffect(() => {
        if (!isLoggedIn)
            navigate("/");
    }, [
        isLoggedIn
    ]);

    return (
        <Layout>
            <SwitchTransition mode="out-in">
                <CSSTransition {...cssProps}>
                    <Routes location={location}>
                        {routes.map(({ path, roles, Component }) => (
                            (userCheckRole(roles)) ?
                            <Route key={path} path={path}
                                element={isLoggedIn || path === '/' ? <Component /> : <Navigate to='/' />} /> :
                            <></>)
                        )}
                    </Routes>
                </CSSTransition>
            </SwitchTransition>
        </Layout>
    );
};

export default App;