import { ReportApi } from 'src/api';
import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import fs from 'fs/promises';

export type BalanceRequest = Readonly<{
    startDate: string;
    endDate: string;
    departmentId:number;
}>;

export type BalanceItem = Readonly<{
    pos: number;
    date_pos: Date;
    start_value: number;
    out_value: number;
    in_value: number;
    defect_value: number;
    end_value: number;
}>;

export type BalancesState = Readonly<{
    isLoading: boolean;
    startDate: string;
    endDate: string;
    items: BalanceItem[];
}>;

export type ReceiveBalancesPayload = Pick<BalancesState, "items" | "startDate" | "endDate">;

const initialState: BalancesState = {
    items: [],
    isLoading: false,
    startDate: dayjs(new Date()).add(-1, 'month').toISOString(),
    endDate: dayjs(new Date()).toISOString()
};

export const balanceReportSlice = createSlice({
    name: 'balances',
    initialState,
    reducers: {
        requestItems: (state) => {
            state.isLoading = true;
        },
        requestBlob: (state) => {
            state.isLoading = true;
        },
        receiveBlob: (state) => {
            state.isLoading = false;
        },
        receiveItems: (state, action: PayloadAction<ReceiveBalancesPayload>) => {
            const { items, startDate, endDate } = action.payload;
            state.isLoading = false;
            state.items = items;
            state.startDate = startDate;
            state.endDate = endDate;
        }
    }
});

export const getBalanceAsync = createAsyncThunk(
    'reports/getBalanceAsync',
    async (request: BalanceRequest, { dispatch }) => {
        dispatch(requestItems());
        try {
            const items = await ReportApi.getBalanceAsync(request.startDate, request.endDate, request.departmentId);
            const payload = { items, startDate: request.startDate, endDate: request.endDate };
            dispatch(receiveItems(payload));
        } catch (e) {
            console.error(e);
        }
    }
);

export const getBalanceExportAsync = createAsyncThunk(
    'reports/getBalanceExportAsync',
    async (request: BalanceRequest, { dispatch }) => {
        dispatch(requestBlob());
        try {
            const response = await ReportApi.getBalanceExportAsync(request.startDate, request.endDate, request.departmentId);
            const byteArray = new Uint8Array(response);
            const url = window.URL.createObjectURL(new Blob([byteArray]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "balance.xlsx");
            document.body.appendChild(link);
            link.click();

            dispatch(receiveBlob());
        } catch (e) {
            console.error(e);
        }
    }
);

export const { requestItems, receiveItems, receiveBlob, requestBlob } = balanceReportSlice.actions;

export default balanceReportSlice.reducer;