/* eslint-disable @typescript-eslint/no-redeclare */
import { AuthApi } from 'src/api';
import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

export const AuthStatusEnum = {
    FAIL: 'fail',
    NONE: 'none',
    PROCESS: 'process',
    SUCCESS: 'success'
} as const;

export const localStorageGisTokenKey = "gisToken";
export const localStorageGisLoginKey = "gisLogin";

export type AuthStatusEnum = typeof AuthStatusEnum[keyof typeof AuthStatusEnum];

export type AuthState = AuthUser;

export type Credentials = {
    userName?: string;
    password?: string;
    rememberMe: boolean;
};

export type GisCredentials = {
    login: string;
    password: string;
    // token: string;
};

export type ChangePasswordCredentials = {
    userName?: string;
    currentPassword?: string;
    newPassword?: string;
    newPassword2?: string;
};

export type AuthUser = {
    isLoading: boolean;
    token?: string;
    userName?: string;
    status: AuthStatusEnum;
    roles: number[];
    isNeedToChangePassword: boolean;
};

const initialState: AuthState = {
    isLoading: false,
    token: '',
    userName: '',
    status: AuthStatusEnum.NONE,
    roles:[],
    isNeedToChangePassword:false
};

const replaceState = (state: AuthState, { status, token, userName, roles }: AuthState
) => {
    if (token != '') {
        localStorage.setItem('token', token ?? '');
        localStorage.setItem('auth_user', JSON.stringify({ status, userName, roles }));
    }
    else {
        localStorage.removeItem('token');
        localStorage.removeItem('auth_user');
    }
    state.token = token;
    state.status = status;
    state.userName = userName;
    state.roles = roles;
    state.isLoading = false;
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthStatus: (state, action: PayloadAction<AuthStatusEnum>) => {
            replaceState(state, initialState);
            state.status = action.payload;
        },
        loadingUser: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setUserLogin: (state, action: PayloadAction<AuthState>) => {
            replaceState(state, action.payload);
        },
        resetState: (state) => {
            replaceState(state, initialState);
        },
        rememberMe: (state, action: PayloadAction<boolean>) => {
            if (action.payload) {
                localStorage.setItem('remember', action.payload.toString());
            }
            else {
                localStorage.removeItem('remember');
            }
        },
        setGisToken: (state, action: PayloadAction<string>) => {
            if (action.payload.length > 0) {
                localStorage.setItem(localStorageGisTokenKey, action.payload.toString());
            }
            else {
                localStorage.removeItem(localStorageGisTokenKey);
            }
        },
    }
});

export const rememberMeAsync = createAsyncThunk(
    'auth/rememberMeAsync',
    async (value: boolean, { dispatch }) => {
        try {
            dispatch(rememberMe(value));
        } catch (e) {
            dispatch(rememberMe(false));
        }
    }
);

export const loginAsync = createAsyncThunk(
    'auth/loginAsync',
    async (credentials: Credentials, { dispatch, fulfillWithValue, rejectWithValue }) => {
        loadingUser(true);
        try {            
            const authUser = await AuthApi.loginAsync(credentials);
            // dispatch(setUserLogin(authUser));
            if(!authUser.isNeedToChangePassword)
                dispatch(setUserLogin(authUser));
            loadingUser(false);
            return fulfillWithValue(authUser);
        } catch (err:any) {
            loadingUser(false);
            dispatch(resetState());    
            dispatch(setAuthStatus(AuthStatusEnum.FAIL));
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response)
        }
    }
);

export const loginGisAsync = createAsyncThunk(
    'auth/loginAsync',
    async (gisCredentials: GisCredentials, { dispatch, fulfillWithValue, rejectWithValue }) => {
        // loadingUser(true);
        try {            
            const gisAuthUserResponse = await AuthApi.loginGisAsync(gisCredentials);
            // dispatch(setUserLogin(authUser));
            console.log("loginGisAsync gisAuthUserResponse", gisAuthUserResponse)
            // if(!authUser.isNeedToChangePassword)
            //     dispatch(setUserLogin(authUser));
            // loadingUser(false);
            dispatch(setGisToken(gisAuthUserResponse.data.token));
            return gisAuthUserResponse; 
        } catch (err:any) {
            console.error(err);
            dispatch(setGisToken(""));
            if(err instanceof AxiosError){
                let axiosError = err as AxiosError
                return axiosError.response
            }
            // loadingUser(false);
            // dispatch(resetState());    
            // dispatch(setAuthStatus(AuthStatusEnum.FAIL));
            // if (!err.response) {
            //     throw err
            // }
            // return rejectWithValue(err.response)
        }
    }
);

export const { setAuthStatus, setUserLogin, resetState, rememberMe, loadingUser, setGisToken } = authSlice.actions;

export default authSlice.reducer;