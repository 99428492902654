import type { ComponentType } from 'react';
import type { Params } from 'react-router-dom';
import {
    Audit, Users, UserAdd, UserEdit,
    Purchases, PurchaseAdd, PurchaseEdit,
    Performs, PerformAdd,PerformEdit
} from '../containers';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import Dashboard from '../containers/Dashboard';
import BalanceReport from '../containers/Dashboard/BalanceTable';
import UploadReport from '../containers/Dashboard/UploadTable';
import RemissionReport from '../containers/Dashboard/RemissionTable';
import Auth from '../containers/Auth';
import { Roles } from './constants';

export const TRANSITION_DEFAULT = {
    classNames: 'fade',
    timeout: { enter: 250, exit: 250 }
};

export type RouteComponent = ComponentType<any>;
export type Transition = typeof TRANSITION_DEFAULT;

export type Route = Readonly<{
    title?: string;
    name: string;
    path: string;
    icon?: IconProp;
    showInNav?: boolean;
    transition: Transition;
    Component: RouteComponent;
    roles:number[],
    params?: Readonly<Params<string>>;
}>;

export const Routes: Route[] = [
    {
        path: '/',       
        name: 'Logout',
        Component: Auth,
        roles: [Roles.All],
        transition: TRANSITION_DEFAULT
    },
    {
        showInNav: false,
        path: '/audit/:id/:type',
        name: 'Audit',
        Component: Audit,
        roles: [Roles.All],
        transition: TRANSITION_DEFAULT,
        params: {id: '0',  type: '' }
    },
    {
        showInNav: true,
        title: "Закупленные диапазоны",
        path: '/purchases',
        name: 'Purchases',
        roles: [Roles.Admin, Roles.Purchase, Roles.ReadOnly],
        Component: Purchases,
        transition: TRANSITION_DEFAULT
    },
    {
        showInNav: false,
        path: '/purchase/add',
        name: 'PurchaseAdd',
        roles: [Roles.Admin, Roles.Purchase],
        Component: PurchaseAdd,
        transition: TRANSITION_DEFAULT
    },
    {
        showInNav: false,
        path: '/purchases/edit/:id',
        name: 'PurchaseEdit',
        Component: PurchaseEdit,
        roles: [Roles.Admin, Roles.Purchase],
        transition: TRANSITION_DEFAULT,
        params: {
            id: '0'
        }
    },
    {
        showInNav: true,
        title: "Реализованные диапазоны",
        path: '/performs',
        name: 'Performs',
        roles: [Roles.Admin, Roles.Perform, Roles.ReadOnly],
        Component: Performs,
        transition: TRANSITION_DEFAULT
    },
    {
        showInNav: false,
        path: '/perform/add',
        name: 'PerformAdd',
        Component: PerformAdd,
        roles: [Roles.Admin, Roles.Perform],
        transition: TRANSITION_DEFAULT
    },
    {
        showInNav: false,
        path: '/perform/edit/:id',
        name: 'PerformEdit',
        Component: PerformEdit,
        roles: [Roles.Admin, Roles.Perform],
        transition: TRANSITION_DEFAULT,
        params: {
            id: '0'
        }
    },
    {
        showInNav: true,
        path: '/home',
        title: "Отчеты",
        name: 'Home',
        roles: [Roles.Admin, Roles.Reports],
        Component: Dashboard,
        transition: TRANSITION_DEFAULT
    },
    {
        showInNav: false,
        path: '/reports/balance',
        title: "Оборотная ведомость",
        name: 'ReportBalance',
        roles: [Roles.Admin, Roles.Reports],
        Component: BalanceReport,
        transition: TRANSITION_DEFAULT
    }, 
    {
        showInNav: false,
        path: '/reports/upload',
        title: "Отчет о реализации УКЗ",
        name: 'ReportUpload',
        roles: [Roles.Admin, Roles.Reports],
        Component: UploadReport,
        transition: TRANSITION_DEFAULT
    }, 
    {
        showInNav: false,
        path: '/reports/remission',
        title: "Отчет о cписание УКЗ",
        name: 'ReportRemission',
        roles: [Roles.Admin, Roles.Reports],
        Component: RemissionReport,
        transition: TRANSITION_DEFAULT
    }, 
    {
        showInNav: true,
        title: "Пользователи",
        path: '/users',
        name: 'Users',
        roles: [Roles.Admin],
        Component: Users,
        transition: TRANSITION_DEFAULT
    },
    {
        showInNav: false,
        path: '/users/add',
        name: 'UserAdd',
        roles: [Roles.Admin],
        Component: UserAdd,
        transition: TRANSITION_DEFAULT
    },
    {
        showInNav: false,
        path: '/users/edit/:id',
        name: 'UserEdit',
        roles: [Roles.Admin],
        Component: UserEdit,
        transition: TRANSITION_DEFAULT,
        params: {
            id: '0'
        }
    }
];