import { Spinner } from '../../components';
import { useParams, useNavigate, generatePath } from 'react-router-dom';
import { useEffect, type FunctionComponent, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../store';
import { deletePerformAsync, getPerformsGroupAsync, getPerformsPageAsync, sendPerformAsync, type PerformGroupItem, type PerformItem, sendPerformsGroupAsync, setSearchString, type SendPerformsToDatamarkRequest, sendPerformToDatamarkAsync } from '../../store/performSlice';
import { Box, Button, ButtonGroup, CircularProgress, Divider, FormControlLabel, Grid, IconButton, Modal, Switch, TableCell, TableRow, TextField, Tooltip, Typography, type SxProps, type Theme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faUpload } from '@fortawesome/free-solid-svg-icons';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ListIcon from '@mui/icons-material/List';
import OutboxIcon from '@mui/icons-material/Outbox';
import { Departments, GisLoginStatus, padLeft, Roles, Routes, Status, textLabels } from '../../config';
import Swal from 'sweetalert2';
import MUIDataTable, { type MUIDataTableColumnDef } from 'mui-datatables';
import getStatusName from '../../hooks/getStatusName';
import type { DtoPage, DtoPageWithIdsOfGroup } from '../../enities/comonEntities';
import { userCheckRole } from '../../hooks';
import dayjs from 'dayjs';
import { FilterAlt } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PerformGroupItemTDO, PerformItemTDO } from './PerformGroupTable';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { localStorageGisLoginKey, loginGisAsync, type GisCredentials, type AuthUser } from 'src/store/authSlice';

enum FilterColumnNames {
    dateSend = "dateSend",
    defect = "defect",
    startCode = "startCode",
    endCode = "endCode",
    status = "status",
    userSendName = "userSendName",
    departmentId = "departmentId"
};

interface IPerformSingleGroupTableProps {
    data: PerformGroupItemTDO
}


const PerformSingleGroupTable: FunctionComponent<IPerformSingleGroupTableProps> = (props) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();


    // const { page: pageDefault = '0' } = useParams();
    // const pageInit = parseInt(pageDefault, 0);

    // const isLoading = useAppSelector<boolean>((state) => state.perform.isLoading);
    // const performs = useAppSelector<PerformGroupItem[]>((state) => state.perform.performGroups);
    const page = useAppSelector<number>((state) => state.perform.page);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [isGisAuthInProcess, setIsGisAuthInProcess] = useState<GisLoginStatus>(GisLoginStatus.None);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => {
        if(isGisAuthInProcess != GisLoginStatus.InProcess && isGisAuthInProcess != GisLoginStatus.Success){
            setModalTitleClear();
            setIsGisAuthInProcess(GisLoginStatus.None);
            setOpenModal(false);
        }
    };
    const [modalTitle, setModalTitle] = useState<string>("");
    const [performItemsData, setPerformItemsData] = useState<PerformItemTDO[]>([]);
    const [isFilterDateRange, setIsFilterDateRange] = useState<boolean>(false);


    const [filtersValues, setFiltersValues] = useState<Map<FilterColumnNames, string[]>>(new Map());
    const [isFiltersActual, setIsFiltersActual] = useState<boolean>(true);
    const [isSending, setIsSending] = useState<boolean>(false);
    const [sendingIdArray, setSendingIdArray] = useState<number[]>([]);

    const setModalTitleSingle = () => setModalTitle("Отправка диапазона в ГИС «Электронный знак»");
    const setModalTitleGroup = () => setModalTitle("Отправка группы диапазонов в ГИС «Электронный знак»");
    const setModalTitleClear = () => setModalTitle("");

    // const rowCount = useAppSelector<number>((state) => state.perform.rowCount);
    // const pageSize = useAppSelector<number>((state) => state.perform.pageSize);
    // const searchString = useAppSelector<string>((state) => state.perform.searchString);
    const successStatusColor = "#07bc0c";
    const warningStatusColor = "#f1c40f";
    const errorStatusColor = "#e74c3c";
    const style: SxProps<Theme> = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        borderRadius: '5px',
        // border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    const gisLoginLoaderStyle: SxProps<Theme> = {
        position: "fixed",
        top: '34%',
        left: '50%',
        width: "100%",
        height: "100%",
        //backgroundColor: "rgba(0, 0, 0, 0.6)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 999
    };

    const validationSchema = Yup.object().shape({
        gisLogin: Yup.string().required('Обязательное поле'),
        gisPassword: Yup.string().required('Обязательное поле'),
    });

    const formikGis = useFormik({
        initialValues: {
            gisLogin: localStorage.getItem(localStorageGisLoginKey) ?? "",
            gisPassword: ""
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setIsGisAuthInProcess(GisLoginStatus.InProcess);
            console.log("gis modal form values", values);
            localStorage.setItem(localStorageGisLoginKey, values.gisLogin);
            // dispatch(loginGisAsync(dtoWithIds))
            const gisCredentials: GisCredentials = {
                login: values.gisLogin,
                password: values.gisPassword,
            };
            dispatch(loginGisAsync(gisCredentials)).then((result: any) => {
                console.log("loginGisAsync result: ",result);
                switch(result.payload.status){
                    case 200:
                        setIsGisAuthInProcess(GisLoginStatus.Success);
                        let data: AuthUser = result.payload.data;
                        const request: SendPerformsToDatamarkRequest = {
                            ids:sendingIdArray,
                            token: data.token as string
                        }
                        dispatch(sendPerformToDatamarkAsync(request));
                        setIsGisAuthInProcess(GisLoginStatus.None);
                        handleCloseModal();
                        break;
                    default:
                        setIsGisAuthInProcess(GisLoginStatus.Error);
                        break;
                }

                // if(result.payload.status != 200){

                // }
            })//.then((result) => {
            // console.log("sendPerformAsync result", result)
            // if (result.payload != null) {
            //     let updatedRowsData: PerformItemTDO[] = result.payload as PerformItemTDO[];
            //     let gridData: PerformItemTDO[] = props.data.items.map(x => { return x });
            //     gridData.forEach(x => {
            //         if (updatedRowsData.some(y => y.id == x.id)) {
            //             let currentRowIndex = updatedRowsData.findIndex(y => y.id == x.id);
            //             x.status = updatedRowsData[currentRowIndex].status;
            //             x.dateSend = updatedRowsData[currentRowIndex].dateSend;
            //             x.userSendName = updatedRowsData[currentRowIndex].userSendName;
            //         }
            //     })
            //     setPerformItemsData(gridData);
            // }
            //}
        }
    });

    const detailColumns: MUIDataTableColumnDef[]
        = [
            {
                name: "id",
                label: "#",
                options: {
                    filter: false
                }
            },
            {
                label: "Стартовый код",
                name: "startCode",
                options: {
                    filter: true,
                    filterType: 'multiselect',
                    customFilterListOptions: {
                        render: (value: any) => "Стартовый код: " + value,
                    },
                    filterOptions: {
                        logic(prop: string, filterValue: any[], row?: any[]) {
                            console.log("filterOptions logic startCode prop", prop);
                            console.log("filterOptions logic startCode filterValue", filterValue);
                            console.log("filterOptions logic startCode row", row);
                            return false;
                        },
                    },
                    customBodyRender: (value: any, tableMeta: { rowData: any; }, updateValue: any) => padLeft(value, 9)
                }
            },
            {
                label: "Конечный код",
                name: "endCode",
                options: {
                    filter: true,
                    filterType: 'multiselect',
                    customFilterListOptions: {
                        render: (value: any) => "Конечный код: " + value,
                    },
                    filterOptions: {
                        logic(prop: string, filterValue: any[], row?: any[]) {
                            console.log("filterOptions logic endCode prop", prop);
                            console.log("filterOptions logic endCode filterValue", filterValue);
                            console.log("filterOptions logic endCode row", row);
                            return false;
                        },
                    },
                    customBodyRender: (value: any, tableMeta: { rowData: any; }, updateValue: any) => padLeft(value, 9)
                }
            },
            {
                label: "Забраковано",
                name: "defect",
                options: {
                    filter: true,
                    filterType: 'multiselect',
                    customFilterListOptions: {
                        render: (value: any) => "Забраковано: " + value,
                    },
                    filterOptions: {
                        logic(prop: string, filterValue: any[], row?: any[]) {
                            console.log("filterOptions logic defect prop", prop);
                            console.log("filterOptions logic defect filterValue", filterValue);
                            console.log("filterOptions logic defect row", row);
                            return false;
                        },
                    }
                }
            },
            {
                label: "Дата отправки",
                name: "dateSend",
                options: {
                    filter: true,
                    filterType: 'custom',
                    customFilterListOptions: {
                        render: value => {
                            console.log("customFilterListOptions render value", value);
                            if (value != null && value.length > 0) {
                                if (value.length == 2 && value[0].length > 0 && value[1].length > 0) {
                                    let rangeOfDateString: string[] = [dayjs(value[0]).format("DD.MM.YYYY") + " - " + dayjs(value[1]).format("DD.MM.YYYY")];
                                    return rangeOfDateString;
                                } else if (value.length == 1 && value[0].length > 0) {
                                    let oneDateString: string[] = [dayjs(value[0]).format("DD.MM.YYYY")];
                                    return oneDateString;
                                }
                            }
                            return [];
                        },
                        update: (filterList, filterPos, index) => {
                            console.log("customFilterListOptions update filterList", filterList);
                            console.log("customFilterListOptions update filterPos", filterPos);
                            console.log("customFilterListOptions update index", index);
                            return filterList;
                        },
                    },
                    filterOptions: {
                        // names: [],
                        logic(prop: string, filterValue: any[], row?: any[]) {
                            console.log("filterOptions logic dateSend prop", prop);
                            console.log("filterOptions logic dateSend filterValue", filterValue);
                            console.log("filterOptions logic dateSend row", row);
                            return false;
                        },
                        display: (filterList, onChange, index, column, filterData) => (
                            // {
                            // const [isFilterDateRange, setIsFilterDateRange] = useState<boolean>(false);
                            // let 

                            // return(

                            //     // <div>date filter</div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            // ref={}
                                            checked={isFilterDateRange}
                                            onChange={() => { setIsFilterDateRange(!isFilterDateRange) }}
                                        />
                                    }
                                    label="Промежуток дат"
                                />
                                <div style={{ display: "flex", gap: "5px", }}>
                                    <DatePicker
                                        slotProps={{
                                            field: {
                                                clearable: true,
                                                // onClear: () => {
                                                //     filterList.splice(index,1,[]);
                                                //     onChange(filterList[index], index, column);
                                                // } 
                                            },
                                        }}
                                        // maxDate={filterEndDate.length > 0 ? dayjs(filterEndDate).subtract(1,'day') : null}
                                        maxDate={filterList[index].length == 2 ? dayjs(filterList[index][1]).subtract(1, 'day') : null}
                                        // value={filterStartDate.length > 0 ? dayjs(filterStartDate): null}
                                        value={filterList[index][0] && filterList[index][0].length > 0 ? dayjs(filterList[index][0]) : null}
                                        onChange={(value: any, context) => {
                                            let stringValue = value?.toDate().toISOString();
                                            console.log("DatePicker onChange stringValue", stringValue);
                                            console.log("DatePicker onChange stringValue != null", stringValue != null);
                                            filterList[index][0] = stringValue != null ? stringValue : "";
                                            onChange(filterList[index], index, column);
                                        }}
                                        format={"DD.MM.YYYY"}
                                    />
                                    {isFilterDateRange &&
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>-</div>
                                    }
                                    {isFilterDateRange &&
                                        <DatePicker
                                            slotProps={{
                                                field: {
                                                    clearable: true,
                                                    // onClear: () => {
                                                    //     let firstValue = filterList[index][0];
                                                    //     filterList.splice(index,1,[firstValue]);
                                                    //     onChange(filterList[index], index, column);
                                                    // }
                                                },
                                            }}
                                            // minDate={filterStartDate.length > 0 ? dayjs(filterStartDate).add(1,'day') : null}
                                            minDate={filterList[index].length > 0 ? dayjs(filterList[index][0]).add(1, 'day') : null}
                                            // value={filterEndDate.length > 0 ? dayjs(filterEndDate): null}
                                            value={filterList[index][1] && filterList[index][1].length > 0 ? dayjs(filterList[index][1]) : null}
                                            onChange={(value: any, context) => {
                                                let stringValue = value?.toDate().toISOString();
                                                console.log("DatePicker onChange stringValue", stringValue);
                                                console.log("DatePicker onChange stringValue != null", stringValue != null);
                                                filterList[index][1] = stringValue != null ? stringValue : "";
                                                onChange(filterList[index], index, column);
                                                // setFilterEndDate(stringValue != null ? stringValue : "")
                                            }}
                                            format={"DD.MM.YYYY"}
                                        />
                                    }
                                </div>
                            </LocalizationProvider>
                        ),

                        // )},
                    },
                    customBodyRender: (value: string, tableMeta: { rowData: any; }, updateValue: any) => {
                        console.log("dateSend value", value);
                        console.log("dateSend tableMeta.rowData", tableMeta.rowData);
                        if (value.toString().startsWith("0"))
                            return ""
                        else
                            return dayjs(value).format("DD.MM.YYYY")
                    }
                }
            },
            {
                label: "Статус отправки",
                name: "status",
                options: {
                    filter: true,
                    filterType: 'multiselect',
                    sort: false,
                    customFilterListOptions: {
                        render: (value: any) => "Статус: " + getStatusName(value),
                    },
                    filterOptions: {
                        logic(prop: string, filterValue: any[], row?: any[]) {
                            console.log("filterOptions logic defect prop", prop);
                            console.log("filterOptions logic defect filterValue", filterValue);
                            console.log("filterOptions logic defect row", row);
                            return false;
                        },
                        renderValue: (value: any) => getStatusName(value)
                    },
                    customBodyRender: (value: any) => (
                        value == Status.Fail ?
                            <div style={{ color: errorStatusColor, fontWeight: 600 }}>{getStatusName(value)}</div>
                            : value == Status.Partially || value == Status.AwaitingSend || value == Status.Processing ?
                                <div style={{ color: warningStatusColor, fontWeight: 600 }}>{getStatusName(value)}</div>
                                : value == Status.Sent ?
                                    <div style={{ color: successStatusColor, fontWeight: 600 }}>{getStatusName(value)}</div>
                                    :
                                    <div>{getStatusName(value)}</div>
                    ),
                }
            },
            {
                label: "Отдел",
                name: "departmentId",
                options: {
                    filter: true,
                    filterType: "multiselect",
                    customBodyRender: (value: any, tableMeta: { rowData: any; }, updateValue: any) => Departments.has(Number(value)) ? Departments.get(Number(value)) : ""
                }
            },
            {
                label: "Отправитель",
                name: "userSendName",
                options: {
                    filter: true,
                    filterType: 'multiselect',
                    sort: false,
                    customFilterListOptions: {
                        render: (value: any) => "Отправитель: " + value,
                    },
                    filterOptions: {
                        logic(prop: string, filterValue: any[], row?: any[]) {
                            console.log("filterOptions logic defect prop", prop);
                            console.log("filterOptions logic defect filterValue", filterValue);
                            console.log("filterOptions logic defect row", row);
                            return false;
                        },
                    }
                }
            },
            {
                label: "Информация",
                name: "information",
                options: {
                    filter: false, sort: false
                }
            },
            {
                name: "Операции",
                options: {
                    filter: false, sort: false,
                    customBodyRender: (value: any, tableMeta: { rowData: any; }, updateValue: any) => {
                        return <>
                            <ButtonGroup variant="text">
                                {(!userCheckRole([Roles.ReadOnly])) ?
                                    <Tooltip title="Редактировать">
                                        <IconButton color="primary" onClick={() => {
                                            const path = Routes.find((x) => x.name === "PerformEdit")?.path ?? "";
                                            const id = tableMeta.rowData[0];
                                            navigate(generatePath(path, { id: id }), { state: { id: id } });
                                        }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip> : <></>}
                                {(!userCheckRole([Roles.ReadOnly])) ?
                                    <Tooltip title="Удалить">
                                        <IconButton color="primary" onClick={() => {
                                            Swal.fire({
                                                title: "Удалить диапазон?",
                                                showClass: { popup: 'animate__animated animate__fadeInDown' },
                                                hideClass: { popup: 'animate__animated animate__fadeOutUp' },
                                                showCancelButton: true,
                                                confirmButtonColor: '#d33',
                                                cancelButtonColor: '#3085d6',
                                                confirmButtonText: 'Удалить',
                                                cancelButtonText: 'Отменить'
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    const id = tableMeta.rowData[0];
                                                    dispatch(deletePerformAsync(id)).then(() => {
                                                        dispatch(getPerformsGroupAsync(
                                                            {
                                                                page: page, sortOrder: '', direction: '',
                                                                searchText: ''
                                                            } as DtoPage));
                                                    });
                                                }
                                            })
                                        }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip> : <></>}
                                <Tooltip title="Информация об изменении версий элемента списка">
                                    <IconButton color="primary" onClick={() => {
                                        const path = Routes.find((x) => x.name === "Audit")?.path ?? "";
                                        const id = tableMeta.rowData[0];
                                        navigate(generatePath(path, { id: id, type: 'perform' }));
                                    }}>
                                        <ListIcon />
                                    </IconButton>
                                </Tooltip>
                                {(userCheckRole([Roles.Admin, Roles.SendData])) ?
                                    <Tooltip title="Отправить в ГИС ЭЗ">
                                        <IconButton color="primary" onClick={() => {
                                            setModalTitleSingle();
                                            handleOpenModal();
                                            const id:number = tableMeta.rowData[0];
                                            setSendingIdArray([id]);
                                            // Swal.fire({
                                            //     title: "Отправить диапазон в ГИС ЭЗ?",
                                            //     showClass: { popup: 'animate__animated animate__fadeInDown' },
                                            //     hideClass: { popup: 'animate__animated animate__fadeOutUp' },
                                            //     showCancelButton: true,
                                            //     confirmButtonText: 'Отправить',
                                            //     cancelButtonText: 'Отменить'
                                            // }).then((result) => {
                                            //     if (result.isConfirmed) {
                                            //         setIsSending(true);
                                            //         const id = tableMeta.rowData[0];
                                            //         dispatch(sendPerformAsync(id)).then((result) => {
                                            //             console.log("sendPerformAsync result", result)
                                            //             if (result.payload != null) {
                                            //                 let updatedRowData = result.payload as PerformItemTDO;
                                            //                 let gridData: PerformItemTDO[] = props.data.items.map(x => { return x });
                                            //                 gridData.forEach(x => {
                                            //                     if (x.id == updatedRowData.id) {
                                            //                         x.status = updatedRowData.status;
                                            //                         x.dateSend = updatedRowData.dateSend;
                                            //                         x.userSendName = updatedRowData.userSendName;
                                            //                     }
                                            //                 })
                                            //                 setPerformItemsData(gridData);
                                            //             }
                                            //             // dispatch(getPerformsGroupAsync(
                                            //             //     {
                                            //             //         page: page, sortOrder: '', direction: '',
                                            //             //         searchText: ''
                                            //             //     } as DtoPage));
                                            //             setIsSending(false);
                                            //         });
                                            //     }
                                            // })
                                        }}>
                                            <OutboxIcon />
                                        </IconButton>
                                    </Tooltip> : <></>}
                            </ButtonGroup>
                        </>;
                    },
                },
            }];



    //PerformItem


    const HeaderGroupElements = () => (
        <>
            {(!userCheckRole([Roles.ReadOnly])) ?
                <>
                    <Tooltip title="Отправить группу">
                        <Button
                            variant="outlined"
                            startIcon={<FontAwesomeIcon icon={faUpload} />}
                            className="float-start"
                            onClick={() => {
                                setModalTitleGroup();
                                handleOpenModal();
                                const ids = performItemsData.map(x => x.id) as number[];
                                setSendingIdArray(ids ?? []);
                                // Swal.fire({
                                //     title: "Отправить группу диапазонов в ГИС ЭЗ?",
                                //     showClass: { popup: 'animate__animated animate__fadeInDown' },
                                //     hideClass: { popup: 'animate__animated animate__fadeOutUp' },
                                //     showCancelButton: true,
                                //     html: `
                                //         <input id="swal-input1" class="swal2-input">
                                //         <input id="swal-input2" class="swal2-input">
                                //     `,
                                //     confirmButtonText: 'Отправить группу',
                                //     cancelButtonText: 'Отменить'
                                // }).then((result) => {
                                //     if (result.isConfirmed) {
                                //         setIsSending(true);
                                //         console.log("props", props)
                                //         console.log("result.isConfirmed", result)
                                //         const ids = performItemsData.map(x => x.id);
                                //         const dtoWithIds = {
                                //             page: page, sortOrder: '', direction: '',
                                //             searchText: '', ids: ids
                                //         } as DtoPageWithIdsOfGroup
                                //         dispatch(sendPerformsGroupAsync(dtoWithIds)).then((result) => {
                                //             console.log("sendPerformAsync result", result)
                                //             if (result.payload != null) {
                                //                 let updatedRowsData: PerformItemTDO[] = result.payload as PerformItemTDO[];
                                //                 let gridData: PerformItemTDO[] = props.data.items.map(x => { return x });
                                //                 gridData.forEach(x => {
                                //                     if (updatedRowsData.some(y => y.id == x.id)) {
                                //                         let currentRowIndex = updatedRowsData.findIndex(y => y.id == x.id);
                                //                         x.status = updatedRowsData[currentRowIndex].status;
                                //                         x.dateSend = updatedRowsData[currentRowIndex].dateSend;
                                //                         x.userSendName = updatedRowsData[currentRowIndex].userSendName;
                                //                     }
                                //                 })
                                //                 setPerformItemsData(gridData);
                                //             }
                                //             // dispatch(getPerformsGroupAsync(dtoWithIds));
                                //             setIsSending(false);
                                //         });
                                //     }
                                // })
                            }}>
                            Отправить группу
                        </Button>
                    </Tooltip>
                </> : <></>}
        </>
    );

    // useEffect(() => {
    //     dispatch(getPerformsGroupAsync(
    //         {
    //             page: pageInit,
    //             sortOrder: '',
    //             direction: '',
    //             searchText: searchString
    //         } as DtoPage));
    // }, [dispatch, pageInit]);

    function saveNewFilters(currentFilterList: string[][], dateRangeShoudBeEmpty: boolean = false) {
        console.log("applyFiltersToData currentFilterList", currentFilterList);

        let startCodeFilterArray: string[] = currentFilterList[1];
        let endCodeFilterArray: string[] = currentFilterList[2];
        let defectFilterArray: string[] = currentFilterList[3];
        let dateSendFilterArray: string[] = currentFilterList[4];
        let statusFilterArray: string[] = currentFilterList[5];
        let departmentLabelFilterArray: string[] = currentFilterList[6];
        let userSendNameFilterArray: string[] = currentFilterList[7];
        // console.log("applyFiltersToData filterStartDate", filterStartDate)
        // console.log("applyFiltersToData filterEndDate", filterEndDate)
        // console.log("applyFiltersToData defectFilterArray", defectFilterArray);
        // console.log("applyFiltersToData statusFilterArray", statusFilterArray);
        // console.log("applyFiltersToData startCodeFilterArray", startCodeFilterArray);
        // console.log("applyFiltersToData endCodeFilterArray", endCodeFilterArray);
        // const newFilterDateGridValue: string[] = [];
        // let requiredFilterStartDate = filterStartDate.has(groupIndex) ? filterStartDate.get(groupIndex) : "";
        // if(requiredFilterStartDate && requiredFilterStartDate.length > 0 && !dateRangeShoudBeEmpty){
        //     newFilterDateGridValue.push(requiredFilterStartDate);
        //     let requiredFilterEndDate = filterEndDate.has(groupIndex) ? filterEndDate.get(groupIndex) : "";
        //     if(requiredFilterEndDate && requiredFilterEndDate.length > 0)
        //         newFilterDateGridValue.push(requiredFilterEndDate);
        // }
        let newFiltersDataMap: Map<FilterColumnNames, string[]> = new Map();

        if (startCodeFilterArray.length > 0)
            // newFiltersDataMap.set({columnName: FilterColumnNames.startCode, groudIndex: groupIndex},startCodeFilterArray)
            newFiltersDataMap.set(FilterColumnNames.startCode, startCodeFilterArray)
        if (endCodeFilterArray.length > 0)
            // newFiltersDataMap.set({columnName: FilterColumnNames.endCode, groudIndex: groupIndex},endCodeFilterArray)
            newFiltersDataMap.set(FilterColumnNames.endCode, endCodeFilterArray)
        if (defectFilterArray.length > 0)
            // newFiltersDataMap.set({columnName: FilterColumnNames.defect, groudIndex: groupIndex},defectFilterArray)
            newFiltersDataMap.set(FilterColumnNames.defect, defectFilterArray)
        if (dateSendFilterArray.length > 0)
            // newFiltersDataMap.set({columnName: FilterColumnNames.startCode, groudIndex: groupIndex},startCodeFilterArray)
            newFiltersDataMap.set(FilterColumnNames.dateSend, dateSendFilterArray)
        if (statusFilterArray.length > 0)
            // newFiltersDataMap.set({columnName: FilterColumnNames.status, groudIndex: groupIndex},statusFilterArray)
            newFiltersDataMap.set(FilterColumnNames.status, statusFilterArray)
        if (userSendNameFilterArray.length > 0)
            // newFiltersDataMap.set({columnName: FilterColumnNames.status, groudIndex: groupIndex},statusFilterArray)
            newFiltersDataMap.set(FilterColumnNames.userSendName, userSendNameFilterArray)
        if (departmentLabelFilterArray.length > 0) {
            let departmentIdFilterArray: string[] = [];
            Departments.forEach((label, id) => {
                if (departmentLabelFilterArray.includes(label)) {
                    departmentIdFilterArray.push(id.toString());
                }
            })
            newFiltersDataMap.set(FilterColumnNames.departmentId, departmentIdFilterArray)
        }

        // let joinedFiltersValues: Map<string, string[]> = new Map();
        filtersValues.forEach((value, key) => {
            if (!newFiltersDataMap.has(key)) {
                let filtersValues = newFiltersDataMap.get(key);
                if (filtersValues && filtersValues.length > 0)
                    newFiltersDataMap.set(key, filtersValues)
            }
            // else{
            //     joinedFiltersValues.set(key, value)
            // }
        })
        setFiltersValues(newFiltersDataMap);

        setIsFiltersActual(false);
    }

    useEffect(() => {
        // isFiltersActual.forEach((value, index) => {
        //     if (value === false) {
        //         console.log("isFiltersActual check index", index, " is false");
        //         filterGridData(index);
        //     }
        // })
        if (isFiltersActual === false)
            filterGridData()
    }, [
        isFiltersActual,
        filtersValues,
        // purchasesData
    ]);

    function filterGridData() {
        if (filtersValues.size > 0 //&& filterValuesNames.some(fn => filtersValues.has(fn))
        ) {
            //создаем копию группы
            let gridData: PerformItemTDO[] = props.data.items.map(x => { return x });
            //фильтруем
            if (filtersValues.has(FilterColumnNames.dateSend)) {
                const dateSendFilterValues = filtersValues.get(FilterColumnNames.dateSend);
                //const dateSendFilterValues = filtersValues.get(FilterColumnNames.dateSend)
                if (dateSendFilterValues != null && dateSendFilterValues.length > 0) {
                    if (dateSendFilterValues.length === 1) {
                        console.log("dateSendFilterValues == 1", dateSendFilterValues)
                        const singleDate = dayjs(dateSendFilterValues[0]);
                        gridData = gridData.filter(d => singleDate.isSame(dayjs(d.dateSend), 'day'))
                    } else if (dateSendFilterValues.length === 2) {
                        console.log("dateSendFilterValues == 2", dateSendFilterValues)

                        let isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
                        let isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
                        dayjs.extend(isSameOrAfter);
                        dayjs.extend(isSameOrBefore);
                        const startDate = dayjs(dateSendFilterValues[0]);
                        const endDate = dayjs(dateSendFilterValues[1]);
                        // console.log("startDate.isSameOrAfter(dayjs('2024-02-02'),'day')", startDate.isSameOrAfter(dayjs('2024-02-02'),'day'))
                        // console.log("startDate.isSameOrBefore(dayjs('2024-02-02'),'day')", startDate.isSameOrBefore(dayjs('2024-02-02'),'day'))

                        // console.log("endDate.isSameOrBefore(dayjs('2024-02-02'),'day')", endDate.isSameOrBefore(dayjs('2024-02-02'),'day'))
                        // console.log("endDate.isSameOrAfter(dayjs('2024-02-02'),'day')", endDate.isSameOrAfter(dayjs('2024-02-02'),'day'))

                        gridData = gridData.filter(d => startDate.isSameOrBefore(dayjs(d.dateSend), 'day') && endDate.isSameOrAfter(dayjs(d.dateSend), 'day'));
                    }
                }
                // gridData = gridData.filter(d => seriaFilterValues?.some(sf => sf === d.seria))
            }
            if (filtersValues.has(FilterColumnNames.startCode)) {
                const startCodeFilterValues = filtersValues.get(FilterColumnNames.startCode);
                if (startCodeFilterValues != null && startCodeFilterValues.length > 0) {
                    gridData = gridData.filter(d => startCodeFilterValues.some(sc => sc.toString() === padLeft(d.startCode != null ? d.startCode.toString() : "", 9)))
                    // let newItems = performGroup.items?.filter(d => startCodeFilterValues.some(sc => sc.toString() === padLeft(d.startCode != null ? d.startCode.toString() : "", 9)))
                    // console.log("startCode newitems", newItems);
                }
            }
            if (filtersValues.has(FilterColumnNames.endCode)) {
                const endCodeFilterValues = filtersValues.get(FilterColumnNames.endCode);
                if (endCodeFilterValues != null && endCodeFilterValues.length > 0) {
                    gridData = gridData.filter(d => endCodeFilterValues.some(sc => sc.toString() === padLeft(d.endCode != null ? d.endCode.toString() : "", 9)))
                    // let newItems = performGroup.items?.filter(d => endCodeFilterValues.some(sc => sc.toString() === padLeft(d.endCode != null ? d.endCode.toString(): "",9)))
                    // console.log("endCode newitems", newItems);
                }

            }
            if (filtersValues.has(FilterColumnNames.defect)) {
                const defectFilterValues = filtersValues.get(FilterColumnNames.defect);
                if (defectFilterValues != null && defectFilterValues.length > 0) {
                    gridData = gridData.filter(d => defectFilterValues.some(sc => sc.toString() === d.defect?.toString()))
                    // let newItems = 
                    // console.log("defecgt newitems", newItems);
                }

            }
            if (filtersValues.has(FilterColumnNames.status)) {
                const statusFilterValues = filtersValues.get(FilterColumnNames.status);
                if (statusFilterValues != null && statusFilterValues.length > 0) {
                    gridData = gridData.filter(d => statusFilterValues.some(sc => sc.toString() === d.status?.toString()))
                    let newItems = gridData.filter(d => statusFilterValues.some(sc => sc.toString() === d.status?.toString()))
                    console.log("status newitems", newItems);
                }

            }
            if (filtersValues.has(FilterColumnNames.userSendName)) {
                const userSendNameFilterValues = filtersValues.get(FilterColumnNames.userSendName);
                if (userSendNameFilterValues != null && userSendNameFilterValues.length > 0) {
                    gridData = gridData.filter(d => userSendNameFilterValues.some(usn => usn.toString() === d.userSendName?.toString()))
                    let newItems = gridData.filter(d => userSendNameFilterValues.some(usn => usn.toString() === d.userSendName?.toString()))
                    console.log("userSendName newitems", newItems);
                }

            }
            if (filtersValues.has(FilterColumnNames.departmentId)) {
                const departmentIdFilterValues = filtersValues.get(FilterColumnNames.departmentId);
                if (departmentIdFilterValues != null && departmentIdFilterValues.length > 0) {
                    gridData = gridData.filter(d => departmentIdFilterValues.some(df => df.toString() === d.departmentId?.toString()))
                    let newItems = gridData.filter(d => departmentIdFilterValues.some(df => df.toString() === d.departmentId?.toString()))
                    console.log("departmentId newitems", newItems);
                }

            }
            //
            //Обновляем данные с учётом фильтров

            setPerformItemsData(gridData);

        } else {
            setPerformItemsData(props.data.items);
        }

        setIsFiltersActual(true)
    }

    useEffect(() => {
        console.log("useEffect performs", props.data)
        setPerformItemsData(props.data.items);
        setIsFiltersActual(false)
    }, [props.data]);


    return (
        <div>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <form onSubmit={formikGis.handleSubmit}>
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" align="center">
                            {modalTitle}
                        </Typography>

                        {/* <Divider> */}
                        <Typography id="modal-modal-description" variant="h6" component="h4" align="center">
                            Введите, пожалуйста, данные для авторизации в ГИС «Электронный знак»:
                        </Typography>
                        {/* </Divider> */}
                        <div>
                            {isGisAuthInProcess == GisLoginStatus.InProcess &&
                                <CircularProgress size={50} sx={gisLoginLoaderStyle} />
                            }
                            <div style={{filter:isGisAuthInProcess == GisLoginStatus.InProcess || isGisAuthInProcess == GisLoginStatus.Success ? "blur(1px)" : ""}}>
                                <TextField
                                    disabled={isGisAuthInProcess == GisLoginStatus.InProcess || isGisAuthInProcess == GisLoginStatus.Success}
                                    margin="normal" required fullWidth autoFocus
                                    id="gisLogin" label="Логин ГИС ЭЗ" name="gisLogin" autoComplete="gisLogin"
                                    value={formikGis.values.gisLogin}
                                    onChange={formikGis.handleChange}
                                    onBlur={formikGis.handleBlur}
                                    error={formikGis.touched.gisLogin && Boolean(formikGis.errors.gisLogin)}
                                    helperText={formikGis.touched.gisLogin && formikGis.errors.gisLogin}
                                />
                                <TextField
                                    disabled={isGisAuthInProcess == GisLoginStatus.InProcess || isGisAuthInProcess == GisLoginStatus.Success}
                                    margin="normal" required fullWidth type="password"
                                    id="gisPassword" label="Пароль ГИС ЭЗ" name="gisPassword"
                                    value={formikGis.values.gisPassword}
                                    onChange={formikGis.handleChange}
                                    onBlur={formikGis.handleBlur}
                                    error={formikGis.touched.gisPassword && Boolean(formikGis.errors.gisPassword)}
                                    helperText={formikGis.touched.gisPassword && formikGis.errors.gisPassword}
                                />
                            </div>

                        </div>

                        {isGisAuthInProcess == GisLoginStatus.InProcess &&  
                            <Typography id="modal-modal-description" variant="h6" component="h4" align="center" sx={{ color: warningStatusColor}}>
                                Пожалуйста, не закрывайте это окно, проходит аутентификация в системе ГИС ЭЗ
                            </Typography>
                        }
                        {isGisAuthInProcess == GisLoginStatus.Error &&  
                            <Typography id="modal-modal-description" aria-multiline variant="h6" component="h4" align="center" sx={{ color: errorStatusColor}}>
                                <div>Во время аутентификация в системе ГИС ЭЗ произошла ошибка.</div>
                                <div>Пожалуйста, проверьте правильность введённых данных и повторите попытку</div>
                            </Typography>
                        }
                        {isGisAuthInProcess == GisLoginStatus.Success &&  
                            <Typography id="modal-modal-description" variant="h6" component="h4" align="center" sx={{ color: successStatusColor}}>
                                <div>Аутентификация в системе ГИС ЭЗ прошла успешно.</div>
                                <div>Пожалуйста, не закрывайте это окно, оно закроется автоматически, как только будет запущен процесс отправки.</div>
                            </Typography>
                        }
                        <Box margin={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={3}>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Button
                                        disabled={isGisAuthInProcess == GisLoginStatus.InProcess || isGisAuthInProcess == GisLoginStatus.Success}
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Отправить
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Button
                                        disabled={isGisAuthInProcess == GisLoginStatus.InProcess || isGisAuthInProcess == GisLoginStatus.Success}
                                        fullWidth
                                        variant="outlined"
                                        sx={{ mt: 3, mb: 2 }}
                                        onClick={() => handleCloseModal()}
                                    >
                                        Отменить
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </form>
            </Modal>
            <MUIDataTable
                //title=""
                title={isFiltersActual && !isSending ? "" : <CircularProgress size={30} />}
                // data={performs[rowMeta.rowIndex].items}
                data={performItemsData}
                // data={dataToShow}
                columns={detailColumns}
                options={{
                    textLabels: textLabels,
                    filter: true,
                    // filterType: 'custom', 
                    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
                        return (
                            <div style={{ marginTop: '40px' }}>
                                <Button
                                    variant="outlined"
                                    startIcon={<FilterAlt />}
                                    className="float-start"
                                    onClick={() =>
                                        saveNewFilters(currentFilterList)
                                    }>
                                    Применить фильтры
                                </Button>
                            </div>
                        );
                    },
                    onFilterChange: (column, filterList, type) => {
                        console.log('onFilterChange event occured', column, filterList, type);
                        if (type === 'chip') {
                            let dateRangeShoudBeEmpty: boolean = false
                            // if (column === "dateSend") {
                            //     dateRangeShoudBeEmpty = true;
                            //     let newFilterStartDate: Map<number, string> = new Map();
                            //     newFilterStartDate.set(rowMeta.rowIndex, "");
                            //     filterStartDate.forEach((value, key) => {
                            //         if (key != rowMeta.rowIndex)
                            //             newFilterStartDate.set(key, value);
                            //     });
                            //     setFilterStartDate(newFilterStartDate);
                            //     let newFilterEndDate: Map<number, string> = new Map();
                            //     newFilterEndDate.set(rowMeta.rowIndex, "");
                            //     filterEndDate.forEach((value, key) => {
                            //         if (key != rowMeta.rowIndex)
                            //             newFilterEndDate.set(key, value);
                            //     });
                            //     setFilterEndDate(newFilterEndDate);
                            // }
                            saveNewFilters(filterList)
                        }
                        if (type === 'reset') {
                            // let newFilterStartDate: Map<number, string> = new Map();
                            // newFilterStartDate.set(rowMeta.rowIndex, "");
                            // filterStartDate.forEach((value, key) => {
                            //     if (key != rowMeta.rowIndex)
                            //         newFilterStartDate.set(key, value);
                            // });
                            // setFilterStartDate(newFilterStartDate);
                            // let newFilterEndDate: Map<number, string> = new Map();
                            // newFilterEndDate.set(rowMeta.rowIndex, "");
                            // filterEndDate.forEach((value, key) => {
                            //     if (key != rowMeta.rowIndex)
                            //         newFilterEndDate.set(key, value);
                            // });
                            // setFilterEndDate(newFilterEndDate);
                            saveNewFilters(filterList);
                        }
                    },
                    search: false,
                    download: false,
                    viewColumns: false,
                    print: false,
                    selectableRows: 'none',
                    pagination: false,
                    customToolbar: () => (<HeaderGroupElements />),
                }}
            />
        </div>

    );
};

export default PerformSingleGroupTable;