import { Box, CircularProgress, InputLabel, MenuItem } from '@mui/material';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import dayjs from 'dayjs';
import Button from "@mui/material/Button";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import { chunkSize, Departments, Routes } from '../../config';
import { useEffect, type FunctionComponent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { editPerformAsync, getPerformAsync, getSeriaCodesAsync, getTvdCodesAsync, type PerformItem, type SeriaItem, type TvdItem } from '../../store/performSlice';
import { useAppSelector, useAppDispatch } from '../../store';
import Swal from 'sweetalert2';
import { validateDiapasonAsync, type DiapasonRange } from '../../store/performSlice';

import { toast, type Id } from 'react-toastify';
import type { AxiosResponse } from 'axios';


const PerformEdit: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const isLoading = useAppSelector<boolean>((state) => state.perform.isLoading);
    const perform = useAppSelector<PerformItem>((state) => state.perform.singlePerform);
    const tvdCodesRef = useAppSelector<TvdItem[]>((state) => state.perform.tvdCodes);
    const seriasRef = useAppSelector<SeriaItem[]>((state) => state.perform.serias);
    const { id:idParam = '0'} = useParams();
    const idParamNumber = parseInt(idParam, 0);
    const [seria, setSeria] = useState<string>("")
    const [seriaIntervalId, setSeriaIntervalId] = useState<number>(perform.departmentId)

    const [showSavingProgress, setShowSavingProgress] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getTvdCodesAsync(0)).then(() => {
            dispatch(getPerformAsync(idParamNumber));
        });
    }, [dispatch, idParamNumber]);

    useEffect(() => {
        dispatch(getSeriaCodesAsync(0));
    }, [dispatch]);

    const validateCode = async (value: any, ctx: any) => {
        const v = ctx.parent;
        // console.log("ctx", ctx);
        // console.log("window.location.pathname", window.location.pathname);
        let pathnameArr = window.location.pathname.split('/');
        // console.log("pathnameArr", pathnameArr);
        // console.log("pathnameArr[pathnameArr.length]", pathnameArr[pathnameArr.length - 1]);
        let id: number = isNaN(parseInt(pathnameArr[pathnameArr.length - 1])) ? 0 : parseInt(pathnameArr[pathnameArr.length - 1]);
        if (v.startCode == 0 || v.endCode == 0) {
            return true;
        }
        if (parseInt(v.startCode) > parseInt(v.endCode)) {
            return true;
        }
        const range = { seria: v?.seria ?? '', startCode: v.startCode, endCode: v.endCode, currentDiapasonId: id } as DiapasonRange;
        console.log("validate range", range);
        const result = await (await dispatch(validateDiapasonAsync(range))).payload;
        return result === true;
    };
    const compareCode = async (value: any, ctx: any) => {
        const v = ctx.parent;
        if (v.startCode == 0 || v.endCode == 0) {
            return true;
        }
        return (parseInt(v.startCode) <= parseInt(v.endCode));
    };
    const requiredCode = async (value: any) => {
        return (parseInt(value) > 0);
    };
    const startCodeInRange = async (value: any, ctx: any) => {
        console.log("value", value);
        console.log("ctx", ctx);
        console.log("seriasRef", seriasRef);
        if(ctx.parent.seria != null && ctx.parent.seria.length > 0){
            const selectedSeria = seriasRef.find(x => x.id == ctx.parent.seria);
            console.log("selectedSeria", selectedSeria);
            let isStartCodeInSeriaInterval = selectedSeria?.intervals.some(x => value >= x.start && value <= x.end && //Валидация вводимого начального кода
                (ctx.parent.endCode == 0 || (ctx.parent.endCode >= x.start && ctx.parent.endCode <= x.end))) //Валидация, что конечный код либо ещё не введён, либо попадает в тот же ЗД
            let selectedSeriaInterval = selectedSeria?.intervals.find(x => value >= x.start && value <= x.end)
            console.log("isStartCodeInSeriaInterval", isStartCodeInSeriaInterval);
            console.log("end seriaIntervalId", seriaIntervalId);
            console.log("start selectedSeriaInterval", selectedSeriaInterval);
            if(isStartCodeInSeriaInterval){
                if(selectedSeriaInterval && selectedSeriaInterval.purchaseId){
                    setSeriaIntervalId(Number(selectedSeriaInterval.purchaseId))
                    return true;
                }   
            }
        }
        
        return false;
    };
    const endCodeInRange = async (value: any, ctx: any) => {
        console.log("value", value);
        console.log("ctx", ctx);
        console.log("seriasRef", seriasRef);
        if(ctx.parent.seria != null && ctx.parent.seria.length > 0){
            const selectedSeria = seriasRef.find(x => x.id == ctx.parent.seria);
            console.log("selectedSeria", selectedSeria);
            let isEndCodeInSeriaInterval = selectedSeria?.intervals.some(x => value >= x.start && value <= x.end && //Валидация вводимого конечного кода
                (ctx.parent.startCode == 0 || (ctx.parent.startCode >= x.start && ctx.parent.startCode <= x.end)) //Валидация, что начальный код либо ещё не введён, либо попадает в тот же ЗД
                )
            let selectedSeriaInterval = selectedSeria?.intervals.find(x => value >= x.start && value <= x.end)
            console.log("isEndCodeInSeriaInterval", isEndCodeInSeriaInterval);
            console.log("end seriaIntervalId", seriaIntervalId);
            console.log("end selectedSeriaInterval", selectedSeriaInterval);
            if(isEndCodeInSeriaInterval){
                if(selectedSeriaInterval && selectedSeriaInterval.purchaseId){
                    setSeriaIntervalId(Number(selectedSeriaInterval.purchaseId))
                    return true;
                }
            }   
        }
        return false;
    };

    const validationSchema = Yup.object().shape({

        startCode: Yup.string().max(9, "Максимальное длинна не больше 9 знаков").required('Обязательное поле')
            .test('startCode', 'Обязательное поле', requiredCode)
            .test('startCode', 'Диапазон уже существует в реализованных диапазонах', validateCode)
            .test('startCode', 'Код должен попадать в диапазон серии', startCodeInRange)
            .test('startCode', 'Стартовый код должен быть меньше конечного кода', compareCode),
            
        endCode: Yup.string().max(9, "Максимальное длинна не больше 9 знаков").required('Обязательное поле')
            .test('endCode', 'Обязательное поле', requiredCode)
            .test('endCode', 'Диапазон уже существует в реализованных диапазонах', validateCode)
            .test('endCode', 'Код должен попадать в диапазон серии', endCodeInRange)
            .test('endCode', 'Конечный код должен быть больше стартового кода', compareCode),
            
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            seria: perform.seria,
            group: perform.group,
            startCode: perform.startCode,
            endCode: perform.endCode,
            defect: perform.defect,           
            information: perform.information,           
            tvdCode: perform.tvdCode ?? "100950000583449", //todo init formik
            // dateSend: perform.dateSend
            userSendName: perform.userSendName,
            departmentId: perform.departmentId,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            toast.dismiss();
            if(seriaIntervalId == -1) //начальные и конечные коды не изменялись
                setSeriaIntervalId(perform.departmentId)
            const performItem: PerformItem = {
                id: perform.id,
                seria: values.seria,
                group: values.group,
                startCode: values.startCode,
                endCode: values.endCode,
                defect: values.defect,
                information: values.information,
                tvdCode: values.tvdCode,
                userSendName: values.userSendName,
                departmentId: values.departmentId,
                purchaseId: seriaIntervalId
                // dateSend: values.dateSend
            };
            if ((values.endCode - values.startCode) > chunkSize) {
                Swal.fire({
                    title: "Диапазон больше максимально возможного для отправки в ГИС ЭЗ",
                    showClass: { popup: 'animate__animated animate__fadeInDown' },
                    hideClass: { popup: 'animate__animated animate__fadeOutUp' },
                    showConfirmButton: true,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'Закрыть'
                });
            }
            else {
                setShowSavingProgress(true);
                toast.info("Идёт сохранение...");
                dispatch(editPerformAsync(performItem)).then((result) => {
                    console.log("result", result);
                    let response = result.payload as any;
                    if(response != null && response.status != null && 
                        response.status == 200){
                            setShowSavingProgress(false);
                            toast.dismiss();
                            toast.success("Сохранено успешно");
                    } else if (response != null && response.status != null && 
                        response.status != 200){
                            setShowSavingProgress(false);
                            toast.dismiss();
                            toast.error("Во время сохранения произошла ошибка! Свяжитесь с администратором системы");
                        }
                    
                    // navigate(Routes.find((x) => x.name === "Performs")?.path ?? "");
                });
            }
        }     
    });

    useEffect(()=>{
        // console.log("PerformEdit seriasRef", seriasRef)
        // console.log("PerformEdit perform", perform)
        // console.log("PerformEdit idParamNumber", idParamNumber)
        console.log("PerformEdit formik", formik)
        setSeria(formik.values.seria)
    },[formik])

    return (
        <div className="section">
            <div className="container">
                <h3 className="title is-4">Изменение реализованных диапазонов УКЗ</h3>
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink>Серия:</InputLabel>
                                    <TextField
                                        select
                                        required
                                        fullWidth
                                        name="seria"
                                        type="select"
                                        id="seria"
                                        value={seria}
                                        // value={formik.values.seria}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.seria && Boolean(formik.errors.seria)}
                                        helperText={formik.touched.seria && formik.errors.seria}
                                    >
                                        {seriasRef.map(x => (
                                            <MenuItem key={x.id} value={x.id}>
                                                {x.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {/*<TextField*/}
                                    {/*    required fullWidth*/}
                                    {/*    id="seria" name="seria"*/}
                                    {/*    value={formik.values.seria}*/}
                                    {/*    onChange={formik.handleChange}*/}
                                    {/*    onBlur={formik.handleBlur}*/}
                                    {/*    error={formik.touched.seria && Boolean(formik.errors.seria)}*/}
                                    {/*    helperText={formik.touched.seria && formik.errors.seria}*/}
                                    {/*/>*/}
                                </Grid>
                                <Grid item sm={6}>
                                    <InputLabel shrink>Код ТН ВЭД:</InputLabel>
                                    <TextField
                                        select
                                        required
                                        fullWidth
                                        name="tvdCode"                                       
                                        type="select"
                                        id="tvdCode"
                                        value={formik.values.tvdCode}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.tvdCode && Boolean(formik.errors.tvdCode)}
                                        helperText={formik.touched.tvdCode && formik.errors.tvdCode}
                                    >
                                        {tvdCodesRef.map(x => (
                                            <MenuItem key={x.id} value={x.id}>
                                                {x.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink>Стартовый код:</InputLabel>
                                    <TextField
                                        required fullWidth
                                        id="startCode" name="startCode" type="number"
                                        value={formik.values.startCode}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.startCode && Boolean(formik.errors.startCode)}
                                        helperText={formik.touched.startCode && formik.errors.startCode}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink>Конечный код:</InputLabel>
                                    <TextField
                                        required fullWidth
                                        id="endCode" name="endCode" type="number"
                                        value={formik.values.endCode}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.endCode && Boolean(formik.errors.endCode)}
                                        helperText={formik.touched.endCode && formik.errors.endCode}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink>Забраковано:</InputLabel>
                                    <TextField
                                        required fullWidth
                                        id="defect" name="defect" type="number"
                                        value={formik.values.defect}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.defect && Boolean(formik.errors.defect)}
                                        helperText={formik.touched.defect && formik.errors.defect}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink>Всего:</InputLabel>
                                    <TextField
                                        fullWidth
                                        id="amount" name="amount" type="number"
                                        value={formik.values.endCode - formik.values.startCode + 1}
                                        InputProps={{
                                            readOnly: true,
                                          }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink>Отдел:</InputLabel>
                                    <TextField
                                        fullWidth
                                        id="department" name="department" type="string"
                                        value={Departments.has(Number(formik.values.departmentId)) ? Departments.get(Number(formik.values.departmentId)) : ""}
                                        InputProps={{
                                            readOnly: true,
                                          }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box margin={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={5}>
                                    {/*<pre>{JSON.stringify(formik.values, null, 2)}</pre>*/}
                                    {/*<pre>{JSON.stringify(tvdCodesRef)}</pre>*/}
                                </Grid>
                                <Grid item xs={12} sm={1} style={{display: "flex", justifyContent:"center", alignItems: "center"}}>
                                    { showSavingProgress && 
                                        <CircularProgress size={25} />
                                    }
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Button
                                        disabled={isLoading}
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Сохранить
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        sx={{ mt: 3, mb: 2 }}
                                        onClick={() => {
                                            navigate(-1);
                                        }}
                                    >
                                        Назад
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                </Box>
            </div>
        </div>
    );
}

export default PerformEdit;