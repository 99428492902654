import type { AnchorHTMLAttributes } from 'react';
import type { Theme } from 'react-functional-select';
import { number } from 'yup';


export type SelectOption = Readonly<{
    value: number;
    label: string;
}>;

export const padLeft = (number: string, length: number, character: string = '0'): string => {
    let result = String(number);
    for (let i = result.length; i < length; ++i) {
        result = character + result;
    }
    return result;
};

export const chunkSize:number = 24999; //25000;

export const textLabels = {
    body: {
        noMatch: "Записей не найдено",
        toolTip: "Сортировать",
        columnHeaderTooltip: (column: any) => `Сортировать по ${column.label}`
    },
    pagination: {
        next: "Дальше",
        previous: "Назад",
        rowsPerPage: "Строк на странице:",
        displayRows: "из",
    },
    toolbar: {
        search: "Поиск",
        downloadCsv: "Загрузить XLSX",
        print: "Печать",
        viewColumns: "Просмотр колонок",
        filterTable: "Фильтр",
    },
    filter: {
        all: "Все",
        title: "Фильтры",
        reset: "Сбросить",
    },
    viewColumns: {
        title: "Показать колонки",
        titleAria: "Показать/Скрыть колонки",
    },
    selectedRows: {
        text: "строк отмечено",
        delete: "Удалить",
        deleteAria: "Удалить отмеченные строки",
    }
};

export enum Status {
    AwaitingSend = -2,
    Processing = 0,
    Sent = 1,
    Partially = 2,
    Fail = 3
}

export enum Roles {
    All = -1,
    Admin = 0,
    ReadOnly = 1,
    Purchase = 2,
    Perform = 3,
    SendData = 4,
    Reports = 5
}

export enum GisLoginStatus {
    None = 0,
    InProcess = 1,
    Success = 2,
    Error = 3
};

export enum DepartmentLabel {
    Production = "Прод",
    Finance = "Финансы",
}

export enum DepartmentId {
    Production = 1,
    Finance = 2,
}

export const Departments: Map<number, string> = new Map([
    [1,"Прод"], //Production
    [2,"Финансы"] //Finance
]);

export const DepartmentsDefs: SelectOption[] = [
    { value: DepartmentId.Production, label: DepartmentLabel.Production },
    { value: DepartmentId.Finance, label: DepartmentLabel.Finance },
];

export const THEME_CONFIG: Theme = {
    color: {
        primary: '#09d3ac'
    },
    control: {
        boxShadowColor: 'rgba(9, 211, 172, 0.25)',
        focusedBorderColor: 'rgba(9, 211, 172, 0.75)'
    },
    menu: {
        option: {
            selectedColor: '#fff',
            selectedBgColor: '#09d3ac',
            focusedBgColor: 'rgba(9, 211, 172, 0.225)'
        }
    }
};

export const RolesDefs: SelectOption[] = [
    { value: Roles.Admin, label: 'Администратор' },
    { value: Roles.ReadOnly, label: 'Только чтение' },
    { value: Roles.Purchase, label: 'Раздел - Закупленные диапазоны' },
    { value: Roles.Perform, label: 'Раздел - Реализованные диапазоны' },
    { value: Roles.SendData, label: 'Отправка данных' },
    { value: Roles.Reports, label: 'Доступ к отчетам' }
];

export const NUGET_URL_CONFIG = {
    SwaggerDocs: 'http://localhost:52530/swagger'
};

export const LINK_ATTRIBUTES: AnchorHTMLAttributes<HTMLAnchorElement> = {
    role: 'button',
    target: '_blank',
    rel: 'noopener noreferrer'
};