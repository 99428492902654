import { Spinner } from '../../components';
import { useParams, useNavigate, generatePath } from 'react-router-dom';
import { useEffect, type FunctionComponent, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../store';
import { deletePerformAsync, getPerformsGroupAsync, getPerformsPageAsync, sendPerformAsync, type PerformGroupItem, type PerformItem, sendPerformsGroupAsync, setSearchString } from '../../store/performSlice';
import { Button, ButtonGroup, FormControlLabel, IconButton, Switch, TableCell, TableRow, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faUpload } from '@fortawesome/free-solid-svg-icons';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ListIcon from '@mui/icons-material/List';
import OutboxIcon from '@mui/icons-material/Outbox';
import { padLeft, Roles, Routes, textLabels } from '../../config';
import Swal from 'sweetalert2';
import MUIDataTable, { type MUIDataTableColumnDef } from 'mui-datatables';
import getStatusName from '../../hooks/getStatusName';
import type { DtoPage, DtoPageWithIdsOfGroup } from '../../enities/comonEntities';
import { userCheckRole } from '../../hooks';
import dayjs from 'dayjs';
import { FilterAlt } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import PerformSingleGroupTable from './PerformSingleGroupTable';
import { createTheme, ThemeProvider } from '@mui/material/styles';

enum FilterColumnNames {
    dateSend = "dateSend",
    defect = "defect",
    startCode = "startCode",
    endCode = "endCode",
    status = "status"
};
export class PerformGroupItemTDO{
    group!: string;
    seria!: string;
    items!: PerformItemTDO[];  
};

export class PerformItemTDO {
    id?: number;
    seria?: string;
    startCode?: number;
    endCode?: number;
    defect?: number;
    status?: number;
    information?: string;
    tvdCode?: string;
    group?: string;
    dateSend?: string;
    userSendName?: string;
    departmentId?: number;
};

const MUITableTheme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides:{
          root: {
              padding: "10px"
          }
        }
      }
    }
  })

const PerformGroupTable: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();


    const { page: pageDefault = '0' } = useParams();
    const pageInit = parseInt(pageDefault, 0);

    const isLoading = useAppSelector<boolean>((state) => state.perform.isLoading);
    const performs = useAppSelector<PerformGroupItem[]>((state) => state.perform.performGroups);
    const page = useAppSelector<number>((state) => state.perform.page);
    const rowCount = useAppSelector<number>((state) => state.perform.rowCount);
    const pageSize = useAppSelector<number>((state) => state.perform.pageSize);
    const searchString = useAppSelector<string>((state) => state.perform.searchString);

    

    const headerColumns = [
        {
            name: "group",
            label: "Группа",
            options: {
                customBodyRender: (value: any, tableMeta: { rowData: any; }, updateValue: any) => {
                    console.log("group value", value);
                    console.log("group tableMeta.rowData", tableMeta.rowData);
                    return(
                    <div>
                        {value}
                        {/* {value.seria + ": " +  value.group} */}
                    </div>
                    )
                }
            }
        },
        {
            name: "items",
            label: "    ",
            options: {
                customBodyRender: (value: any, tableMeta: { rowData: any; }, updateValue: any) => {
                    console.log("items value", value);
                    console.log("items tableMeta.rowData", tableMeta.rowData);
                    return(
                    <div>
                        {/* промежутки   */}
                        {/* {value.seria + ": " +  value.group} */}
                    </div>
                    )
                }
            }
        },
        
    ];

    const detailColumns: MUIDataTableColumnDef[] 
    = [
        {
            name: "id",
            label: "#",
            options:{
                filter: false
            }
        },
        {
            label: "Стартовый код",
            name: "startCode",
            options: {
                filter: true,
                filterType: 'multiselect',
                filterOptions:{
                    logic(prop: string, filterValue: any[], row?: any[]) {
                        console.log("filterOptions logic startCode prop", prop);
                        console.log("filterOptions logic startCode filterValue", filterValue);
                        console.log("filterOptions logic startCode row", row);
                        return false;
                    },
                },
                customBodyRender: (value: any, tableMeta: { rowData: any; }, updateValue: any) => padLeft(value, 9)
            }
        },
        {
            label: "Конечный код",
            name: "endCode",
            options: {
                filter: true,
                filterType: 'multiselect',
                filterOptions: {
                    logic(prop: string, filterValue: any[], row?: any[]) {
                        console.log("filterOptions logic endCode prop", prop);
                        console.log("filterOptions logic endCode filterValue", filterValue);
                        console.log("filterOptions logic endCode row", row);
                        return false;
                    },
                },
                customBodyRender: (value: any, tableMeta: { rowData: any; }, updateValue: any) => padLeft(value, 9)
            }
        },
        {
            label: "Забраковано",
            name: "defect",
            options: {
                filter: true,
                filterType: 'multiselect',
                filterOptions:{
                    logic(prop: string, filterValue: any[], row?: any[]) {
                        console.log("filterOptions logic defect prop", prop);
                        console.log("filterOptions logic defect filterValue", filterValue);
                        console.log("filterOptions logic defect row", row);
                        return false;
                    },
                }
            }
        },
        {
            label: "Дата отправки",
            name: "dateSend",
            options: {
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: value => {
                        console.log("customFilterListOptions render value", value);
                        if(value != null && value.length > 0){
                            if(value.length == 2 && value[0].length > 0 && value[1].length > 0){
                                let rangeOfDateString: string[] =[dayjs(value[0]).format("DD.MM.YYYY") + " - " + dayjs(value[1]).format("DD.MM.YYYY")];
                                return rangeOfDateString;
                            }else if(value.length == 1 && value[0].length > 0){
                                let oneDateString: string[] = [dayjs(value[0]).format("DD.MM.YYYY")];
                                return oneDateString;
                            }
                        }
                        return [];
                    },
                    update: (filterList, filterPos, index) => {
                        console.log("customFilterListOptions update filterList", filterList);
                        console.log("customFilterListOptions update filterPos", filterPos);
                        console.log("customFilterListOptions update index", index);
                        return filterList;
                    },
                },
                filterOptions: {
                    // names: [],
                    logic(prop: string, filterValue: any[], row?: any[]) {
                        console.log("filterOptions logic dateSend prop", prop);
                        console.log("filterOptions logic dateSend filterValue", filterValue);
                        console.log("filterOptions logic dateSend row", row);
                        return false;
                    },
                    display: (filterList, onChange, index, column, filterData) => (
                    // {
                        // const [isFilterDateRange, setIsFilterDateRange] = useState<boolean>(false);
                        // let 
                        
                        // return(
                    
                    //     // <div>date filter</div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            {/* <FormControlLabel 
                                control={
                                    <Switch 
                                        // ref={}
                                        // checked={isFilterDateRange}
                                        onChange={() => {
                                            console.log("filterList", filterList);
                                            console.log("index", index);
                                            console.log("column", column);
                                            console.log("filterData", filterData);
                                            // setIsFilterDateRange(!isFilterDateRange)
                                        }}
                                    />
                                } 
                                label="Промежуток дат" 
                            /> */}
                            <div style={{display:"flex", gap: "5px",}}>
                                <DatePicker
                                    slotProps={{
                                        field: { 
                                            clearable: true, 
                                            // onClear: () => {
                                            //     filterList.splice(index,1,[]);
                                            //     onChange(filterList[index], index, column);
                                            // } 
                                        },
                                    }}
                                    // maxDate={filterEndDate.length > 0 ? dayjs(filterEndDate).subtract(1,'day') : null}
                                    maxDate={filterList[index].length == 2 ? dayjs(filterList[index][1]).subtract(1,'day') : null}
                                    // value={filterStartDate.length > 0 ? dayjs(filterStartDate): null}
                                    value={filterList[index][0] && filterList[index][0].length > 0 ? dayjs(filterList[index][0]): null}
                                    onChange={(value: any, context) => {
                                        let stringValue = value?.toDate().toISOString();
                                        console.log("DatePicker onChange stringValue", stringValue);
                                        console.log("DatePicker onChange stringValue != null", stringValue != null);
                                        filterList[index][0] = stringValue != null ? stringValue : "";
                                        onChange(filterList[index], index, column);
                                    }}
                                    format={"DD.MM.YYYY"}
                                />
                                {/* {isFilterDateRange && */}
                                    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>-</div>
                                {/* } */}
                                {/* {isFilterDateRange && */}
                                <DatePicker
                                    slotProps={{
                                        field: { 
                                            clearable: true, 
                                            // onClear: () => {
                                            //     let firstValue = filterList[index][0];
                                            //     filterList.splice(index,1,[firstValue]);
                                            //     onChange(filterList[index], index, column);
                                            // }
                                        },
                                    }}
                                    // minDate={filterStartDate.length > 0 ? dayjs(filterStartDate).add(1,'day') : null}
                                    minDate={filterList[index].length > 0 ? dayjs(filterList[index][0]).add(1,'day') : null}
                                    // value={filterEndDate.length > 0 ? dayjs(filterEndDate): null}
                                    value={filterList[index][1] && filterList[index][1].length > 0 ? dayjs(filterList[index][1]): null}
                                    onChange={(value: any, context) => {
                                        let stringValue = value?.toDate().toISOString();
                                        console.log("DatePicker onChange stringValue", stringValue);
                                        console.log("DatePicker onChange stringValue != null", stringValue != null);
                                        filterList[index][1] = stringValue != null ? stringValue : "";
                                        onChange(filterList[index], index, column);
                                        // setFilterEndDate(stringValue != null ? stringValue : "")
                                    }}
                                    format={"DD.MM.YYYY"}
                                />
                                {/* } */}
                            </div>
                           </LocalizationProvider>
                    ),

                    // )},
                  },
                customBodyRender: (value: any, tableMeta: { rowData: any; }, updateValue: any) => dayjs(value).format("DD.MM.YYYY")
            }
        },
        {
            label: "Статус отправки",
            name: "status",
            options: {
                filter: true,
                filterType: 'multiselect',
                sort: false,
                customBodyRender: (value: any) => (
                    <>{getStatusName(value)}</>
                )
            }
        },
        {
            label: "Информация",
            name: "information",
            options: {
                filter: false, sort: false
            }
        },
        {
            name: "Операции",
            options: {
                filter: false, sort: false,
                customBodyRender: (value: any, tableMeta: { rowData: any; }, updateValue: any) => {
                    return <>
                        <ButtonGroup variant="text">
                            {(!userCheckRole([Roles.ReadOnly])) ?
                                <Tooltip title="Редактировать">
                                    <IconButton color="primary" onClick={() => {
                                        const path = Routes.find((x) => x.name === "PerformEdit")?.path ?? "";
                                        const id = tableMeta.rowData[0];
                                        navigate(generatePath(path, { id: id }), { state: { id: id } });
                                    }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip> : <></>}
                            {(!userCheckRole([Roles.ReadOnly])) ?
                                <Tooltip title="Удалить">
                                    <IconButton color="primary" onClick={() => {
                                        Swal.fire({
                                            title: "Удалить диапазон?",
                                            showClass: { popup: 'animate__animated animate__fadeInDown' },
                                            hideClass: { popup: 'animate__animated animate__fadeOutUp' },
                                            showCancelButton: true,
                                            confirmButtonColor: '#d33',
                                            cancelButtonColor: '#3085d6',
                                            confirmButtonText: 'Удалить',
                                            cancelButtonText: 'Отменить'
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                const id = tableMeta.rowData[0];
                                                dispatch(deletePerformAsync(id)).then(() => {
                                                    dispatch(getPerformsGroupAsync(
                                                        {
                                                            page: page, sortOrder: '', direction: '',
                                                            searchText: ''
                                                        } as DtoPage));
                                                });
                                            }
                                        })
                                    }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip> : <></>}
                            <Tooltip title="Информация об изменении версий элемента списка">
                                <IconButton color="primary" onClick={() => {
                                    const path = Routes.find((x) => x.name === "Audit")?.path ?? "";
                                    const id = tableMeta.rowData[0];
                                    navigate(generatePath(path, { id: id, type: 'perform' }));
                                }}>
                                    <ListIcon />
                                </IconButton>
                            </Tooltip>
                            {(userCheckRole([Roles.Admin, Roles.SendData])) ?
                                <Tooltip title="Отправить в ГИС ЭЗ">
                                    <IconButton color="primary" onClick={() => {
                                        Swal.fire({
                                            title: "Отправить диапазон в ГИС ЭЗ?",
                                            showClass: { popup: 'animate__animated animate__fadeInDown' },
                                            hideClass: { popup: 'animate__animated animate__fadeOutUp' },
                                            showCancelButton: true,
                                            confirmButtonText: 'Отправить',
                                            cancelButtonText: 'Отменить'
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                const id = tableMeta.rowData[0];
                                                dispatch(sendPerformAsync(id)).then(() => {
                                                    dispatch(getPerformsGroupAsync(
                                                        {
                                                            page: page, sortOrder: '', direction: '',
                                                            searchText: ''
                                                        } as DtoPage));
                                                });
                                            }
                                        })
                                    }}>
                                        <OutboxIcon />
                                    </IconButton>
                                </Tooltip> : <></>}
                        </ButtonGroup>
                    </>;
                },
            },
        }];

    const [performGroupData, setPerformGroupData] = useState<PerformGroupItemTDO[]>([]);
    // const [performGroupColumns, setPerformGroupColumns] = useState<Map<number,MUIDataTableColumnDef[]>>(new Map());

    // const [isFilterDateRange, setIsFilterDateRange] = useState<Map<number,boolean>>(new Map());
    const [filterStartDate, setFilterStartDate] = useState<Map<number,string>>(new Map());
    const [filterEndDate, setFilterEndDate] = useState<Map<number,string>>(new Map());
    
    // const [filterDateGridList, setFilterDateGridList] = useState<Map<number,string[]>>(new Map());
    
    // const [filtersValues, setFiltersValues] = useState<Map<IFilterColumn, string[]>>(new Map());
    const [filtersValues, setFiltersValues] = useState<Map<string, string[]>>(new Map());
    const [isFiltersActual, setIsFiltersActual] = useState<Map<number,boolean>>(new Map());

    //PerformItem
    

    const HeaderElements = () => (
        <>
            {(!userCheckRole([Roles.ReadOnly])) ?
                <>
                    <Tooltip title="Добавить">
                        <Button
                            variant="outlined"
                            startIcon={<FontAwesomeIcon icon={faAdd} />}
                            className="float-start"
                            onClick={() => {
                                navigate(Routes.find((x) => x.name === "PerformAdd")?.path ?? "");
                            }}>
                            Добавить
                        </Button>
                    </Tooltip>
                </> : <></>}
        </>
    );

    const HeaderGroupElements = (props: {performGroup: PerformGroupItem}) => (
        <>
            {(!userCheckRole([Roles.ReadOnly])) ?
                <>
                    <Tooltip title="Отправить группу">
                        <Button
                            variant="outlined"
                            startIcon={<FontAwesomeIcon icon={faUpload} />}
                            className="float-start"
                            onClick={() => {
                                Swal.fire({
                                    title: "Отправка группы диапазонов в ГИС «Электронный знак»", //
                                    showClass: { popup: 'animate__animated animate__fadeInDown' },
                                    hideClass: { popup: 'animate__animated animate__fadeOutUp' },
                                    showCancelButton: true,
                                    confirmButtonText: 'Отправить группу',
                                    cancelButtonText: 'Отменить'
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        console.log("props", props)
                                        console.log("result.isConfirmed", result)
                                        const ids = props.performGroup.items.map(x => x.id);
                                        const dtoWithIds = {
                                            page: page, sortOrder: '', direction: '',
                                            searchText: '', ids: ids
                                        } as DtoPageWithIdsOfGroup
                                        dispatch(sendPerformsGroupAsync(dtoWithIds)).then(() => {
                                            dispatch(getPerformsGroupAsync(dtoWithIds));
                                        });
                                    }
                                })
                            }}>
                            Отправить группу
                        </Button>
                    </Tooltip>
                </> : <></>}
        </>
    );

    useEffect(() => {
        dispatch(getPerformsGroupAsync(
            {
                page: pageInit,
                sortOrder: '',
                direction: '',
                searchText: searchString
            } as DtoPage));
    }, [dispatch, pageInit]);

    function saveNewFilters(currentFilterList: string[][], groupIndex: number, dateRangeShoudBeEmpty: boolean = false) {
        console.log("applyFiltersToData currentFilterList", currentFilterList);
        
        let startCodeFilterArray: string[] = currentFilterList[1];
        let endCodeFilterArray: string[] = currentFilterList[2];
        let defectFilterArray: string[] = currentFilterList[3];
        let dateSendFilterArray: string[] = currentFilterList[4];
        let statusFilterArray: string[] = currentFilterList[5];
        // console.log("applyFiltersToData filterStartDate", filterStartDate)
        // console.log("applyFiltersToData filterEndDate", filterEndDate)
        console.log("applyFiltersToData defectFilterArray", defectFilterArray);
        console.log("applyFiltersToData statusFilterArray", statusFilterArray);
        console.log("applyFiltersToData startCodeFilterArray", startCodeFilterArray);
        console.log("applyFiltersToData endCodeFilterArray", endCodeFilterArray);
        // const newFilterDateGridValue: string[] = [];
        // let requiredFilterStartDate = filterStartDate.has(groupIndex) ? filterStartDate.get(groupIndex) : "";
        // if(requiredFilterStartDate && requiredFilterStartDate.length > 0 && !dateRangeShoudBeEmpty){
        //     newFilterDateGridValue.push(requiredFilterStartDate);
        //     let requiredFilterEndDate = filterEndDate.has(groupIndex) ? filterEndDate.get(groupIndex) : "";
        //     if(requiredFilterEndDate && requiredFilterEndDate.length > 0)
        //         newFilterDateGridValue.push(requiredFilterEndDate);
        // }
        let newFiltersDataMap: Map<string, string[]> = new Map();
        
        if(startCodeFilterArray.length > 0)
            // newFiltersDataMap.set({columnName: FilterColumnNames.startCode, groudIndex: groupIndex},startCodeFilterArray)
            newFiltersDataMap.set(groupIndex+FilterColumnNames.startCode,startCodeFilterArray)
        if(endCodeFilterArray.length > 0)
            // newFiltersDataMap.set({columnName: FilterColumnNames.endCode, groudIndex: groupIndex},endCodeFilterArray)
            newFiltersDataMap.set(groupIndex+FilterColumnNames.endCode,endCodeFilterArray)
        if(defectFilterArray.length > 0)
            // newFiltersDataMap.set({columnName: FilterColumnNames.defect, groudIndex: groupIndex},defectFilterArray)
            newFiltersDataMap.set(groupIndex+FilterColumnNames.defect,defectFilterArray)
        if(dateSendFilterArray.length > 0)
            // newFiltersDataMap.set({columnName: FilterColumnNames.startCode, groudIndex: groupIndex},startCodeFilterArray)
            newFiltersDataMap.set(groupIndex+FilterColumnNames.dateSend,dateSendFilterArray)
        if(statusFilterArray.length > 0)
            // newFiltersDataMap.set({columnName: FilterColumnNames.status, groudIndex: groupIndex},statusFilterArray)
            newFiltersDataMap.set(groupIndex+FilterColumnNames.status,statusFilterArray)

        // let joinedFiltersValues: Map<string, string[]> = new Map();
        filtersValues.forEach((value, key) => {
            if(!newFiltersDataMap.has(key)){
                let filtersValues = newFiltersDataMap.get(key);
                if(filtersValues && filtersValues.length > 0)
                    newFiltersDataMap.set(key, filtersValues)
            }
            // else{
            //     joinedFiltersValues.set(key, value)
            // }
        })
        setFiltersValues(newFiltersDataMap);
        let newIsFiltersActual: Map<number,boolean> = new Map();
        newIsFiltersActual.set(groupIndex, false)
        isFiltersActual.forEach((value, key) => {
            if(key != groupIndex){
                newIsFiltersActual.set(key, value)
            }
        })
        setIsFiltersActual(newIsFiltersActual);
    }

    useEffect(() => {
        isFiltersActual.forEach((value, index) =>{
            if(value === false){
                console.log("isFiltersActual check index",index," is false");
                filterGridData(index);
            }
        })
        // if(isFiltersActual === false)
        //     filterGridData()
    }, [
        isFiltersActual,
        filtersValues, 
        // purchasesData
    ]);

    function filterGridData(index:number) {
        
        let filterValuesNames: string[] = [];
        for(const filterName of Object.values(FilterColumnNames)){
            filterValuesNames.push(index+filterName)
        }
        if(filtersValues.size > 0 && filterValuesNames.some(fn => filtersValues.has(fn))){
            //создаем копию группы
            let performGroup: PerformGroupItemTDO = new PerformGroupItemTDO();
            performGroupData.forEach((performGroupValue, performGroupIndex) => {
                if(performGroupIndex === index){
                    performGroup.seria = performGroupValue.seria;
                    performGroup.group = performGroupValue.group;
                    performGroup.items = performGroupValue.items?.map(x => {return x});
                }
            })
            console.log("filterGridData performGroup", performGroup)
            //фильтруем
            if(filtersValues.has(index+FilterColumnNames.dateSend)){
                const dateSendFilterValues = filtersValues.get(index+FilterColumnNames.dateSend);
                //const dateSendFilterValues = filtersValues.get(FilterColumnNames.dateSend)
                if(dateSendFilterValues != null && dateSendFilterValues.length > 0){
                    if(dateSendFilterValues.length === 1){
                        console.log("dateSendFilterValues == 1", dateSendFilterValues)
                        const singleDate = dayjs(dateSendFilterValues[0]);
                        performGroup.items = performGroup.items?.filter(d => singleDate.isSame(dayjs(d.dateSend),'day'))
                    }else if(dateSendFilterValues.length === 2){
                        console.log("dateSendFilterValues == 2", dateSendFilterValues)
                        
                        let isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
                        let isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
                        dayjs.extend(isSameOrAfter);
                        dayjs.extend(isSameOrBefore);
                        const startDate = dayjs(dateSendFilterValues[0]);
                        const endDate = dayjs(dateSendFilterValues[1]);
                        // console.log("startDate.isSameOrAfter(dayjs('2024-02-02'),'day')", startDate.isSameOrAfter(dayjs('2024-02-02'),'day'))
                        // console.log("startDate.isSameOrBefore(dayjs('2024-02-02'),'day')", startDate.isSameOrBefore(dayjs('2024-02-02'),'day'))

                        // console.log("endDate.isSameOrBefore(dayjs('2024-02-02'),'day')", endDate.isSameOrBefore(dayjs('2024-02-02'),'day'))
                        // console.log("endDate.isSameOrAfter(dayjs('2024-02-02'),'day')", endDate.isSameOrAfter(dayjs('2024-02-02'),'day'))

                        performGroup.items = performGroup.items?.filter(d => startDate.isSameOrBefore(dayjs(d.dateSend),'day') && endDate.isSameOrAfter(dayjs(d.dateSend),'day'));
                    }
                }
                // gridData = gridData.filter(d => seriaFilterValues?.some(sf => sf === d.seria))
            }
            if(filtersValues.has(index+FilterColumnNames.startCode)){
                const startCodeFilterValues = filtersValues.get(index+FilterColumnNames.startCode);
                if(startCodeFilterValues != null && startCodeFilterValues.length > 0){
                    performGroup.items = performGroup.items?.filter(d => startCodeFilterValues.some(sc => sc.toString() === padLeft(d.startCode != null ? d.startCode.toString() : "", 9)))
                    // let newItems = performGroup.items?.filter(d => startCodeFilterValues.some(sc => sc.toString() === padLeft(d.startCode != null ? d.startCode.toString() : "", 9)))
                    // console.log("startCode newitems", newItems);
                }
            }
            if(filtersValues.has(index+FilterColumnNames.endCode)){
                const endCodeFilterValues = filtersValues.get(index+FilterColumnNames.endCode);
                if(endCodeFilterValues != null && endCodeFilterValues.length > 0){
                    performGroup.items = performGroup.items?.filter(d => endCodeFilterValues.some(sc => sc.toString() === padLeft(d.endCode != null ? d.endCode.toString(): "",9)))
                    // let newItems = performGroup.items?.filter(d => endCodeFilterValues.some(sc => sc.toString() === padLeft(d.endCode != null ? d.endCode.toString(): "",9)))
                    // console.log("endCode newitems", newItems);
                }
                    
            }
            if(filtersValues.has(index+FilterColumnNames.defect)){
                const defectFilterValues = filtersValues.get(index+FilterColumnNames.defect);
                if(defectFilterValues != null && defectFilterValues.length > 0){
                    performGroup.items = performGroup.items?.filter(d => defectFilterValues.some(sc => sc.toString() === d.defect?.toString()))
                    // let newItems = 
                    // console.log("defecgt newitems", newItems);
                }
                    
            }
            if(filtersValues.has(index+FilterColumnNames.status)){
                const statusFilterValues = filtersValues.get(index+FilterColumnNames.status);
                if(statusFilterValues != null && statusFilterValues.length > 0){
                    performGroup.items = performGroup.items?.filter(d => statusFilterValues.some(sc => sc.toString() === d.status?.toString()))
                    let newItems = performGroup.items?.filter(d => statusFilterValues.some(sc => sc.toString() === d.status?.toString()))
                    console.log("status newitems", newItems);
                }
                    
            }
            //
            //Создаем новый массив данных
            let newPerformGoupData:PerformGroupItemTDO[] = []
            performGroupData.forEach((performGroupDataValue, performGroupDataIndex) => {
                if(performGroupDataIndex !=  index)
                    newPerformGoupData.push(performGroupDataValue)
                else if(performGroupDataIndex == index)
                    newPerformGoupData.push(performGroup)
            })
            setPerformGroupData(newPerformGoupData);
            // setPerformGroupData(prevState => {
            //  const newState = prevState.map(pgd =>{
            //     if(pgd.seria == performGroup.seria && pgd.group == performGroup.group){
            //         return{...pgd,items: performGroup.items}
            //     }
            //     return pgd;
            //  });
            //  return newState;   
            // });
        }
        // else{
        //     newPerformGoupData(purchases);
        // }
        let newIsFiltersActual: Map<number,boolean> = new Map()
        isFiltersActual.forEach((isFiltersActualValue, isFiltersActualIndex) =>{
            if(isFiltersActualIndex === index){
                newIsFiltersActual.set(index,true)
                console.log("isFiltersActual check index",index," is false");
                // filterGridData(index);
            }else{
                newIsFiltersActual.set(isFiltersActualIndex,isFiltersActualValue)
            }
        })
        setIsFiltersActual(newIsFiltersActual)
    }

    useEffect(() => {
        console.log("useEffect performs", performs)
        let newPerformGroupData: PerformGroupItemTDO[] = [];
        performs.forEach(value =>{
            let performGroup: PerformGroupItemTDO = new PerformGroupItemTDO();
            performGroup.seria = value.seria;
            performGroup.group = value.group;
            performGroup.items = value.items.map(x => {
                let itemAsAny = x as any;
                let performItem: PerformItemTDO = new PerformItemTDO();
                performItem.id = itemAsAny.id
                performItem.seria = itemAsAny.seria; 
                performItem.startCode = itemAsAny.startCode;
                performItem.endCode = itemAsAny.endCode;
                performItem.defect = itemAsAny.defect;
                performItem.status = itemAsAny.status;
                performItem.information = itemAsAny.information;
                performItem.tvdCode = itemAsAny.tvdCode;
                performItem.group = itemAsAny.group;
                performItem.dateSend = itemAsAny.dateSend;
                performItem.userSendName = itemAsAny.userSendName;
                performItem.departmentId = itemAsAny.departmentId;
                return performItem;
            });
            newPerformGroupData.push(performGroup);
        })
        console.log("useEffect newPerformGroupData", newPerformGroupData)
        setPerformGroupData(newPerformGroupData);
        // let newPerformGroupColumns: Map<number, MUIDataTableColumnDef[]> = new Map();
        // performs.forEach((value, index) => {
        //     newPerformGroupColumns.set(index,detailColumns);
        // })
        // setPerformGroupColumns(newPerformGroupColumns);
        let newIsFiltersActual: Map<number, boolean> = new Map();
        isFiltersActual.forEach((value, key) => {
            newIsFiltersActual.set(key, false);
        })
        setIsFiltersActual(newIsFiltersActual);
    }, [performs]);
    return (
        <>
            <Spinner isLoading={isLoading} />
            <ThemeProvider theme={MUITableTheme}>
                <MUIDataTable
                    title=""
                    // data={performs}
                    data={performGroupData}
                    columns={headerColumns}
                    options={{
                        textLabels: textLabels,
                        count: rowCount,
                        serverSide: true,
                        rowsPerPageOptions: [],
                        rowsPerPage: pageSize,
                        page: page,
                        selectableRows: 'single',
                        selectableRowsOnClick: false,
                        selectableRowsHeader: false,
                        selectableRowsHideCheckboxes: true,
                        expandableRows: true, filter: false,
                        search: true, 
                        download: false, 
                        print: false,
                        searchText:searchString,
                        onTableChange: (action, tableState) => {
                            console.log(action, tableState);
                            switch (action) {
                                case 'changePage':
                                    dispatch(getPerformsGroupAsync({
                                        page: tableState.page,
                                        sortOrder: tableState.sortOrder.name ?? '',
                                        direction: tableState.sortOrder.direction ?? '',
                                        searchText: tableState.searchText ?? ''
                                    } as DtoPage));
                                    break;
                                case 'sort':
                                    dispatch(getPerformsGroupAsync({
                                        page: tableState.page,
                                        sortOrder: tableState.sortOrder.name ?? '',
                                        direction: tableState.sortOrder.direction ?? '',
                                        searchText: tableState.searchText ?? ''
                                    } as DtoPage));
                                    break;
                                case 'search':
                                    dispatch(setSearchString(tableState.searchText ?? ''))
                                    dispatch(getPerformsGroupAsync({
                                        page: tableState.page,
                                        sortOrder: tableState.sortOrder.name ?? '',
                                        direction: tableState.sortOrder.direction ?? '',
                                        searchText: tableState.searchText ?? ''
                                    } as DtoPage));
                                    break;
                                default:
                                    console.log('action not handled.');
                            }
                        },
                        renderExpandableRow: (rowData: any, rowMeta: any) => {
                            let dataToShow = performGroupData[rowMeta.rowIndex].items;
                            console.log("dataToShow", dataToShow)
                            return (
                                <TableRow>
                                    <TableCell colSpan={rowData.length + 1}>
                                        <PerformSingleGroupTable data={performGroupData[rowMeta.rowIndex]} />
                                        {/* <MUIDataTable
                                            title=""
                                            // data={performs[rowMeta.rowIndex].items}
                                            data={performGroupData[rowMeta.rowIndex].items ? performGroupData[rowMeta.rowIndex].items : []}
                                            // data={dataToShow}
                                            columns={detailColumns}
                                            options={{
                                                textLabels: textLabels,
                                                filter: true,
                                                // filterType: 'custom', 
                                                customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
                                                    return (
                                                        <div style={{ marginTop: '40px' }}>
                                                            <Button
                                                                variant="outlined"
                                                                startIcon={<FilterAlt />}
                                                                className="float-start"
                                                                onClick={() =>
                                                                    saveNewFilters(currentFilterList,rowMeta.rowIndex)
                                                                }>
                                                                Применить фильтры
                                                            </Button>
                                                        </div>
                                                    );
                                                },
                                                onFilterChange: (column, filterList, type) => {
                                                    console.log('onFilterChange event occured', column, filterList, type);
                                                    if (type === 'chip') {
                                                        let dateRangeShoudBeEmpty: boolean = false
                                                        if(column === "dateSend"){
                                                            dateRangeShoudBeEmpty = true;
                                                            let newFilterStartDate: Map<number, string> = new Map();
                                                            newFilterStartDate.set(rowMeta.rowIndex,"");
                                                            filterStartDate.forEach((value, key) => {
                                                                if(key != rowMeta.rowIndex)
                                                                    newFilterStartDate.set(key,value);
                                                            });
                                                            setFilterStartDate(newFilterStartDate);
                                                            let newFilterEndDate: Map<number, string> = new Map();
                                                            newFilterEndDate.set(rowMeta.rowIndex,"");
                                                            filterEndDate.forEach((value, key) => {
                                                                if(key != rowMeta.rowIndex)
                                                                    newFilterEndDate.set(key,value);
                                                            });
                                                            setFilterEndDate(newFilterEndDate);
                                                        }
                                                    saveNewFilters(filterList, rowMeta.rowIndex, dateRangeShoudBeEmpty)
                                                    }
                                                    if(type === 'reset'){
                                                        let newFilterStartDate: Map<number, string> = new Map();
                                                        newFilterStartDate.set(rowMeta.rowIndex,"");
                                                        filterStartDate.forEach((value, key) => {
                                                            if(key != rowMeta.rowIndex)
                                                                newFilterStartDate.set(key,value);
                                                        });
                                                        setFilterStartDate(newFilterStartDate);
                                                        let newFilterEndDate: Map<number, string> = new Map();
                                                        newFilterEndDate.set(rowMeta.rowIndex,"");
                                                        filterEndDate.forEach((value, key) => {
                                                            if(key != rowMeta.rowIndex)
                                                                newFilterEndDate.set(key,value);
                                                        });
                                                        setFilterEndDate(newFilterEndDate);
                                                        saveNewFilters(filterList, rowMeta.rowIndex, true);
                                                    }
                                                },
                                                search: false,
                                                download: false, 
                                                viewColumns: false,
                                                print: false,
                                                selectableRows: 'none',
                                                pagination: false,
                                                customToolbar: () => (<HeaderGroupElements performGroup={performs[rowMeta.rowIndex]}/>),
                                            }}
                                        /> */}
                                    </TableCell>
                                </TableRow>
                            );
                        },
                        customToolbar: () => (<HeaderElements />),
                    }}
                />
            </ThemeProvider>
        </>
    );
};

export default PerformGroupTable;