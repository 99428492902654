import { BaseService } from './base.service';
import type { UpdateUserPassword, UpdateUserPasswordResponse, UserItem } from '../store/userSlice';

class UserService extends BaseService {
    private static _userService: UserService;
    private static _controller: string = 'users';

    private constructor(name: string) {
        super(name);
    }

    public static get Instance(): UserService {
        return this._userService || (this._userService = new this(this._controller));
    }


    public async addUserAsync(user: UserItem): Promise<UserItem> {
        const { data } = await this.$http.post<UserItem>('/', user);
        return data;
    }

    public async editUserAsync(user: UserItem): Promise<UserItem> {
        const { data } = await this.$http.put<UserItem>(`/${user.id}`, user);
        return data;
    }

    public async deleteUserAsync(id: number): Promise<UserItem> {
        const { data } = await this.$http.delete<UserItem>(`/${id}`);
        return data;
    }

    public async getUserAsync(id: number): Promise<UserItem> {
        const { data } = await this.$http.get<UserItem>(`/${id}`);
        return data;
    }

    public async getUsersAsync(): Promise<UserItem[]> {
        const { data } = await this.$http.get<UserItem[]>('/');
        return data;
    }

    // public async updatePasswordAsync(): Promise<UpdateUserPassword> {
    //     const { data } = await this.$http.post<UpdateUserPassword>('/');
    //     return data;
    // }

    public async updatePasswordAsync(credentials: UpdateUserPassword): Promise<UpdateUserPasswordResponse> {
        const response = await this.$http.post<any>('updatepassword', credentials)
        // .then((response) => {
        //     console.log("response",response)
        //     return {status: response.status, statusText: response.statusText}
        // })
        // .catch(error => {
        //     return {status: error.status, statusText: error.statusText}
        // })
        return {status: response.status, statusText: response.statusText}
        // return {status: 400, statusText: "Unable to send request because of the client"};
      }
}

export const UserApi = UserService.Instance;
