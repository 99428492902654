import { AuditApi } from 'src/api';
import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { AuditItem, AuditRequest } from '../enities/auditEntities';

export type AuditState = Readonly<{
    isLoading: boolean;
    items: AuditItem[];
}>;

export type ReceiveAuditPayload = Pick<AuditState, "items">;

const initialState: AuditState = {
    items: [],
    isLoading: false
};

export const auditSlice = createSlice({
    name: 'purchases',
    initialState,
    reducers: {
        requestItems: (state) => {
            state.isLoading = true;
        },
        receiveItems: (state, action: PayloadAction<ReceiveAuditPayload>) => {
            const { items } = action.payload;
            state.isLoading = false;
            state.items = items;
        }   
    }
});

export const getAuditAsync = createAsyncThunk(
    'purchases/getAuditAsync',
    async (request: AuditRequest, { dispatch, getState }) => {
        dispatch(requestItems());
        try {
            const response = await AuditApi.getAuditAsync(request);
            const payload = { items: response };
            dispatch(receiveItems(payload));
        } catch (e) {
            console.error(e);
        }
    }
);

export const { requestItems, receiveItems } = auditSlice.actions;

export default auditSlice.reducer;