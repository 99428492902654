import { useIsLoggedIn, userCheckRole } from '../hooks';
import { Routes as routes } from '../config';
import type { FunctionComponent } from 'react';
import { NavLink, generatePath } from 'react-router-dom';

const Navbar: FunctionComponent = () => {
  const isLoggedIn = useIsLoggedIn();
  return (
    <nav
      role="navigation"
      className="navbar"
      aria-label="main navigation"
    >
      <div className="navbar-wrapper">
        <div className="navbar-routes">
          {isLoggedIn &&
            routes
              .filter(({ showInNav }) => showInNav)
                      .map(({ path, title, roles, name, params }) =>
                      (
                          (userCheckRole(roles)) ?
                <NavLink
                  key={name}
                  to={generatePath(path, params)}
                  className={({ isActive }) => 'navbar-item' + (isActive ? ' is-active' : '')}
                  >
                  {title??name}
                </NavLink> : <></>
              ))}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
