
import { useEffect, type FunctionComponent, useState } from 'react';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Box, InputLabel, MenuItem } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { addPerformAsync, getSeriaCodesAsync, getTvdCodesAsync, type PerformItem, type SeriaItem, type TvdItem } from '../../store/performSlice';
import { useAppSelector, useAppDispatch } from '../../store';
import { chunkSize, Routes } from '../../config';
import Swal from 'sweetalert2';
import { validateDiapasonAsync, type DiapasonRange } from '../../store/performSlice';


const PerformAdd: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector<boolean>((state) => state.perform.isLoading);
    const tvdCodesRef = useAppSelector<TvdItem[]>((state) => state.perform.tvdCodes);
    const seriasRef = useAppSelector<SeriaItem[]>((state) => state.perform.serias);
    const navigate = useNavigate();

    const [seriaIntervalId, setSeriaIntervalId] = useState<number>(-1)

    useEffect(() => {
        dispatch(getTvdCodesAsync(0));
    }, [dispatch]);
    useEffect(() => {

        dispatch(getSeriaCodesAsync(0));
    }, [dispatch]);

    const validateCode = async (value: any, ctx: any) => {
        const v = ctx.parent;
        console.log("ctx", ctx);
        // console.log("window.location.pathname", window.location.pathname);
        // let pathnameArr = window.location.pathname.split('/');
        // console.log("pathnameArr", pathnameArr);
        if (v.startCode == 0 || v.endCode == 0) {
            return true;
        }
        if (parseInt(v.startCode) > parseInt(v.endCode)) {
            return true;
        }
        const range = { seria: v?.seria ?? '', startCode: v.startCode, endCode: v.endCode, currentDiapasonId: 0 } as DiapasonRange;
        console.log("validate range", range);
        const result = await (await dispatch(validateDiapasonAsync(range))).payload;
        console.log("result", result)
        return result === true;
    };
    const compareCode = async (value: any, ctx: any) => {
        const v = ctx.parent;
        if (v.startCode == 0 || v.endCode == 0) {
            return true;
        }
        return (parseInt(v.startCode) <= parseInt(v.endCode));
    };
    const requiredCode = async (value: any) => {
        return (parseInt(value) > 0);
    };

    const startCodeInRange = async (value: any, ctx: any) => {
        console.log("value", value);
        console.log("ctx", ctx);
        console.log("seriasRef", seriasRef);
        if(ctx.parent.seria != null && ctx.parent.seria.length > 0){
            const selectedSeria = seriasRef.find(x => x.name == ctx.parent.seria);
            console.log("selectedSeria", selectedSeria);
            let isStartCodeInSeriaInterval = selectedSeria?.intervals.some(x => value >= x.start && value <= x.end && //Валидация вводимого начального кода
                (ctx.parent.endCode == 0 || (ctx.parent.endCode >= x.start && ctx.parent.endCode <= x.end))) //Валидация, что конечный код либо ещё не введён, либо попадает в тот же ЗД
            let selectedSeriaInterval = selectedSeria?.intervals.find(x => value >= x.start && value <= x.end)
            console.log("isStartCodeInSeriaInterval", isStartCodeInSeriaInterval);
            console.log("end seriaIntervalId", seriaIntervalId);
            console.log("start selectedSeriaInterval", selectedSeriaInterval);
            if(isStartCodeInSeriaInterval){
                if(selectedSeriaInterval && selectedSeriaInterval.purchaseId){
                    setSeriaIntervalId(Number(selectedSeriaInterval.purchaseId))
                    return true;
                }   
            }
                
        }
        
        return false;
    };
    const endCodeInRange = async (value: any, ctx: any) => {
        console.log("value", value);
        console.log("ctx", ctx);
        console.log("seriasRef", seriasRef);
        if(ctx.parent.seria != null && ctx.parent.seria.length > 0){
            const selectedSeria = seriasRef.find(x => x.name == ctx.parent.seria);
            console.log("selectedSeria", selectedSeria);
            let isEndCodeInSeriaInterval = selectedSeria?.intervals.some(x => value >= x.start && value <= x.end && //Валидация вводимого конечного кода
                (ctx.parent.startCode == 0 || (ctx.parent.startCode >= x.start && ctx.parent.startCode <= x.end)) //Валидация, что начальный код либо ещё не введён, либо попадает в тот же ЗД
                )
            let selectedSeriaInterval = selectedSeria?.intervals.find(x => value >= x.start && value <= x.end)
            console.log("isEndCodeInSeriaInterval", isEndCodeInSeriaInterval);
            console.log("end seriaIntervalId", seriaIntervalId);
            console.log("end selectedSeriaInterval", selectedSeriaInterval);
            if(isEndCodeInSeriaInterval){
                if(selectedSeriaInterval && selectedSeriaInterval.purchaseId){
                    setSeriaIntervalId(Number(selectedSeriaInterval.purchaseId))
                    return true;
                }
            }   
        }
        return false;
    };

    const differenceBetweenCodesMoreThan3Mil = async (value: any, ctx: any) => {
        // console.log("value", value);
        // console.log("ctx", ctx);
        let parent = ctx.parent;
        let res = false
        if(parent.endCode && parent.startCode){
            let startCode = parseInt(parent.startCode);
            let endCode = parseInt(parent.endCode);
            if(!isNaN(startCode) && !isNaN(endCode)){
                let difference = endCode - startCode;
                // console.log("difference", difference)
                if(difference <= 3000000) // 3 000 000
                    res = true;
            }
        }
        return res;
    };

    const validationSchema = Yup.object().shape({      
        /*seria: Yup.string().required('Обязательное поле')
            .matches(/^[а-яА-ЯaA-zZ\s]+$/, "Разрешены только буквенные символы латинского алфавита"),*/
        startCode: Yup.string().max(9, "Максимальное длинна не больше 9 знаков").required('Обязательное поле')
            .test('startCode', 'Обязательное поле', requiredCode)
            .test('startCode', 'Диапазон уже существует в реализованных диапазонах', validateCode)
            .test('startCode', 'Стартовый код должен быть меньше конечного кода', compareCode)
            .test('startCode', 'Код должен попадать в диапазон серии', startCodeInRange)
            .test('startCode', 'Введен слишком большой диапазон (больше 3 000 000)', differenceBetweenCodesMoreThan3Mil),
        endCode: Yup.string().max(9, "Максимальное длинна не больше 9 знаков").required('Обязательное поле')
            .test('endCode', 'Обязательное поле', requiredCode)
            .test('endCode', 'Диапазон уже существует в реализованных диапазонах', validateCode)
            .test('endCode', 'Конечный код должен быть больше стартового кода', compareCode)
            .test('endCode', 'Код должен попадать в диапазон серии', endCodeInRange)
            .test('endCode', 'Введен слишком большой диапазон (больше 3 000 000)', differenceBetweenCodesMoreThan3Mil),
        defect: Yup.number()
    });

    const formik = useFormik({
        initialValues: {
            seria: '',
            startCode: 0,
            endCode: 0,
            defect: 0,
            tvdCode: "",
            group:"",
            // dateSend:""
            userSendName:"",
            departmentId: 0,
            purchaseId: 0
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {            
            const performItem: PerformItem = {
                id: 0,
                seria: values.seria,
                startCode: values.startCode,
                endCode: values.endCode,
                defect: values.defect,
                tvdCode: values.tvdCode,
                group: values.group,
                information: '',
                userSendName: values.userSendName,
                departmentId: values.departmentId,
                purchaseId: seriaIntervalId
                // dateSend: ""
            };
            if ((values.endCode - values.startCode) > chunkSize) {
                Swal.fire({
                    title: "Разбить на максимальные возможные диапазоны для отправки в ГИС ЭЗ?",
                    showClass: { popup: 'animate__animated animate__fadeInDown' },
                    hideClass: { popup: 'animate__animated animate__fadeOutUp' },
                    showCancelButton: true,
                    confirmButtonText: 'Сохранить',
                    cancelButtonText: 'Отменить'
                }).then((result) => {
                    if (result.isConfirmed) {
                        dispatch(addPerformAsync(performItem));
                        const path = Routes.find((x) => x.name === "Performs")?.path ?? "";
                        navigate(path);
                    }
                });
            }
            else {
                dispatch(addPerformAsync(performItem)).then(() => {
                    navigate(Routes.find((x) => x.name === "Performs")?.path ?? "");
                });
            }
        }
    });

    return (
        <div className="section">
            <div className="container">
                <h3 className="title is-4">Ввод реализованных диапазонов УКЗ</h3>
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid container spacing={6}>
                                <Grid item sm={6}>
                                    <InputLabel shrink>Серия:</InputLabel>
                                    <TextField
                                        select
                                        required
                                        fullWidth
                                        name="seria"
                                        type="select"
                                        id="seria"
                                        value={formik.values.seria}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.seria && Boolean(formik.errors.seria)}
                                        helperText={formik.touched.seria && formik.errors.seria}
                                    >
                                        {seriasRef.map(x => (
                                            <MenuItem key={x.id} value={x.id}>
                                                {x.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item sm={6}>
                                    <InputLabel shrink>Код ТН ВЭД:</InputLabel>
                                    <TextField
                                        select
                                        required
                                        fullWidth
                                        name="tvdCode"                                      
                                        type="select"
                                        id="tvdCode"
                                        value={formik.values.tvdCode}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.tvdCode && Boolean(formik.errors.tvdCode)}
                                        helperText={formik.touched.tvdCode && formik.errors.tvdCode}
                                    >
                                        {tvdCodesRef.map(x => (
                                            <MenuItem key={x.id} value={x.id}>
                                                {x.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item sm={6}>
                                    <InputLabel shrink>Стартовый код:</InputLabel>
                                    <TextField                                 
                                        required fullWidth
                                        id="startCode" name="startCode" type="number"
                                        value={formik.values.startCode}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.startCode && Boolean(formik.errors.startCode)}
                                        helperText={formik.touched.startCode && formik.errors.startCode}
                                    />
                                </Grid>
                                <Grid item sm={6}>
                                    <InputLabel shrink>Конечный код:</InputLabel>
                                    <TextField
                                        required fullWidth
                                        id="endCode" name="endCode" type="number"
                                        value={formik.values.endCode}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.endCode && Boolean(formik.errors.endCode)}
                                        helperText={formik.touched.endCode && formik.errors.endCode}
                                    />
                                </Grid>
                                <Grid item sm={6}>
                                    <InputLabel shrink>Забраковано:</InputLabel>
                                    <TextField
                                        fullWidth
                                        id="defect" name="defect" type="number"
                                        value={formik.values.defect}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.defect && Boolean(formik.errors.defect)}
                                        helperText={formik.touched.defect && formik.errors.defect}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink>Всего:</InputLabel>
                                    <TextField
                                        fullWidth
                                        id="amount" name="amount" type="number"
                                        value={formik.values.endCode - formik.values.startCode + 1}
                                        InputProps={{
                                            readOnly: true,
                                          }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box margin={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                {/*    <pre>{JSON.stringify(formik.values, null, 2)}</pre>*/}
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Button
                                        disabled={isLoading}
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Сохранить
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        sx={{ mt: 3, mb: 2 }}
                                        onClick={() => {navigate(-1);}}
                                    >
                                        Отменить
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                </Box>
            </div>
        </div>
    );
}
export default PerformAdd;
