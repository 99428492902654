import { Roles } from '../config';
import type { AuthState } from '../store/authSlice';

const userCheckRole = (roles: number[]): boolean => {
    if (roles.includes(Roles.All)) {
        return true;
    }
    const user = JSON.parse(localStorage.getItem('auth_user') as string) as AuthState;
    if (user?.roles == null) {
        return false;
    }
    return (user?.roles.filter(value => roles.includes(value)).length >= 1); 
};

export default userCheckRole;
