import type { FunctionComponent } from 'react';
import AuditTable from './AuditTable';

const Audit: FunctionComponent = () => {

    return (
        <div className="section">
            <div className="container">
                <h3 className="title is-4">История изменений</h3>
                <AuditTable />
            </div>
        </div>
    );
};

export default Audit;