
import { useState, type FunctionComponent } from 'react';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Box, Checkbox, Container, FormControlLabel } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../store';
import { Routes } from '../../config';
import { Spinner } from '../../components';
import { toast, type Id } from 'react-toastify';
import { AuthStatusEnum, loginAsync, rememberMeAsync, type Credentials, type ChangePasswordCredentials } from '../../store/authSlice';
import { updatePasswordAsync, type UpdateUserPassword } from '../../store/userSlice';


const Signin: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector<boolean>((state) => state.auth.isLoading);
    const navigate = useNavigate();
    const [isPasswordChangeRequired, setIsPasswordChangeRequired] = useState<boolean>(false)

    const validationSchema = Yup.object().shape({
        login: Yup.string().required('Обязательное поле'),
        password: Yup.string().required('Обязательное поле')
    });

    const isNewPasswordsEqual = async (value: string) => {
        // console.log("formikChangePassword.values",formikChangePassword.values);
        let res:boolean = formikChangePassword.values.newPassword === formikChangePassword.values.newPassword2 ? true : false;
        // console.log("res",res);
        if(res)
            return true;
        else 
            return false
        
        // return res == true ? true : false;
    };

    const isOldAndNewPasswordsEqual = async (value: string) => {
        // console.log("formikChangePassword.values",formikChangePassword.values);
        let res:boolean = formikChangePassword.values.currentPassword !== formikChangePassword.values.newPassword ? true : false;
        // console.log("res",res);
        if(res)
            return true;
        else 
            return false
        
        // return res == true ? true : false;
    };

    //const passwordRegex = /(\w*[A-Z]\w*[a-z]\w*\d|\w*[A-Z]\w*\d\w*[a-z]|\w*\d\w*[A-Z]\w*[a-z]|\w*\d\w*[a-z]\w*[A-Z]|\w*[a-z]\w*[A-Z]\w*\d|\w*[a-z]\w*\d\w*[A-Z])/;
    const passwordRegex = /([A-Z]+[a-z]+\d+|[A-Z]+\d+[a-z]+|[a-z]+[A-Z]+\d+|[a-z]+\d+[A-Z]+|\d+[A-Z]+[a-z]+|\d+[a-z]+[A-Z]+)/
    const passwordChangeValidationSchema = Yup.object().shape({
        newPassword: Yup.string()
        .min(12, 'Пароль должен содержать минимум 12 символов').required('Обязательное поле')
        .matches(passwordRegex, 'Пароль должен состоять из строчных и заглавных букв а так же содержать цифру')
        .test('newPassword', 'Новый пароль не должен совпадать со старым', isOldAndNewPasswordsEqual)
        ,
        newPassword2: Yup.string()
        .required('Обязательное поле')
        .test('newPassword2', 'Пароли не совпадают', isNewPasswordsEqual)
        //.matches(passwordRegex, 'Пароль должен состоять из строчных и заглавных букв а так же содержать хотя бы одну цифру')
        ,
    });

    const formikLogin = useFormik({
        initialValues: { userName: '', password: '', remember: false },
        //validationSchema: validationSchema,
        onSubmit: (values) => {
            toast.dismiss();
            const credentials: Credentials = {
                rememberMe: values.remember,
                userName: values.userName,
                password: values.password,
            };
            dispatch(loginAsync(credentials)).then((value: any) => {
                if (value.payload?.status == AuthStatusEnum.SUCCESS) {
                    console.log("value", value)
                    if(value.payload?.isNeedToChangePassword){
                        setIsPasswordChangeRequired(true);
                        formikChangePassword.setFieldValue("userName",values.userName)
                    } else if(!value.payload?.isNeedToChangePassword){
                        dispatch(rememberMeAsync(credentials.rememberMe));
                        navigate(Routes.find((x) => x.name === 'Purchases')?.path ?? '/');
                    }
                    // dispatch(rememberMeAsync(credentials.rememberMe));
                    // navigate(Routes.find((x) => x.name === 'Purchases')?.path ?? '/');
                } else {
                    toast.error("Некорректный пользователь или пароль");
                }
            });
        }
    });
    const formikChangePassword = useFormik({
        initialValues: { userName: '', currentPassword: '', newPassword: '', newPassword2: ''},
        validationSchema: passwordChangeValidationSchema,
        onSubmit: (values) => {
            toast.dismiss();
            const passwordChangeCredentials: UpdateUserPassword = {
                login: values.userName,
                oldPassword: values.currentPassword,
                newPassword: values.newPassword
            };
            console.log("passwordChangeCredentials", passwordChangeCredentials);
            dispatch(updatePasswordAsync(passwordChangeCredentials)).then((value: any) => {
                console.log("formikChangePassword", value);
                if(value.payload.status == 200){
                    window.location.reload();
                    //navigate(Routes.find((x) => x.name === 'Purchases')?.path ?? '/');
                }else{
                    toast.error("Что-то пошло не так. Свяжитесь с Администратором");
                }
            })
            // dispatch(loginAsync(credentials)).then((value: any) => {
            //     if (value.payload?.status == AuthStatusEnum.SUCCESS) {
            //         setIsPasswordChangeRequired(true);
            //         // dispatch(rememberMeAsync(credentials.rememberMe));
            //         // navigate(Routes.find((x) => x.name === 'Purchases')?.path ?? '/');
            //     } else {
            //         toast.error("Некорректный пользователь или парооль");
            //     }
            // });
        }
    });
    

    return (
        <>
            <Spinner isLoading={isLoading} />
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    {isPasswordChangeRequired ?
                        <form onSubmit={formikChangePassword.handleSubmit}>
                            <TextField
                                margin="normal" required fullWidth type="password"
                                id="password" label="Текущий пароль" name="currentPassword"
                                value={formikChangePassword.values.currentPassword}
                                onChange={formikChangePassword.handleChange}
                                onBlur={formikChangePassword.handleBlur}
                                error={formikChangePassword.touched.currentPassword && Boolean(formikChangePassword.errors.currentPassword)}
                                helperText={formikChangePassword.touched.currentPassword && formikChangePassword.errors.currentPassword}
                            />
                            <TextField
                                margin="normal" required fullWidth type="password"
                                id="password" label="Новый пароль" name="newPassword"
                                value={formikChangePassword.values.newPassword}
                                onChange={formikChangePassword.handleChange}
                                onBlur={formikChangePassword.handleBlur}
                                error={formikChangePassword.touched.newPassword && Boolean(formikChangePassword.errors.newPassword)}
                                helperText={formikChangePassword.touched.newPassword && formikChangePassword.errors.newPassword}
                            />
                            <TextField
                                margin="normal" required fullWidth type="password"
                                id="password" label="Повторите новый пароль" name="newPassword2"
                                value={formikChangePassword.values.newPassword2}
                                onChange={formikChangePassword.handleChange}
                                onBlur={formikChangePassword.handleBlur}
                                error={formikChangePassword.touched.newPassword2 && Boolean(formikChangePassword.errors.newPassword2)}
                                helperText={formikChangePassword.touched.newPassword2 && formikChangePassword.errors.newPassword2}
                            />
                            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                                Сохранить
                            </Button>
                        </form>
                        :
                        <form onSubmit={formikLogin.handleSubmit}>
                            <TextField
                                margin="normal" required fullWidth autoFocus
                                id="userName" label="Пользователь" name="userName" autoComplete="userName"
                                value={formikLogin.values.userName}
                                onChange={formikLogin.handleChange}
                                onBlur={formikLogin.handleBlur}
                                error={formikLogin.touched.userName && Boolean(formikLogin.errors.userName)}
                                helperText={formikLogin.touched.userName && formikLogin.errors.userName}
                            />
                            <TextField
                                margin="normal" required fullWidth type="password"
                                id="password" label="Пароль" name="password"
                                value={formikLogin.values.password}
                                onChange={formikLogin.handleChange}
                                onBlur={formikLogin.handleBlur}
                                error={formikLogin.touched.password && Boolean(formikLogin.errors.password)}
                                helperText={formikLogin.touched.password && formikLogin.errors.password}
                            />
                            <FormControlLabel
                                control={<Checkbox value={formikLogin.values.remember} color="primary" />}
                                label="Запомнить меня" id="remember" name="remember"
                                onChange={formikLogin.handleChange}
                            />
                            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                                Вход
                            </Button>
                        </form>
                    }


                </Box>
            </Container>
        </>
    );
}
export default Signin;
