import { ReportApi } from 'src/api';
import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

export type UploadRequest = Readonly<{
    startDate: string;
    endDate: string;
    users: string[];
}>;

export type UploadItem = Readonly<{
    id: number;
    seria: string;
    startCode: number;
    endCode: number;
    used: number;
    defect: number;
    changedOn: Date;
    changedBy: string;
    sendBy: string;
    dateSend: Date;
    status: number;
}>;

export type UploadsState = Readonly<{
    isLoading: boolean;
    startDate: string;
    endDate: string;   
    items: UploadItem[];
}>;

export type ReceiveUploadsPayload = Pick<UploadsState, "items" | "startDate" | "endDate">;

const initialState: UploadsState = {
    items: [],
    isLoading: false,
    startDate: dayjs(new Date()).add(-1, 'month').toISOString() ,
    endDate: dayjs(new Date()).toISOString()
};

export const uploadReportSlice = createSlice({
    name: 'uploads',
    initialState,
    reducers: {
        requestItems: (state) => {
            state.isLoading = true;
        },
        requestBlob: (state) => {
            state.isLoading = true;
        },
        receiveBlob: (state) => {
            state.isLoading = false;
        },
        receiveItems: (state, action: PayloadAction<ReceiveUploadsPayload>) => {
            const { items, startDate, endDate } = action.payload;
            state.isLoading = false;
            state.items = items;
            state.startDate = startDate;
            state.endDate = endDate;
        }
    }
});

export const getUploadAsync = createAsyncThunk(
    'reports/getUploadAsync',
    async (request: UploadRequest, { dispatch }) => {
        dispatch(requestItems());
        try {
            const items = await ReportApi.getUploadAsync(request.startDate, request.endDate, request.users);
            const payload = { items, startDate: request.startDate, endDate: request.endDate };
            dispatch(receiveItems(payload));
        } catch (e) {
            console.error(e);
        }
    }
);

export const getUploadExportAsync = createAsyncThunk(
    'reports/getUploadExportAsync',
    async (request: UploadRequest, { dispatch }) => {
        dispatch(requestBlob());
        try {
            //request.users
            const response = await ReportApi.getUploadExportAsync(request.startDate, request.endDate, request.users.map(x => parseInt(x,10)));
            const byteArray = new Uint8Array(response);
            const url = window.URL.createObjectURL(new Blob([byteArray]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "upload.xlsx");
            document.body.appendChild(link);
            link.click();

            dispatch(receiveBlob());
        } catch (e) {
            console.error(e);
        }
    }
);

export const { requestItems, receiveItems, receiveBlob, requestBlob } = uploadReportSlice.actions;

export default uploadReportSlice.reducer;