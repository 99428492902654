import { BaseService } from './base.service';
import dayjs from 'dayjs';
import type { BalanceItem } from '../store/balanceReportSlice';
import type { UploadItem } from '../store/uploadReportSlice';
import type { RemissionItem } from '../store/remissionReportSlice';


class ReportService extends BaseService {
    private static _reportService: ReportService;
    private static _controller: string = 'report';

    private constructor(name: string) {
        super(name);
    }

    public static get Instance(): ReportService {
        return this._reportService || (this._reportService = new this(this._controller));
    }

    public async getBalanceExportAsync(startDate: string, endDate: string, departmentId: number): Promise<any> {
        const { data } = await this.$http.post<any>('/balance/export',
            {
                startDate: dayjs(startDate).toDate(),
                endDate: dayjs(endDate).toDate(), 
                departmentId: departmentId,
                users: []
            }, { responseType: "arraybuffer" });
        return data;
    }
    public async getBalanceAsync(startDate: string, endDate: string, departmentId: number): Promise<BalanceItem[]> {
        const { data } = await this.$http.post<BalanceItem[]>('/balance',
        {
            startDate: dayjs(startDate).toDate(),
            endDate: dayjs(endDate).toDate(), 
            departmentId: departmentId,
            users: []
        });
        return data;
    }

    public async getRemissionExportAsync(startDate: string, endDate: string, users: number[]): Promise<any> {
        const { data } = await this.$http.post<any>('/remission/export',
            {
                startDate: dayjs(startDate).toDate(),
                endDate: dayjs(endDate).toDate(),
                users: users
            }, { responseType: "arraybuffer" });
        return data;
    }
    public async getRemissionAsync(startDate: string, endDate: string, users: string[]): Promise<RemissionItem[]> {
        const { data } = await this.$http.post<RemissionItem[]>('/remission',
            {
                startDate: dayjs(startDate).toDate(),
                endDate: dayjs(endDate).toDate(),
                users: users
            });
        return data;
    }

    public async getUploadExportAsync(startDate: string, endDate: string, users: number[]): Promise<any> {
        const { data } = await this.$http.post<any>('/upload/export',
            {
                startDate: dayjs(startDate).toDate(),
                endDate: dayjs(endDate).toDate(),
                users: users
            }, { responseType: "arraybuffer" });
        return data;
    }
    public async getUploadAsync(startDate: string, endDate: string, users: string[]): Promise<UploadItem[]> {
        const { data } = await this.$http.post<UploadItem[]>('/upload',
            {
                startDate: dayjs(startDate).toDate(),
                endDate: dayjs(endDate).toDate(),
                users: users
            });
        return data;
    }
}

export const ReportApi = ReportService.Instance;
