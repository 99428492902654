import { ReportApi } from 'src/api';
import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

export type RemissonRequest = Readonly<{
    startDate: string;
    endDate: string;
    users: string[];
}>;

export type RemissionItem = Readonly<{
    id: number;
    seria: string;
    startCode: number;
    endCode: number;
    used: number;
    changedBy:string
}>;

export type RemissionsState = Readonly<{
    isLoading: boolean;
    startDate: string;
    endDate: string;   
    items: RemissionItem[];
}>;

export type ReceiveRemissionsPayload = Pick<RemissionsState, "items" | "startDate" | "endDate">;

const initialState: RemissionsState = {
    items: [],
    isLoading: false,
    startDate: dayjs(new Date()).add(-1, 'month').toISOString() ,
    endDate: dayjs(new Date()).toISOString()
};

export const remissionReportSlice = createSlice({
    name: 'remissions',
    initialState,
    reducers: {
        requestItems: (state) => {
            state.isLoading = true;
        },
        requestBlob: (state) => {
            state.isLoading = true;
        },
        receiveBlob: (state) => {
            state.isLoading = false;
        },
        receiveItems: (state, action: PayloadAction<ReceiveRemissionsPayload>) => {
            const { items, startDate, endDate } = action.payload;
            state.isLoading = false;
            state.items = items;
            state.startDate = startDate;
            state.endDate = endDate;
        }
    }
});

export const getRemissionAsync = createAsyncThunk(
    'reports/getUploadAsync',
    async (request: RemissonRequest, { dispatch }) => {
        dispatch(requestItems());
        try {
            const items = await ReportApi.getRemissionAsync(request.startDate, request.endDate, request.users);
            const payload = { items, startDate: request.startDate, endDate: request.endDate };
            dispatch(receiveItems(payload));
        } catch (e) {
            console.error(e);
        }
    }
);

export const getRemissionExportAsync = createAsyncThunk(
    'reports/getUploadExportAsync',
    async (request: RemissonRequest, { dispatch }) => {
        dispatch(requestBlob());
        try {
            //request.users
            const response = await ReportApi.getRemissionExportAsync(request.startDate, request.endDate, request.users.map(x => parseInt(x,10)));
            const byteArray = new Uint8Array(response);
            const url = window.URL.createObjectURL(new Blob([byteArray]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "remission.xlsx");
            document.body.appendChild(link);
            link.click();

            dispatch(receiveBlob());
        } catch (e) {
            console.error(e);
        }
    }
);

export const { requestItems, receiveItems, receiveBlob, requestBlob } = remissionReportSlice.actions;

export default remissionReportSlice.reducer;