import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, ButtonGroup, CircularProgress, FormControlLabel, IconButton, Switch, Tooltip } from '@mui/material';
import dayjs, {Dayjs} from 'dayjs';
import 'dayjs/plugin/isSameOrAfter'
import 'dayjs/plugin/isSameOrBefore'
import MUIDataTable, { type MUIDataTableColumnDef } from 'mui-datatables';
import { useEffect, useState, type FunctionComponent } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Spinner } from '../../components';
import { Departments, padLeft, Roles, Routes, textLabels } from '../../config';
import { useAppDispatch, useAppSelector } from '../../store';
import ListIcon from '@mui/icons-material/List';
import { deletePurchaseAsync, getPurchasesPageAsync, setSearchString, type PurchaseItem } from '../../store/purchaseSlice';
import type { DtoPage } from '../../enities/comonEntities';
import { userCheckRole } from '../../hooks';
import { FilterAlt } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';




enum FilterColumnNames {
    dateSup = "dateSup",
    seria = "seria",
    startCode = "startCode",
    endCode = "endCode",
    departmentId = "departmentId"
};   

const PurchaseTable: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { page: pageDefault = '0' } = useParams();
    const pageInit = parseInt(pageDefault, 0);

    const isLoading = useAppSelector<boolean>((state) => state.purchase.isLoading);
    const purchases = useAppSelector<PurchaseItem[]>((state) => state.purchase.purchases);
    const page = useAppSelector<number>((state) => state.purchase.page);
    const rowCount = useAppSelector<number>((state) => state.purchase.rowCount);
    const pageSize = useAppSelector<number>((state) => state.purchase.pageSize);
    const searchString = useAppSelector<string>((state) => state.purchase.searchString);

    const [purchasesData, setPurchasesData] = useState<PurchaseItem[]>([]);
    const [isFilterDateRange, setIsFilterDateRange] = useState<boolean>(false);
    const [filterStartDate, setFilterStartDate] = useState<string>("");
    const [filterEndDate, setFilterEndDate] = useState<string>("");
    
    const [filterDateGridList, setFilterDateGridList] = useState<string[]>([]);
    
    const [filtersValues, setFiltersValues] = useState<Map<FilterColumnNames, string[]>>(new Map());
    const [isFiltersActual, setIsFiltersActual] = useState<boolean>(true);
    // const [filterDateGridValue, setFilterDateGridValue] = useState<string[]>([]);
    // const [filterSeriaGridValue, setFilterSeriaGridValue] = useState<string[]>([]);
    // const [filterStartCodeGridValue, setFilterStartCodeGridValue] = useState<string[]>([]);
    // const [filterEndCodeGridValue, setFilterEndCodeGridValue] = useState<string[]>([]);

    

    // const [filterDa, setPurchasesData] = useState<PurchaseItem[]>([]);

    const columns: MUIDataTableColumnDef[] = [
        {
            name: "id",
            label: "#",
            //align: "center",
            options: {
                filter: false
            }
        },
        {
            label: "Дата поставки",
            name: "dateSup",
            options: {
                filter: true,
                // filterType: "textField",
                // filterType: "multiselect",
                filterType: "custom",
                filterList: filterDateGridList,
                filterOptions: {
                   display(filterList, onChange, index, column, filterData) {
                       return (
                           <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <FormControlLabel 
                                control={
                                    <Switch 
                                        checked={isFilterDateRange}
                                        onChange={() => {setIsFilterDateRange(!isFilterDateRange)}}
                                    />
                                } 
                                label="Промежуток дат" 
                            />
                            <div style={{display:"flex", gap: "5px",}}>
                                <DatePicker
                                    slotProps={{
                                        field: { 
                                            clearable: true, 
                                            // onClear: () => setFilterStartData("") 
                                        },
                                    }}
                                    maxDate={filterEndDate.length > 0 ? dayjs(filterEndDate).subtract(1,'day') : null}
                                    value={filterStartDate.length > 0 ? dayjs(filterStartDate): null}
                                    onChange={(value, context) => {
                                        // console.log("DatePicker onChange value", value);
                                        // console.log("DatePicker onChange typeOf(value)", typeOf(value));
                                        // console.log("DatePicker onChange value?.toDate()", value?.toDate());
                                        // console.log("DatePicker onChange value?.toDate().toISOString()", value?.toDate().toISOString());
                                        let stringValue = value?.toDate().toISOString();
                                        console.log("DatePicker onChange stringValue", stringValue);
                                        console.log("DatePicker onChange stringValue != null", stringValue != null);
                                        setFilterStartDate(stringValue != null ? stringValue : "")
                                        // formik.setFieldValue("dateSup", value?.toDate());
                                        // formik.handleChange(value)
                                    }}
                                    // onChange={formik.handleChange}
                                    //onBlur={formik.handleBlur}
                                    //error={formik.touched.dateSup && Boolean(formik.errors.dateSup)}
                                    format={"DD.MM.YYYY"}
                                />
                                {isFilterDateRange &&
                                    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>-</div>
                                }
                                {isFilterDateRange &&
                                <DatePicker
                                    slotProps={{
                                        field: { 
                                            clearable: true, 
                                            // onClear: () => setFilterStartData("") 
                                        },
                                    }}
                                    minDate={filterStartDate.length > 0 ? dayjs(filterStartDate).add(1,'day') : null}
                                    value={filterEndDate.length > 0 ? dayjs(filterEndDate): null}
                                    onChange={(value, context) => {
                                        //    console.log("DatePicker onChange value?.toDate()", value?.toDate());
                                       // formik.setFieldValue("dateSup", value?.toDate());
                                       // formik.handleChange(value)
                                        let stringValue = value?.toDate().toISOString();
                                        console.log("DatePicker onChange stringValue", stringValue);
                                        console.log("DatePicker onChange stringValue != null", stringValue != null);
                                        setFilterEndDate(stringValue != null ? stringValue : "")
                                    }}
                                    // onChange={formik.handleChange}
                                    //onBlur={formik.handleBlur}
                                    //error={formik.touched.dateSup && Boolean(formik.errors.dateSup)}
                                    format={"DD.MM.YYYY"}
                                />
                                }
                            </div>
                           </LocalizationProvider>
                       )
                   },
                //    renderValue("test"),
                },
                customBodyRender: (value: any, tableMeta: { rowData: any; }, updateValue: any) => dayjs(value).format("DD.MM.YYYY")
            }
        },
        {
            label: "Серия",
            name: "seria",
            options: {
                filter: true,
                filterType: "multiselect",
            }
        },
        {
            label: "Стартовый код",
            name: "startCode",
            options: {
                filter: true,
                filterType: "multiselect",
                // filterType: "textField",
                customBodyRender: (value: any, tableMeta: { rowData: any; }, updateValue: any) => padLeft(value, 9),
            }
        },
        {
            label: "Конечный код",
            name: "endCode",
            options: {
                filter: true,
                filterType: "multiselect",
                // filterType: "textField",
                customBodyRender: (value: any, tableMeta: { rowData: any; }, updateValue: any) => padLeft(value, 9)
            }
        },
        {
            label: "Отдел",
            name: "departmentId",
            options: {
                filter: true,
                filterType: "multiselect",
                sort: true,
                customBodyRender: (value: any, tableMeta: { rowData: any; }, updateValue: any) => Departments.has(Number(value)) ? Departments.get(Number(value)) : "" 
            }
        },

        //unusedCodesCount
        // {
        //     label: "Неиспользованных кодов",
        //     name: "unusedCodesCount",
        //     options: {
        //         // filter: false,
        //         // filterType: "multiselect",
        //         sort: true,
        //         //customBodyRender: (value: any, tableMeta: { rowData: any; }, updateValue: any) => Departments.has(Number(value)) ? Departments.get(Number(value)) : "" 
        //     }
        // },
        {
            name: "Операции",
            options: {
                filter: false, sort: false,
                customBodyRender: (value: any, tableMeta: { rowData: any; }, updateValue: any) => {
                    return <>
                        <ButtonGroup variant="text" >
                            {(!userCheckRole([Roles.ReadOnly]) ?
                                <Tooltip title="Редактировать">
                                    <IconButton disabled  color="primary" onClick={() => {
                                        const path = Routes.find((x) => x.name === "PurchaseEdit")?.path ?? "";
                                        const id = tableMeta.rowData[0];
                                        navigate(generatePath(path, { id: id }), { state: { id: id } });
                                    }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip> : <></>)}
                            {(!userCheckRole([Roles.ReadOnly]) ?
                                <Tooltip title="Удалить">
                                    <IconButton color="primary" onClick={() => {
                                        Swal.fire({
                                            title: "Удалить закупленный диапазон?",
                                            showClass: { popup: 'animate__animated animate__fadeInDown' },
                                            hideClass: { popup: 'animate__animated animate__fadeOutUp' },
                                            showCancelButton: true,
                                            confirmButtonColor: '#d33',
                                            cancelButtonColor: '#3085d6',
                                            confirmButtonText: 'Удалить',
                                            cancelButtonText: 'Отменить'
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                const id = tableMeta.rowData[0];
                                                dispatch(deletePurchaseAsync(id)).then(() => {
                                                    dispatch(getPurchasesPageAsync({
                                                        page: page,
                                                        sortOrder: '',
                                                        direction: '',
                                                        searchText: ''
                                                    } as DtoPage));
                                                });
                                            }
                                        })
                                    }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip> : <></>)}
                            <Tooltip title="Информация об изменении версий элемента списка">
                                <IconButton color="primary" onClick={() => {
                                    const path = Routes.find((x) => x.name === "Audit")?.path ?? "";
                                    const id = tableMeta.rowData[0];
                                    navigate(generatePath(path, { id: id, type: 'purchase' }));
                                }}>
                                    <ListIcon />
                                </IconButton>
                            </Tooltip>
                        </ButtonGroup>
                    </>;
                },
            },
        }];

    const HeaderElements = () => (
        <>
            {!(userCheckRole([Roles.ReadOnly])) ?
                <Tooltip title="Добавить">
                    <Button
                        variant="outlined"
                        startIcon={<FontAwesomeIcon icon={faAdd} />}
                        className="float-start"
                        onClick={() => {
                            navigate(Routes.find((x) => x.name === "PurchaseAdd")?.path ?? "");
                        }}>
                        Добавить
                    </Button>
                </Tooltip> : <></>}
        </>
    );

    useEffect(() => {
        console.log("useEffect [dispatch, page] ", searchString);
        dispatch(getPurchasesPageAsync({
            page: page,
            sortOrder: '',
            direction: '',
            searchText: searchString
        } as DtoPage));
    }, [dispatch, page]);

    useEffect(() => {
        setPurchasesData(purchases);
        setIsFiltersActual(false);
    }, [purchases]);

    useEffect(() => {
        if(filterStartDate.length > 0){
            if(isFilterDateRange){
                if(filterStartDate.length > 0 && filterEndDate.length > 0){
                    let rangeOfDateString = dayjs(filterStartDate).format("DD.MM.YYYY") + " - " + dayjs(filterEndDate).format("DD.MM.YYYY");
                    // let dateFilterList:string[] = [rangeOfDateString];
                    // setFilterDateGridValue(dateFilterList);
                    setFilterDateGridList([rangeOfDateString]);
                }
            }else{
                let oneDateString = dayjs(filterStartDate).format("DD.MM.YYYY");
                setFilterDateGridList([oneDateString]);
            }
        }else{
            setFilterDateGridList([]);
        }
    }, [filterStartDate, filterEndDate, isFilterDateRange]);

    

    useEffect(() => {
        if(isFiltersActual === false)
            filterGridData()
    }, [
        isFiltersActual,
        filtersValues, 
        // purchasesData
    ]);

    function saveNewFilters(currentFilterList: string[][], dateRangeShoudBeEmpty: boolean = false) {
        console.log("applyFiltersToData currentFilterList", currentFilterList);
        let seriaFilterArray: string[] = currentFilterList[2];
        let startCodeFilterArray: string[] = currentFilterList[3];
        let endCodeFilterArray: string[] = currentFilterList[4];
        let departmentLabelFilterArray: string[] = currentFilterList[5];
        console.log("applyFiltersToData filterStartDate", filterStartDate)
        console.log("applyFiltersToData filterEndDate", filterEndDate)
        console.log("applyFiltersToData seriaFilterArray", seriaFilterArray);
        console.log("applyFiltersToData startCodeFilterArray", startCodeFilterArray);
        console.log("applyFiltersToData endCodeFilterArray", endCodeFilterArray);
        console.log("applyFiltersToData departmentLabelFilterArray", departmentLabelFilterArray);
        const newFilterDateGridValue: string[] = [];
        if(filterStartDate.length > 0 && !dateRangeShoudBeEmpty){
            newFilterDateGridValue.push(filterStartDate);
            if(filterEndDate.length > 0)
                newFilterDateGridValue.push(filterEndDate);
        }
        let newFiltersDataMap: Map<FilterColumnNames, string[]> = new Map();
        if(newFilterDateGridValue.length > 0)
            newFiltersDataMap.set(FilterColumnNames.dateSup,newFilterDateGridValue)
        if(seriaFilterArray.length > 0)
            newFiltersDataMap.set(FilterColumnNames.seria,seriaFilterArray)
        if(startCodeFilterArray.length > 0)
            newFiltersDataMap.set(FilterColumnNames.startCode,startCodeFilterArray)
        if(endCodeFilterArray.length > 0)
            newFiltersDataMap.set(FilterColumnNames.endCode,endCodeFilterArray)
        if(departmentLabelFilterArray.length > 0){
            let departmentIdFilterArray: string[] = [];
            Departments.forEach((label,id) => {
                if(departmentLabelFilterArray.includes(label)){
                    departmentIdFilterArray.push(id.toString());
                }
            })
            newFiltersDataMap.set(FilterColumnNames.departmentId,departmentIdFilterArray)  
        }

        setFiltersValues(newFiltersDataMap);
        setIsFiltersActual(false);
    }

    function filterGridData() {
        if(filtersValues.size > 0){
            let gridData: PurchaseItem[] = purchases.map(x => {return x});
            console.log("gridData", gridData)
            if(filtersValues.has(FilterColumnNames.dateSup)){
                const dateSupFilterValues = filtersValues.get(FilterColumnNames.dateSup)
                if(dateSupFilterValues != null && dateSupFilterValues.length > 0){
                    if(dateSupFilterValues.length === 1){
                        console.log("dateSupFilterValues == 1", dateSupFilterValues)
                        const singleDate = dayjs(dateSupFilterValues[0]);
                        gridData = gridData.filter(d => singleDate.isSame(dayjs(d.dateSup),'day'))
                    }else if(dateSupFilterValues.length === 2){
                        console.log("dateSupFilterValues == 2", dateSupFilterValues)
                        
                        let isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
                        let isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
                        dayjs.extend(isSameOrAfter);
                        dayjs.extend(isSameOrBefore);
                        const startDate = dayjs(dateSupFilterValues[0]);
                        const endDate = dayjs(dateSupFilterValues[1]);
                        // console.log("startDate.isSameOrAfter(dayjs('2024-02-02'),'day')", startDate.isSameOrAfter(dayjs('2024-02-02'),'day'))
                        // console.log("startDate.isSameOrBefore(dayjs('2024-02-02'),'day')", startDate.isSameOrBefore(dayjs('2024-02-02'),'day'))

                        // console.log("endDate.isSameOrBefore(dayjs('2024-02-02'),'day')", endDate.isSameOrBefore(dayjs('2024-02-02'),'day'))
                        // console.log("endDate.isSameOrAfter(dayjs('2024-02-02'),'day')", endDate.isSameOrAfter(dayjs('2024-02-02'),'day'))

                        gridData = gridData.filter(d => startDate.isSameOrBefore(dayjs(d.dateSup),'day') && endDate.isSameOrAfter(dayjs(d.dateSup),'day'));
                    }
                }
                // gridData = gridData.filter(d => seriaFilterValues?.some(sf => sf === d.seria))
            }
            if(filtersValues.has(FilterColumnNames.seria)){
                const seriaFilterValues = filtersValues.get(FilterColumnNames.seria);
                gridData = gridData.filter(d => seriaFilterValues?.some(sf => sf === d.seria))
            }
            if(filtersValues.has(FilterColumnNames.startCode)){
                const startCodeFilterValues = filtersValues.get(FilterColumnNames.startCode);
                gridData = gridData.filter(d => startCodeFilterValues?.some(scf => parseInt(scf,10) === d.startCode))
            }
            if(filtersValues.has(FilterColumnNames.endCode)){
                const endCodeFilterValues = filtersValues.get(FilterColumnNames.endCode);
                gridData = gridData.filter(d => endCodeFilterValues?.some(ecf => parseInt(ecf,10) === d.endCode))
            }
            if(filtersValues.has(FilterColumnNames.departmentId)){
                const departmentIdFilterValues = filtersValues.get(FilterColumnNames.departmentId);
                gridData = gridData.filter(d => departmentIdFilterValues?.some(df => parseInt(df,10) === d.departmentId))
            }
            setPurchasesData(gridData);
        }else{
            setPurchasesData(purchases);
        }
        setIsFiltersActual(true);
    }

    return (
        <>
            <Spinner isLoading={isLoading} />
            <MUIDataTable
                title=""
                //title={isFiltersActual ? "" : <CircularProgress size={30} />}
                // data={purchases}
                data={purchasesData}
                columns={columns}
                options={{

                    textLabels: textLabels,
                    count: rowCount,
                    serverSide: true,
                    filter: true,
                    filterType: "custom",
                    // confirmFilters: true,

                    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
                        return (
                            <div style={{ marginTop: '40px' }}>
                                <Button
                                    variant="outlined"
                                    startIcon={<FilterAlt />}
                                    className="float-start"
                                    onClick={() =>
                                        saveNewFilters(currentFilterList)
                                    }>
                                    Применить фильтры
                                </Button>
                            </div>
                        );
                    },
                      onFilterChange: (column, filterList, type) => {
                        console.log('onFilterChange event occured', column, filterList, type);
                        if (type === 'chip') {
                            let dateRangeShoudBeEmpty: boolean = false
                            if(column === "dateSup"){
                                dateRangeShoudBeEmpty = true;
                                setFilterStartDate("");
                                setFilterEndDate("");
                            }
                          saveNewFilters(filterList, dateRangeShoudBeEmpty)
                        }
                        if(type === 'reset'){
                            setFilterStartDate("");
                            setFilterEndDate("");
                            saveNewFilters(filterList, true);
                        }
                      },
                    rowsPerPageOptions: [],
                    rowsPerPage: pageSize,
                    page: page,
                    download: false,
                    selectableRows: 'single',
                    selectableRowsOnClick: false,
                    selectableRowsHeader: false,
                    selectableRowsHideCheckboxes: true,
                    searchText:searchString,
                    onTableChange: (action, tableState) => {
                        console.log(action, tableState);
                        switch (action) {
                            case 'changePage':
                                dispatch(getPurchasesPageAsync({
                                    page: tableState.page,
                                    sortOrder: tableState.sortOrder.name ?? '',
                                    direction: tableState.sortOrder.direction ?? '',
                                    searchText: tableState.searchText ?? ''
                                } as DtoPage));
                                break;
                            case 'sort':
                                dispatch(getPurchasesPageAsync({
                                    page: tableState.page,
                                    sortOrder: tableState.sortOrder.name ?? '',
                                    direction: tableState.sortOrder.direction ?? '',
                                    searchText: tableState.searchText ?? ''
                                } as DtoPage));
                                break;
                            case 'search':
                                dispatch(setSearchString(tableState.searchText ?? ''))
                                dispatch(getPurchasesPageAsync({
                                    page: tableState.page,
                                    sortOrder: tableState.sortOrder.name ?? '',
                                    direction: tableState.sortOrder.direction ?? '',
                                    searchText: tableState.searchText ?? ''
                                } as DtoPage));
                                break;
                            default:
                                console.log('action not handled.');
                        }
                    },
                    customToolbar: () => (<HeaderElements />),
                }}
            />
        </>
    );
};

export default PurchaseTable;
