import { PerformApi } from 'src/api';
import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { DtoPage, DtoPageWithIdsOfGroup } from '../enities/comonEntities';
import { number } from 'yup';
import { AxiosError } from 'axios';

export type SeriaItem = Readonly<{
    id: string;    
    name: string;
    startcode:number;
    endcode:number;
    intervals:{
        start: number;
        end: number;
        purchaseId: number;
    }[]
}>;

export type DiapasonRange = Readonly<{
    seria: string;
    startCode: number;
    endCode: number;
    currentDiapasonId: number;
}>;

export type TvdItem = Readonly<{
    id: string;
    name: string;
}>;

export type PagedPerformGroups = Readonly<{
    currentPage: number;
    rowCount: number;
    pageSize: number;
    results: PerformGroupItem[];
}>;

export type PagedPerforms = Readonly<{
    currentPage: number;
    rowCount: number;
    pageSize: number;
    results: PerformItem[];
}>;

export type PerformGroupItem = Readonly<{
    group: string;
    seria: string;
    items: PerformItem[];  
}>;

export type SendPerformsToDatamarkRequest = Readonly<{
    ids: number[];
    token: string;
}>;

export type PerformItem = Readonly<{
    id: number;
    seria: string;
    startCode: number;
    endCode: number;
    defect: number;
    status?: number;
    information: string;
    tvdCode: string;
    group: string;
    userSendName: string;
    departmentId: number;
    purchaseId: number;
    // dateSend:string;
}>;


export type PerformsState = Readonly<{
    isLoading: boolean;
    page: number;
    rowCount: number;
    pageSize: number;
    performs: PerformItem[];
    tvdCodes: TvdItem[];
    serias: SeriaItem[];
    performGroups: PerformGroupItem[];
    singlePerform: PerformItem;
    id: number;
    searchString:string;
}>;

export type ReceivePerformsPayload = Pick<PerformsState,
    "performs" | "performGroups" | "pageSize" | "rowCount" | "page" | "id" | "singlePerform">;

const initialState: PerformsState = {
    performGroups: [],
    performs: [],
    isLoading: false,
    page: 0,
    rowCount: 0,
    pageSize: 10,
    tvdCodes: [],
    serias:[],
    singlePerform:{} as PerformItem,
    id: 0,
    searchString:"",
};

export const performSlice = createSlice({
    name: 'performs',
    initialState,
    reducers: {
        addPerform: (state, action: PayloadAction<PerformItem>) => {   
            state.singlePerform = action.payload;
        },
        requestPerform: (state, action: PayloadAction<number>) => {
            state.isLoading = true;
            state.id = action.payload;
        },
        receivePerform: (state, action: PayloadAction<ReceivePerformsPayload>) => {
            const { id, singlePerform  } = action.payload;
            state.isLoading = false;
            state.id = id;
            state.singlePerform = singlePerform;
        },
        receiveTvdCodes: (state, action: PayloadAction<TvdItem[]>) => {
            state.tvdCodes = action.payload;
        },
        receiveSeriaCodes: (state, action: PayloadAction<SeriaItem[]>) => {
            state.serias = action.payload;
        },
        requestPerforms: (state, action: PayloadAction<number>) => {
            state.isLoading = true;
            state.page = action.payload;
        },
        receivePerforms: (state, action: PayloadAction<ReceivePerformsPayload>) => {
            const { performs, performGroups, page, rowCount, pageSize } = action.payload;
            state.isLoading = false;
            state.performs = performs;
            state.performGroups = performGroups;
            state.page = page;
            state.rowCount = rowCount;
            state.pageSize = pageSize;  
        },
        requestGroupPerforms: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        receiveGroupPerforms: (state, action: PayloadAction<PerformGroupItem[]>) => {
            state.isLoading = false;
            state.performGroups = action.payload;
        },
        setSearchString: (state, action: PayloadAction<string>) => {
            state.searchString = action.payload;
        },
    }
});

export const getSeriaCodesAsync = createAsyncThunk(
    'performs/getSeriaCodesAsync',
    async (index: number, { dispatch }) => {
        try {
            const data = await PerformApi.getSeriaCodesAsync(index);
            dispatch(receiveSeriaCodes(data));
        } catch (e) {
            console.error(e);
        }
    }
);

export const getTvdCodesAsync = createAsyncThunk(
    'performs/getTvdCodesAsync',
    async (index:number, { dispatch }) => {
        try {
            const data = await PerformApi.getTvdCodesAsync(index);
            dispatch(receiveTvdCodes(data));
        } catch (e) {
            console.error(e);
        }
    }
);

export const getPerformAsync = createAsyncThunk(
    'performs/getPerformAsync',
    async (id: number, { dispatch }) => {
        dispatch(requestPerform(id));
        try {
            const singlePerform = await PerformApi.getPerformAsync(id);
            const payload = {
                performs: [], pageSize: 0, page: 0, rowCount: 0,
                singlePerform: singlePerform, id: id, performGroups:[]
            };
            dispatch(receivePerform(payload));
        } catch (e) {
            console.error(e);
        }
    }
);

export const sendPerformAsync = createAsyncThunk(
    'performs/getPerformAsync',
    async (id: number, { dispatch }) => {
        // dispatch(requestPerform(id));
        try {
            const singlePerform = await PerformApi.sendPerformAsync(id);
            // const payload = {
            //     performs: [], pageSize: 0, p age: 0, rowCount: 0,
            //     singlePerform: singlePerform, id: id, performGroups:[]
            // };
            // dispatch(receivePerform(payload));
            return singlePerform;
        } catch (e) {
            console.error(e);
        }
    }
);



export const deletePerformAsync = createAsyncThunk(
    'performs/getPerformAsync',
    async (id: number, { dispatch }) => {
        dispatch(requestPerform(id));
        try {
            const singlePerform = await PerformApi.deletePerformAsync(id);
            const payload = {
                performs: [], pageSize: 0, page: 0, rowCount: 0,
                singlePerform: singlePerform, id: id, performGroups:[]
            };
            dispatch(receivePerform(payload));
        } catch (e) {
            console.error(e);
        }
    }
);

export const editPerformAsync = createAsyncThunk(
    'performs/editPerformAsync',
    async (perform: PerformItem, { dispatch }) => {
        try {
            let editResponse = await PerformApi.editPerformAsync(perform);
            dispatch(addPerform(perform));
            return editResponse; 
        } catch (err) {
            console.error(err);
            if(err instanceof AxiosError){
                let axiosError = err as AxiosError
                return axiosError.response
            }
        }
    }
);

export const addPerformAsync = createAsyncThunk(
    'performs/addPerformAsync',
    async (perform: PerformItem, { dispatch }) => {
        try {
            await PerformApi.addPerformAsync(perform);
            dispatch(addPerform(perform));
        } catch (e) {
            console.error(e);
        }
    }
);

export const getPerformsGroupAsync = createAsyncThunk(
    'performs/getPerformsGroupAsync',
    async (dto: DtoPage, { dispatch, getState }) => {
        dispatch(requestGroupPerforms(true));
        try {
            const response = await PerformApi.getPerformsGroupAsync(dto);
            const payload = {
                performGroups: response.results, pageSize: response.pageSize,
                page: response.currentPage, rowCount: response.rowCount,
                singlePerform: {} as PerformItem, id: 0, performs: []
            };
            dispatch(receivePerforms(payload));
        } catch (e) {
            console.error(e);
        }
    }
);

export const getPerformsPageAsync = createAsyncThunk(
    'performs/getPerformsPageAsync',
    async (dto: DtoPage, { dispatch, getState }) => {
        dispatch(requestPerforms(dto.page));
        try {
            const response = await PerformApi.getPerformsPageAsync(dto);
            const payload = {
                performs: response.results, pageSize: response.pageSize,
                page: response.currentPage, rowCount: response.rowCount,
                singlePerform: {} as PerformItem, id: 0, performGroups:[]
            };
            dispatch(receivePerforms(payload));
        } catch (e) {
            console.error(e);
        }
    }
);

export const sendPerformsGroupAsync = createAsyncThunk(
    'performs/sendPerformsGroupAsync',
    async (dto: DtoPageWithIdsOfGroup, { dispatch, getState }) => {
        // dispatch(requestPerform(dto.page));
        try {
            const response = await PerformApi.sendPerformsGroupAsync(dto);
            // const payload = {
            //     performs: response.results, pageSize: response.pageSize,
            //     page: response.currentPage, rowCount: response.rowCount,
            //     singlePerform: {} as PerformItem, id: 0, performGroups:[]
            // };
            //dispatch(receivePerform(payload));
            return response
        } catch (e) {
            console.error(e);
        }
    }
);

export const sendPerformToDatamarkAsync = createAsyncThunk(
    'performs/sendToDatamarkAsync',
    async (request: SendPerformsToDatamarkRequest, { dispatch, getState }) => {
        // dispatch(requestPerform(dto.page));
        try {
            const response = await PerformApi.sendToDatamark(request);
            // const payload = {
            //     performs: response.results, pageSize: response.pageSize,
            //     page: response.currentPage, rowCount: response.rowCount,
            //     singlePerform: {} as PerformItem, id: 0, performGroups:[]
            // };
            //dispatch(receivePerform(payload));
            return response
        } catch (e) {
            console.error(e);
        }
    }
);

export const validateDiapasonAsync = createAsyncThunk(
    'performs/validateDiapasonAsync',
     async (range: DiapasonRange, {fulfillWithValue }) => {
        try {
            const result = await PerformApi.validateDiapasonAsync(range);
            return fulfillWithValue(result);
        } catch (err: any) {
            return fulfillWithValue(false);
        }
    }
);



export const { requestPerforms, receivePerforms, requestPerform, receiveGroupPerforms, receiveSeriaCodes,
    receivePerform, addPerform, receiveTvdCodes, requestGroupPerforms, setSearchString } = performSlice.actions;

export default performSlice.reducer;