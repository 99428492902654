import { BaseService } from './base.service';
import type { DiapasonRange, PagedPerformGroups, PagedPerforms, PerformGroupItem, PerformItem, SendPerformsToDatamarkRequest, SeriaItem, TvdItem } from '../store/performSlice';
import type { DtoPage, DtoPageWithIdsOfGroup } from '../enities/comonEntities';
import type { AxiosResponse } from 'axios';

class PerformService extends BaseService {
    private static _performService: PerformService;
    private static _controller: string = 'performs';

    private constructor(name: string) {
        super(name);
    }

    public static get Instance(): PerformService {
        return this._performService || (this._performService = new this(this._controller));
    }

    public async addPerformAsync(user: PerformItem): Promise<PerformItem> {
        const { data } = await this.$http.post<PerformItem>('/', user);
        return data;
    }

    public async editPerformAsync(user: PerformItem): Promise<AxiosResponse> {
        // const { data }
        const response = await this.$http.put<PerformItem>(`/${user.id}`, user);
        return response;
    }

    public async deletePerformAsync(id: number): Promise<PerformItem> {
        const { data } = await this.$http.delete(`/${id}`);
        return data;
    }

    public async sendPerformAsync(id: number): Promise<PerformItem> {
        const { data } = await this.$http.get<PerformItem>(`/send/${id}`);
        return data;
    }

    public async getPerformAsync(id: number): Promise<PerformItem> {
        const { data } = await this.$http.get<PerformItem>(`/${id}`);
        return data;
    }

    public async getSeriaCodesAsync(index: number): Promise<SeriaItem[]> {
        const { data } = await this.$http.get<SeriaItem[]>(`/serias`);
        return data;
    }

    public async getTvdCodesAsync(index: number): Promise<TvdItem[]> {
        const { data } = await this.$http.get<TvdItem[]>(`/tvd`);
        return data;
    }

    public async getPerformsAsync(index: number): Promise<PerformItem[]> {
        const { data } = await this.$http.get<PerformItem[]>('/');
        return data;
    }

    public async getPerformsGroupAsync(dto: DtoPage): Promise<PagedPerformGroups> {
        const { data } = await this.$http.post<PagedPerformGroups>('/group',            
        {
            page: dto.page,
            sortOrder: dto.sortOrder,
            direction: dto.direction,
            searchText: dto.searchText
        });
        return data;
    }
    
    public async getPerformsPageAsync(dto: DtoPage): Promise<PagedPerforms> {
        const { data } = await this.$http.post<PagedPerforms>('/page',
            {
                page: dto.page,
                sortOrder: dto.sortOrder,
                direction: dto.direction,
                searchText: dto.searchText
            });
        return data;
    }

    public async sendPerformsGroupAsync(dto: DtoPageWithIdsOfGroup): Promise<any> {
        const { data } = await this.$http.post<any>('/sendall', dto.ids           
        // {
            // page: dto.page,
            // sortOrder: dto.sortOrder,
            // direction: dto.direction,
            // searchText: dto.searchText,
            // ids: dto.ids
        // }
        );
        return data;
    }

    public async sendToDatamark(request: SendPerformsToDatamarkRequest): Promise<AxiosResponse> {
        const response = await this.$http.post<any>('/sendToDatamark',
        {
            PerformIds:request.ids,
            TokenDatamark: request.token
        }           
        // {
            // page: dto.page,
            // sortOrder: dto.sortOrder,
            // direction: dto.direction,
            // searchText: dto.searchText,
            // ids: dto.ids
        // }
        );
        return response;
    }

    public async validateDiapasonAsync(item: DiapasonRange): Promise<boolean> {
        const { data } = await this.$http.post<boolean>(`/validate`, item);
        return data;
    }   
    
}

export const PerformApi = PerformService.Instance;
